import { DataManager } from '../../../../../com/vbee/data/DataManager'
import { BM2DetailsViewModel, BMDetails2ViewModel } from './BMDetails2ViewModel';
import {BusinessModelViewModel} from './BusinessModelViewModel'
import * as ko from 'knockout'
import * as _ from 'underscore'
import { ValueType } from '../../../../bo/vdml/ValueType'

var path = DataManager.getDataManager().buildAppNsPath("vdml.views.properties", global.version);

export class BusinessModel2ViewModel extends BusinessModelViewModel{
    
    editValueFormulaValuesClickHandler(view, event) {
        var self = this;
        var id = view.modalId;
        var modalEle = self.selectedValueFormula() ? self.selectedValueFormula().get('component').findWhere({ 'id': view.id }) : null;
        var tempValuesAgg = self.addTempValuesForAggregation(self.vfName(), 'valueFormula', 'My Proposition');
        if ($.find("#modal" + id).length > 0) {
            self.updateView('valueFormula', id, tempValuesAgg);
            $("#modal" + id).modal('show');
        }
        else {
            var scenario = self.selectedValueFormula() ? self.currentAlternativeModel.getMainScenario(self.selectedValueFormula().getNestedParent()) : self.mainScenario();
            var collaboration = self.model.get('bmRole').get(self.selectedBusinessRole()).getParent();
            var aggregatedColl = self.getAggregatedColl();
            var addOptions = { 'period':self.defaultPeriod, 'year':self.defaultYear, 'type': 'valueFormula', 'aggregatedColl': aggregatedColl, 'mainScenario': scenario, 'valuesColl': self.valueFormulaValuesColl(), 'currentAlternativeModel': self.currentAlternativeModel, 'tempValuesForAggregation': tempValuesAgg, 'collaboration': collaboration };
            window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), modalEle, "ValueDetails", _.bind(self.addChildModel, self), addOptions);
        }
    };

    addValueFormulaClick() {
        var self = this;
        if (self.selectedBusinessRole()) {
            var id = self.htmlEscape(window.guidGenerator()) + "valueFormula";
            var tempValuesAgg = self.addTempValuesForAggregation(self.vfName(), 'valueFormula', 'My Proposition');
            var scenario = self.selectedValueFormula() ? self.currentAlternativeModel.getMainScenario(self.selectedValueFormula().getNestedParent()) : self.mainScenario();
            var collaboration = self.model.get('bmRole').get(self.selectedBusinessRole()).getParent();
            var aggregatedColl = self.getAggregatedColl();
            var addOptions = { 'period':self.defaultPeriod, 'year':self.defaultYear, 'type': 'valueFormula', 'aggregatedColl': aggregatedColl, 'mainScenario': scenario, 'valuesColl': self.valueFormulaValuesColl(), 'currentAlternativeModel': self.currentAlternativeModel, 'tempValuesForAggregation': tempValuesAgg, 'collaboration': collaboration };
            window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), null, "ValueDetails", _.bind(self.addChildModel, self), addOptions);
        }
    };

    editValuePropositionValuesClickHandler(view, event) {
        var self = this;
        var id = view.modalId;
        var modalEle = self.selectedValueProposition() ? self.selectedValueProposition().get('component').findWhere({ 'id': view.id }) : null;
        var tempValuesAgg = self.addTempValuesForAggregation(self.vpName(), 'valueProposition', 'Value Proposition');
        if ($.find("#modal" + id).length > 0) {
            self.updateView('valueProposition', id, tempValuesAgg);
            $("#modal" + id).modal('show');
        }
        else {
            var scenario = self.selectedValueProposition() ? self.currentAlternativeModel.getMainScenario(self.selectedValueProposition().getNestedParent()) : self.mainScenario();
            var collaboration = self.model.get('bmRole').get(self.selectedProviderRole()).getParent();
            var aggregatedColl = self.getAggregatedColl();
            var addOptions = { 'period':self.defaultPeriod, 'year':self.defaultYear, 'type': 'valueProposition', 'aggregatedColl': aggregatedColl, 'mainScenario': scenario, 'valuesColl': self.valuePropositionValuesColl(), 'currentAlternativeModel': self.currentAlternativeModel, 'tempValuesForAggregation': tempValuesAgg, 'collaboration': collaboration };
            window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), modalEle, "ValueDetails", _.bind(self.addChildModel, self), addOptions);
        }
    };

    addValueFormulaClick() {
        var self = this;
        if (self.selectedBusinessRole()) {
            var id = self.htmlEscape(window.guidGenerator()) + "valueFormula";
            var tempValuesAgg = self.addTempValuesForAggregation(self.vfName(), 'valueFormula', 'My Proposition');
            var scenario = self.selectedValueFormula() ? self.currentAlternativeModel.getMainScenario(self.selectedValueFormula().getNestedParent()) : self.mainScenario();
            var collaboration = self.model.get('bmRole').get(self.selectedBusinessRole()).getParent();
            var aggregatedColl = self.getAggregatedColl();
            var addOptions = {period:self.defaultPeriod, year:self.defaultYear, 'type': 'valueFormula', 'aggregatedColl': aggregatedColl, 'mainScenario': scenario, 'valuesColl': self.valueFormulaValuesColl(), 'currentAlternativeModel': self.currentAlternativeModel, 'tempValuesForAggregation': tempValuesAgg, 'collaboration': collaboration };
            window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), null, "ValueDetails", _.bind(self.addChildModel, self), addOptions);
        }
    };

    addValuePropositionClick() {
        var self = this;
        if (self.selectedProviderRole()) {
            var id = self.htmlEscape(window.guidGenerator()) + "valueProposition";
            var scenario = self.selectedValueProposition() ? self.currentAlternativeModel.getMainScenario(self.selectedValueProposition().getNestedParent()) : self.mainScenario();
            var tempValuesAgg = self.addTempValuesForAggregation(self.vpName(), 'valueProposition', 'Value Proposition');
            var collaboration = self.model.get('bmRole').get(self.selectedProviderRole()).getParent();
            var aggregatedColl = self.getAggregatedColl();
            var addOptions = {period:self.defaultPeriod, year:self.defaultYear, 'type': 'valueProposition', 'aggregatedColl': aggregatedColl, 'mainScenario': scenario, 'valuesColl': self.valuePropositionValuesColl(), 'currentAlternativeModel': self.currentAlternativeModel, 'tempValuesForAggregation': tempValuesAgg, 'collaboration': collaboration };
            window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), null, "ValueDetails", _.bind(self.addChildModel, self), addOptions);
        }
    };

    contributingWhatValuesActivitiesClickHandler() {
        var self = this;
        if (self.selectedPersued()) {
            var id = self.htmlEscape(window.guidGenerator()) + "contributingActivity";
            var vp = self.fetchValueProposition(self.persuedOptions(), self.selectedPersued());
            var vpValues = self.filterActivityAggregatedToValuesList(vp, self.activityContributeWhatValuesColl());
            var tempValuesAgg = self.addTempValuesForAggregation(null, 'createWhatValues', 'Activity', true);
            //DataManager.getDataManager().get('currentPlan').getValuesThatCanBeRefferedTo(function(values){
            //	var valuesColl = new Backbone.Collection();
            //	valuesColl.add(values);
            var aggregatedColl = self.getAggregatedColl();
            var addOptions = {period:self.defaultPeriod, year:self.defaultYear, 'type': 'contributeWhatValues', 'activityValueProposition': vp, 'activitiesValues': self.activityCreateWhatValuesColl(), 'vpValue': vpValues, 'aggregatedColl': aggregatedColl, 'mainScenario': self.mainScenario(), 'currentAlternativeModel': self.currentAlternativeModel, 'tempValuesForAggregation': tempValuesAgg };
            window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), null, "ValueDetails", _.bind(self.addChildModel, self), addOptions);
            //});
        }
    };

    createWhatValuesActivitiesClickHandler() {
        var self = this;
        var openWhatValuesDialog = false;
        for (var i = 0; i < self.activityCollection().length; i++) {
            if (!self.activityCollection()[i]._destroy) {
                openWhatValuesDialog = true;
                break;
            }
        }
        if (openWhatValuesDialog) {
            var id = self.htmlEscape(window.guidGenerator()) + "activity";
            var role = self.model.get('bmRole').findWhere({ id: self.selectedActivityProviderRole() });
            var tempValuesAgg = self.addTempValuesForAggregation(null, 'createWhatValues', 'Activity');
            var aggregatedColl = self.getAggregatedColl();
            var addOptions = { period:self.defaultPeriod, year:self.defaultYear, 'type': 'createWhatValues', 'activityProviderRole': role, 'activities': self.activityCollection(), 'aggregatedColl': aggregatedColl, 'mainScenario': self.mainScenario(), 'valuesColl': self.activityCreateWhatValuesColl(), 'currentAlternativeModel': self.currentAlternativeModel, 'tempValuesForAggregation': tempValuesAgg };
            window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), null, "ValueDetails", _.bind(self.addChildModel, self), addOptions);
        }
    };

    editActivityCreateValuesClickHandler(view, event) {
        var self = this;
        var id = view.modalId;
        var tempValuesAgg = self.addTempValuesForAggregation(null, 'createWhatValues', 'Activity');
        if ($.find("#modal" + id).length > 0) {
            self.updateView('createWhatValues', id, tempValuesAgg);
            $("#modal" + id).modal('show');
        }
        else {
            var modalEle = null;
            var activityModal = self.fetchActivityModal(view.activityId);
            var ports = activityModal.get('containedPort');
            for (var i = 0; i < ports.length; i++) {
                if (ports.at(i).get('type') === "vdml_OutputPort") {
                    modalEle = ports.at(i).get('valueAdd').findWhere({ id: view.originalId });
                    if (modalEle) {
                        break;
                    }
                }
            }
            var role = self.model.get('bmRole').findWhere({ id: self.selectedActivityProviderRole() });
            var scenario = self.currentAlternativeModel.getMainScenario(modalEle.getNestedParent());
            var collaboration = activityModal.getNestedParent();
            var aggregatedColl = self.getAggregatedColl();
            var addOptions = { 'period':self.defaultPeriod, 'year':self.defaultYear, 'type': 'createWhatValues', 'activityProviderRole': role, 'activities': self.activityCollection(), 'aggregatedColl': aggregatedColl, 'mainScenario': scenario, 'valuesColl': self.activityCreateWhatValuesColl(), 'currentAlternativeModel': self.currentAlternativeModel, 'tempValuesForAggregation': tempValuesAgg, 'collaboration': collaboration };
            window.getAndCreateModalDialog(self.model, id, self.model.getMixinClass(), modalEle, "ValueDetails", _.bind(self.addChildModel, self), addOptions);
        }
    };

	loadBusinessDetailComponent(){
        var self = this
        self.bmDetails2ViewInstance = BMDetails2ViewModel.getInstance(this);
        if(!ko.components.isRegistered('BMDetailsWidget')){
            ko.components.register("BMDetailsWidget", {
                viewModel: {instance:self.bmDetails2ViewInstance},
                template: window.utils.getHtmlPage("BMDetails2Template"),
            });
		}
	}

    getMeasurementValueandUnit(component,period) {
        var self = this;
        var val = {};
        //var scenario = self.currentAlternativeModel.getDefaultScenario(value.getNestedParent());
        var valueElementContexts = component.get("context").models;
        var valueContext = null;
        for(var i = 0;i<valueElementContexts.length;i++){
            var valueContextObj = valueElementContexts[i];
            if(valueContextObj.get("alternative") == self.currentAlternativeModel ){
                valueContext = valueContextObj
                break;
            }
        }
        var definition = component.get("definition");
        var unit = component.get("unit");
        if (self.periodDataset != null) {
            if(definition != null && !unit){
                unit = definition.get("unit");
            }    
            val['unit'] = unit ? unit.get('name') : "";
            var significantDecimals = unit ? unit.get('significantDecimals') : 2;
            if (parseFloat(significantDecimals) < 0 || parseFloat(significantDecimals) > 20) {
                significantDecimals = 2;
            }
            var value;
            if(self.periodDataset){
                //value = periodDataset.get("data")[value.id];
                value = self.periodDataset.get(component.id);
            }
            if(component.get("valueType") != ValueType.Atomic.name){
                isCollective = true;
                value = self.calcPeriodDataset?self.calcPeriodDataset.get(component.id):null;
            }

            /*if (!definition || definition.get("valueType") == ValueType.Literal) {
                val['value'] = value ? value: null;
            } else {*/
                if(value != null){
                    val['value'] = window.utils.getRoundedValues(value, significantDecimals);
                }
                //val['measurement'] = valueContext;
            //}
            if (val['value'] == "") {
                val['unit'] = "";
            }
            if(valueContext && valueContext.get('formula')){
                val['formula'] = valueContext.get('formula').get('expressionStr');
            }
            
            
            if (!val['formula']) {//back-end compatability
                val['formula'] = definition ? definition.get('formula'): null;
            }
        } else {
            val['unit'] = "";
            val['value'] = "";
            val['formula'] = "";
            if (component.get('aggregatedFrom').length > 0) {
                val['isCollective'] = true;
            } else {
                val['isCollective'] = false;
            }
        }
        return val;
    }

    updateYearAndPeriod(year,period){
        var self = this;
        self.defaultPeriod = period;
        self.defaultYear = year;
    }

    datasetsByPhase(phaseID){
        var self = this;
        var dataManager = DataManager.getDataManager();
        var phaseDataSets=[]
        var scenarioId = dataManager.get("currentPlan").get("defaultScenario");
        var scenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId});
        var step = scenario.get("step").findWhere({phaseId: phaseID});
        var periodsLen = step.get('noPeriods');
        var period = step.get("startPeriod");			
        for(var l = 0; l < periodsLen; l++){
            if(l != 0){
                period++;
            }
            let yearPeriod = utils.calculateYearPeriod(scenario.get("startTime") , period, self.periodKind );
            phaseDataSets.push(yearPeriod);
        }
        return phaseDataSets
    }

    init(model, options){
        super.init(model,options);
        var self = this;
        var dataManager = DataManager.getDataManager();
        this.selectedYear = ko.observable();
        this.selectedPeriod = ko.observable();
        var currentPlan = dataManager.get('currentPlan');
        this.periodKind = currentPlan.get("periodKind");
        var scenarioId = currentPlan.get("defaultScenario");
        var scenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId});
        this.dataSets = scenario.getPhaseAlternativeSteps(currentPlan);
        var phase = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: dataManager.get('viewAlternative')})?.getParent();
        this.phaseDatasets = this.datasetsByPhase(phase.id);
        var defaultPeriodList = scenario.fillDefaultPeriods(this.phaseDatasets,self.periodKind);
        self.defaultPeriod = defaultPeriodList.period
        self.defaultYear = defaultPeriodList.year;

        var defaultExecutionScenaroId = scenario.get("defaultExecutionScenario");
        var defaultExecutionScenario = Backbone.Relational.store.getObjectByName("transformation.ScenarioExecution").find({ id: defaultExecutionScenaroId });
        self.periodDataset = defaultExecutionScenario.get("input").findWhere({period: self.defaultPeriod, periodKind : self.periodKind, year:self.defaultYear});
        self.calcPeriodDataset = defaultExecutionScenario.get("result").findWhere({period: self.defaultPeriod, periodKind : self.periodKind, year:self.defaultYear});
    }

    static getInstance(model, options) {
        var view = new BusinessModel2ViewModel(model, options);
        return view;
    };
}



path.BusinessModel2ViewModel = BusinessModel2ViewModel;