import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as bootbox from '../../../../../../libs/bootbox/bootbox'
import {DataManager} from '../../../../../com/vbee/data/DataManager'
//import {MappingWizardViewModel} from '../../../strategyMap/views/designer/MappingWizardViewModel'
import {EcoMapDiagramMixin} from '../../../../../version1/bo/vdml/EcoMapDiagramMixin'
import {BusinessModelMixin} from '../../../../../version1/bo/vdml/BusinessModelMixin'
import {ValueDeliveryModelMixin} from '../../../../../version1/bo/vdml/ValueDeliveryModelMixin'
//import {EcomapMappingWizardViewModel} from './EcomapMappingWizardViewModel'
/*define(["require", "jquery", "underscore", 'async', "backbone", "knockout", "knockoutMapping", "knockback", "bootbox", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/canvas/BMCanvasDiagram", "appbo/vdml/ValueStream", "appbo/vdml/BusinessItem", "appbo/vdml/CapabilityOffer", "appbo/vdml/MeasuredCharacteristic", "appviews/vdml/views/chart/BMValuesChart", "appviews/transformation/views/chart/PlanValuesChart", "appbo/vdml/BusinessModelMixin", "appbo/vdml/EcoMapDiagramMixin", "appbo/vdml/ValueDeliveryModelMixin", "summernote", "colorpicker"],
    function (require, $, _, async, Backbone, ko, koMapping, kb, bootbox, DataManager, global, BMCanvasDiagram, ValueStream, BusinessItem, CapabilityOffer, MeasuredCharacteristic, BMValuesChart, PlanValuesChart, BusinessModelMixin, EcoMapDiagramMixin,ValueDeliveryModelMixin) {
*/
        var path = DataManager.getDataManager().buildAppNsPath("ecoMap.views.designer", global.version);
	export class ShapePropertiesViewModel {
		constructor(model, configObj, callback){
			var self = this;
			this.init(model, configObj, callback);
		}
		init(model, configObj, callback) {
            var self = this;
            this.ShapePropertiesViewModel = this;
            self.model = model;
            this.showCapabilityName = ko.observable();
            self.showColors = ko.observable(true);
            this.showValueElement = ko.observable(false);
            this.showValueDefinition = ko.observable(false);
			this.name = ko.observable();
			this.type = ko.observable();
            this.description = ko.observable();
            this.valueLibs = [];
            this.usedValue = null;
            this.customTrigger = false;
            self.id = kb.observable(self.model, 'id');
            self.getBusinessObjectReference = function (businessObject) {
                /*if (businessObject.di) {
                    businessObject = businessObject.di;
                    
                }*/
                if (businessObject.definitionRef) {
                    return businessObject.definitionRef;
                } else {
                    return businessObject.cmmnElementRef
                }
            }
            if(configObj.businessObject.$type == "vdml:Value"){
                var currentPlan = DataManager.getDataManager().get('currentPlan');
                currentPlan.loadValueLibraries(function (valLibraries) {
                    if (valLibraries) {
                        self.valueLibs = valLibraries;
                        self.showValueElement(true);
                    }
                })
            }
            if (self.model.get('type') == "vdml_CmmnDiagram") {
                self.showColors(false);
                if (configObj.addOptions.businessObject.name || (self.getBusinessObjectReference(configObj.addOptions.businessObject) && self.getBusinessObjectReference(configObj.addOptions.businessObject).name)){
                    self.name(configObj.addOptions.businessObject.name ? configObj.addOptions.businessObject.name : self.getBusinessObjectReference(configObj.addOptions.businessObject).name);
                    self.type(configObj.parentView.getMappedObj(configObj.addOptions.businessObject.$type));
				    self.description(self.name());
			    }else{
                   self.name(null);
                   self.type(configObj.parentView.getMappedObj(configObj.addOptions.businessObject.$type));
				   self.description(self.name());
			    }
			}else{
			    self.name(configObj.addOptions.businessObject.name);
                self.description(configObj.addOptions.businessObject.description ? configObj.addOptions.businessObject.description : configObj.addOptions.businessObject.name);
                if (configObj.addOptions.businessObject && configObj.addOptions.businessObject.$type === "vdml:Value" && configObj.businessObject.mcid) {
                    self.usedValue = configObj.businessObject.mcid.slice();
                    self.showParentValueId = configObj.businessObject.mcpid.slice();
                    configObj.businessObject.isValueDef = self.usedValue;
                } else if(configObj.businessObject.mcid){
                    self.showCapabilityId = configObj.businessObject.mcid.slice();
                    self.showParentCapabilityId = configObj.businessObject.mcpid.slice();
                    configObj.businessObject.isCapElement = self.showCapabilityId;
                }
			}
            self.mappedTo = ko.observable(null);
            self.mappedValueDef = ko.observable(null)
			self.mappedToValues = ko.observable(false);
            self.capMappedDiv = ko.observable(false);
            self.mappedElementModel = null;
			self.enableComplete = ko.observable(true);
            self.isVPShape = ko.observable(false);
			self.syncName = ko.observable(false);
			//self.enableName = ko.observable(true);
			//self.enableDescription = ko.observable(true);
            self.showCharts = ko.observable(false);
            self.enableValueAdd = ko.observable(true);
            self.modeling = configObj.parentView.modeler.get('modeling');
            self.eventBus = configObj.parentView.modeler.get('eventBus');
            if(self.model.get('type') == "vdml_ProcessDiscoveryDiagram" || self.model.get('type') == "vdml_CaseDiscoveryDiagram"){
            	self.bmpn = true;
            	self.vdmlFactory = configObj.parentView.modeler.get('bpmnFactory');
            }else if(self.model.get('type') == "vdml_CmmnDiagram"){
				self.cmmn = true;
                self.vdmlFactory = configObj.parentView.modeler.get('cmmnFactory');
			}
            else {
            	self.vdmlFactory = configObj.parentView.modeler.get('bpmnFactory');
            }
            self.elementRegistry =  configObj.parentView.modeler.get('elementRegistry');
            self.documentationsList = ko.observableArray([]);
            self.isShape = ko.observable(true);
            self.allowMapping = ko.observable(true);
            self.hideIcons = ko.observable(false);
            self.showDefinition = ko.observable(false);
            self.isValueStream = ko.observable(false);
            self.isStartActivity = ko.observable(false);
            self.isStopActivity = ko.observable(false);
            self.isOutput = ko.observable("false");
            self.showStartActivity = ko.observable(false);
            self.showCompInputOutput = ko.observable(false);
            self.showAddDocumentation = ko.observable(true);
            self.radioSelectedOptionValue = ko.observable(configObj.businessObject.curveType);
            if (configObj.parentView.model.get('type') === "vdml_ValueStreamMapDiagram") {
                self.isValueStream(true);
				if(configObj.businessObject.$type == "vdml:Activity"){
					self.isStartActivity(configObj.businessObject.isProcessStart ? configObj.businessObject.isProcessStart : false );
					self.isStopActivity(configObj.businessObject.isProcessEnd ? configObj.businessObject.isProcessEnd : false);
					self.showStartActivity(true);
                }
				else if (configObj.businessObject.$type == "vdml:Competency") {
                    var compModel = Backbone.Relational.store.getObjectByName('vdml.Competence').find({id:configObj.businessObject.mid});
					var compType = compModel ? compModel.get('type') : "";
					if((configObj.businessObject.mid && compType == "vdml_BusinessItem") || (!configObj.businessObject.mid && !configObj.businessObject.mcid)){
					     self.isOutput(configObj.businessObject.isOutput ? configObj.businessObject.isOutput : "false");
						 self.showCompInputOutput(true);
					}
				}
            }
            if (self.model.get('type') === 'vdml_CapabilityLibraryDiagram' || self.model.get('type') === 'vdml_ValueStreamMapDiagram'){
				if (/*configObj.businessObject.$type == "vdml:Competency" ||*/ configObj.businessObject.$type == "vdml:CapabilityContainer") {
					self.showDefinition(false);
				}else {
					self.showDefinition(true);
				}
            }
            if (window.checkContextForRevision()) {
                self.hideIcons(true);
            }
            self.disableUpdateMappingTypes = ['vdml_BusinessModel', 'vdml_ValuePropositionComponent', 'vdml_ValueStream'];
            if (configObj.addOptions.element && (configObj.addOptions.element.businessObject.$type === "vdml:Perspective" || configObj.addOptions.element.businessObject.$type === "vdml:CapabilityContainer") || self.bmpn || self.cmmn) {
                self.allowMapping(false);
            }
            self.mappingWizardPathMap = {};
            self.mappingWizardPathMap['vdml_EcoMapDiagram'] = "EcomapMappingWizardViewModel";
            self.mappingWizardPathMap['vdml_StrategyMapDiagram'] = "MappingWizardViewModel";
            self.mappingWizardPathMap['vdml_ValueStreamMapDiagram'] = "MappingWizardViewModel";
            self.mappingWizardPathMap['vdml_CapabilityLibraryDiagram'] = "CapabilityMappingWizardViewModel";
            self.usedColors = typeof self.model.getUsedColors === 'function' ? self.model.getUsedColors(configObj.parentView.modeler) : ["rgba(255,255,127,1.0)", "rgba(255,81,156,1.0)", "rgba(70,193,237,1.0)", "rgba(250,119,49,1.0)", "rgba(127,140,255,1.0)", '#ffffff'];
            //self.enableMapping = ko.observable(false);
            this.refreshDocumentationsList = function () {
                self.documentationsList.removeAll();
                var documentations = configObj.addOptions.businessObject.cmmnElementRef ? configObj.addOptions.businessObject.cmmnElementRef.documentation : configObj.addOptions.businessObject.documentation;
                _.each(documentations, function (doc) {
                    self.documentationsList.push({ type: doc.$type, id: doc.get('id'), name: doc.get('name'), text: doc.get('text') });
                });
                if(self.documentationsList().length >= 1){
                    self.showAddDocumentation(false);
                }
            }
            self.refreshDocumentationsList();
            if ((configObj.addOptions.element) && (configObj.addOptions.element.businessObject.$type === "vdml:BusinessItem" || configObj.addOptions.element.businessObject.$type === "vdml:CausalRelationship" || configObj.addOptions.element.businessObject.$type === "vdml:DeliverableFlow" || configObj.addOptions.element.businessObject.$type === "bpmn:SequenceFlow")) {
                var di;
				if(configObj.addOptions.element){
					di = configObj.addOptions.element.di;

				}
				if (di && di.stroke) {
                    self.defaultColor = di.stroke;
                } else {
                    self.defaultColor = "#050505";
                }
				if(self.usedColors && self.usedColors.length < 6){
					self.usedColors = ["#000000", "#FF0000", "#0000FF", "#008000", "#FF00FF", "#00FF00", "#ffffff", "#050505"];
				}                
                self.isShape(false);
            } else {
                 var di,type;
				if(configObj.addOptions.element && configObj.addOptions.element.di){
					di = configObj.addOptions.element.di;
				}else if(configObj.addOptions.element && configObj.addOptions.element.di){
					di = configObj.addOptions.element.di;
				}else if(configObj.addOptions.businessObject && configObj.addOptions.businessObject.cmmnElementRef){
					di = configObj.addOptions.businessObject.cmmnElementRef.di;
				}
                if (di && di.get('fill')) {
                    self.defaultColor = di.get('fill');
                } else {
                    self.defaultColor = "#fcfcfc";
                } 
                if (!configObj.parentView.mappingObject) {
                    self.showColors(false);
                    var modelType = di ? di.$type : configObj.addOptions.businessObject.$type;
                    if (modelType == "cmmndi:CMMNShape") {                       
                        self.type(configObj.parentView.getMappedObj(self.getBusinessObjectReference(configObj.addOptions.businessObject).$type));
                        self.name(self.getBusinessObjectReference(configObj.addOptions.businessObject).name);
                        if(!self.name() && (configObj.addOptions.businessObject.definitionRef || (configObj.addOptions.businessObject.cmmnElementRef && configObj.addOptions.businessObject.cmmnElementRef.definitionRef))){
							self.name(configObj.addOptions.businessObject.cmmnElementRef ? configObj.addOptions.businessObject.cmmnElementRef.definitionRef.name : configObj.addOptions.businessObject.definitionRef.name);
						}
						self.description(self.name());
                    }
                    else if (di && (di.$type == "bpmndi:BPMNShape" || di.$type === "bpmndi:BPMNEdge")) { 
                        if (configObj.addOptions.businessObject.definitionRef) {
                            self.type(configObj.parentView.getMappedObj(configObj.addOptions.businessObject.definitionRef.$type));
                            self.name(configObj.addOptions.businessObject.name);
                            self.description(self.name());
                        } else {
                            self.type(configObj.addOptions.businessObject.$type);
                            self.name(configObj.addOptions.businessObject.name);
                            self.description(self.name());
                        }
                    }
                }else {
                    /*if (configObj.addOptions.businessObject.$type == "vdml:BusinessIndicatorCategory"){
                        type = 'smm:BusinessIndicatorCategory';
                    } else if (configObj.addOptions.businessObject.$type == "vdml:BusinessIndicatorDefinition"){
                        type = 'smm:BusinessIndicatorDefinition';
                    } else if (configObj.addOptions.businessObject.$type == "vdml:BusinessIndicatorLibraryElement"){
                        type = 'smm:BusinessIndicatorLibraryElement'
					}else{*/
						type = configObj.addOptions.businessObject.$type;
					//}
                    self.type = configObj.parentView.mappingObject[type] ?
				             ko.observable(configObj.parentView.mappingObject[type].name) :
                        ko.observable(configObj.parentView.mappingObject[self.getBusinessObjectReference(configObj.addOptions.businessObject).$type].name);
                }
				if(self.type() == "Activity" || self.type() == "Competency"){
					self.capMappedDiv(true);
				}
				if(configObj.businessObject.get('syncName') && configObj.businessObject.get('mid')){
					self.syncName(true);
					//self.enableName(false);
					//self.enableDescription(false);
				} 
			}
            function htmlEscape(str) {
                return String(str)
                    .replace(/@/g, '')
                    .replace(/ /g, '');
            }
            this.encodeId = configObj.modalId;

            $('#modal' + self.encodeId).on('shown.bs.modal', function () {
                window.utils.focusElement("#newModal .focus-ele");
            });


            this.getMappedElement = function () {
                function fetchMappedEleName(model) {
                    if (model) {
                        self.mappedElementModel = model;
                        var desc = self.description() ? self.description().trim() : "";
                        var name = self.name() ? self.name().trim() : "";                        
                        var modalName = name;
                        if (model.get('type') === "vdml_ValuePropositionComponent" || model.get('type') === "vdml_ValueAdd") {
                            self.name(modalName);
                        }
						var modalDescription = model.get('description');
						if (model.get('type') === 'vdml_ValueStream' && model.get('persuedProposition')) {
							modalName = model.get('persuedProposition').get('name');
							modalDescription = model.get('persuedProposition').get('description');
						}
						if (self.syncName()) {
							var tempTargetObj = _.find(configObj.parentView.children, function(obj){ return obj.modal == model; });
							if(tempTargetObj){
								modalName = tempTargetObj.name;
								modalDescription = tempTargetObj.description;
							}else if (model.get('type') !== 'vdml_ValueStream'){
                                modalName = model.get('name');
                            }
						}
                        if (!desc || self.syncName()) {
                            self.description(modalDescription);
                            configObj.businessObject.description = modalDescription;
                        }
                        self.mappedTo(model.get('name'));
                        var valueDef = model.get('valueDefinition');
                        $('#addValueDefinition' + self.encodeId).hide();
                        if (valueDef) {
                            self.mappedValueDef(valueDef.get('name'));
                            self.usedValue = valueDef.id;
							self.showParentValueId = valueDef.getNestedParent().get('id');
                            $('#showValueMappedInfo' + self.encodeId).show();
                            $('#editValueDefinition' + self.encodeId).hide();
                            $('#deleteValueDef' + self.encodeId).hide();
                            configObj.businessObject.isValueDef = self.usedValue;
                        } else {
                            self.showValueDefinition(false);
                        }
                        
                        if (!name || self.syncName()) {
							self.name(modalName);
                            configObj.businessObject.name = modalName;
                        }
                        if(modalName == "" || modalName == '' || modalName == null){
                            self.name(modalDescription);
                        }
                        if (model.get('type') === 'vdml_ValueProposition' || model.get('type') ===  "vdml_ValueStream") {
                            self.isVPShape(true);
                            self.showCharts(true);
                            if (window.checkContextForRevision()) {
                                self.enableValueAdd(false);
                            }
                        }
                        if (model.get('type') === 'vdml_Activity'){
                            self.showCharts(true);
                            self.isVPShape(true);
                            if (window.checkContextForRevision()) {
                                self.enableValueAdd(false);
                            }
							var capMappedModal = model.get('capabilityRequirement');
							if(capMappedModal){
							    self.capMappedDiv(true);
							    $('#capabilityName').html(capMappedModal.get('name'));
								$('#capMappedTo').hide();
								$('#showCapMappedInfo').show();
								$('#showCapMappedEdit').hide();
				                $('#removeCapMapping').hide();
							}else if(self.showCapabilityId){
							      var capModel = self.getCapabilityElementModel(self.showCapabilityId);
								  $('#capabilityName').html(capModel.get('name'));
								  $('#capMappedTo').hide(); 
								  $('#showCapMappedInfo').show();
								  $('#showCapMappedEdit').hide();
				                  $('#removeCapMapping').hide();
							}else{
							  self.capMappedDiv(false);
							}
                        }
						if (model.get('type') === 'vdml_CapabilityOffer'){
							var capMappedModal = model.get('capability');
							if(capMappedModal){
							     self.capMappedDiv(true);
							     $('#capabilityName').html(capMappedModal.get('name'));
								 $('#capMappedTo').hide(); 
								 $('#showCapMappedInfo').show();
								 $('#showCapMappedEdit').hide();
				                 $('#removeCapMapping').hide();
								 //self.capMappedTo(capMappedModal.get('name'));
							}else if(self.showCapabilityId){
							         var capModel = self.getCapabilityElementModel(self.showCapabilityId);
								     $('#capabilityName').html(capModel.get('name'));
									 $('#capMappedTo').hide(); 
									 $('#showCapMappedInfo').show();
									 $('#showCapMappedEdit').hide();
									 $('#removeCapMapping').hide();
							}else{
							  self.capMappedDiv(false);
							}
                        }
						if(model.get('type') === 'vdml_BusinessItem'){
						    self.capMappedDiv(false);
						}
                        if (self.disableUpdateMappingTypes.indexOf(model.get('type')) >= 0 || self.model.get('type') === "vdml_CapabilityLibraryDiagram" || self.model.get('type') === 'smm_BusinessIndicatorLibraryDiagram') {
                            $("#updateMapping" + self.encodeId).attr('disabled', 'disabled');
                            $("#updateMapping" + self.encodeId).hide();
                        }
						if(self.showDefinition()){
							self.showDefinitionSection(model.get('definition'));
						}
                    }
                }
                var mappingEleId = configObj.addOptions.businessObject.mid;
                var parentId = configObj.addOptions.businessObject.mpid;
                var repId = DataManager.getDataManager().getRepositoryId(parentId);
                var vdmStore = DataManager.getDataManager().getVDMStore(repId);
                var shapeType = configObj.addOptions.businessObject.$type;
                if(shapeType == "vdml:Value"){
					self.mappedToValues(true);
				}
				self.model.getMappedElement(mappingEleId, parentId, shapeType, null, fetchMappedEleName);
            }
            this.map = function () {
				var self = this;
                if (self.wizard) {
                    window.cleanViewModel(self.wizard, $('#wizardModalContent'));
                }
                var shapeDesc = self.description() ? self.description().trim() : null;
                if (configObj.businessObject.description != shapeDesc) {
                    configObj.businessObject.description = shapeDesc;
                    configObj.parentView.enableComplete(true);
                }
                var shapename = self.name() ? self.name().trim() : null;
                if (configObj.businessObject.name != shapename) {
                    configObj.businessObject.name = shapename;
                    configObj.parentView.loadTable();
					configObj.parentView.enableComplete(true);
                    self.eventBus.fire('elements.changed', {
                        elements: [configObj.addOptions.element]
                    });
                }
                var mappingWizardPath = self.mappingWizardPathMap[self.model.get('type')];
                import('../../../../../DynamicClass').then(({ default: DynamicClass }) => {
				    var MappingWizardViewModel = DynamicClass.getView(mappingWizardPath);
                    if (MappingWizardViewModel) {
                            var options = { parentView: configObj.parentView, shape: configObj.addOptions.element, name: self.name(), description: self.description() };
                            var businessObject = configObj.addOptions.businessObject;
                            self.wizard = MappingWizardViewModel.getInstance(self.model, options, businessObject, function (model, revisionEcoMap) {
                                if(self.model.get('type') === 'vdml_CapabilityLibraryDiagram'){
                                    if (revisionEcoMap.mid) {
                                        configObj.addOptions.businessObject = revisionEcoMap;
                                        self.getMappedElement();
                                        var ele = self.elementRegistry.get(revisionEcoMap.id);
                                        //fitShapes(ele);
                                        var newBounds = { x: ele.x, y: ele.y, width: ele.width, height: ele.height };
                                        self.modeling.resizeShape(ele, newBounds);
                                    }
                                } else {
                                    if (revisionEcoMap) {
                                        self.cleanModal();
                                    } else if (configObj.addOptions.businessObject.mid) {
                                        self.getMappedElement();
                                    }
                                }
                            });
                            self.wizard.startWizard();
                    }
                });
            }

            this.updateMapping = function () {
				var self = this;
                if (self.wizard) {
                    window.cleanViewModel(self.wizard, $('#wizardModalContent'));
                }
                if (configObj.businessObject.name != self.name()) {
                    configObj.businessObject.name = self.name();
                    configObj.parentView.loadTable();
                    //configObj.parentView.enableComplete(true);
                    self.eventBus.fire('elements.changed', {
                        elements: [configObj.addOptions.element]
                    });
                }
                var mappingWizardPath = self.mappingWizardPathMap[self.model.get('type')];

				var dataManager = DataManager.getDataManager();
				var options = { parentView: configObj.parentView, shape: configObj.addOptions.element };
				var businessObject = configObj.addOptions.businessObject;
                import('../../../../../DynamicClass').then(({ default: DynamicClass }) => {
				    var MappingWizardViewModel = DynamicClass.getView(mappingWizardPath);
                    self.wizard = MappingWizardViewModel.getInstance(self.model, options, businessObject, function (model, revisionEcoMap) {
					    if (revisionEcoMap) {
						    self.cleanModal();
					    } else if (configObj.addOptions.businessObject.mid) {
						    self.getMappedElement();
					    }
				    });
				    self.wizard.startWizard();
                });
            }
            this.createRevisionBasedOnContext = function (pack, reviCallback) {
                if (window.checkContextForRevision() && this.model.id.indexOf('Common') === -1) {
                    function createMapRevision() {
                        window.utils.startSpinner('revisionSpinner', "Creating a copy...");
                        function fetchBmModel(newVdmPackage) {
                            if (newVdmPackage) {
                                DataManager.getDataManager().releaseSaveLock();
                                DataManager.getDataManager().set('isActive', false);
                                reviCallback(newVdmPackage);
                                /*DataManager.getDataManager().saveData({
                                    success: function () {
                                        reviCallback(newVdmPackage);
                                    },
                                    error: function () {
                                        reviCallback(newVdmPackage);
                                    },
                                    persist: true
                                });*/
                            }
                        }
                        window.setTimeout(function () {
                            DataManager.getDataManager().acquireSaveLock(function () {
                                pack.createRevision(DataManager.getDataManager().get('viewAlternative'), DataManager.getDataManager().get('currentWorkspace').get('id'), fetchBmModel);
                            });
                        }, 100);
                    }

                    createMapRevision();
                }
                else {
                    reviCallback(pack);
                }
            };
            this.removeMapping = function () {
                var oldPack = self.model.getNestedParent();
                self.createRevisionBasedOnContext(oldPack, function (pack) {
                    var model, altId = DataManager.getDataManager().getRepositoryId(pack.id);
					var modeler = configObj.parentView.modeler;
					var shapes = modeler._definitions.rootElements[0].flowElements;
					var mid = configObj.addOptions.businessObject.get('mid');
					var type = configObj.addOptions.businessObject.$type;
                    if (pack.id !== oldPack.id) {
                        var modelId = self.model.id.substr(self.model.id.lastIndexOf('@') + 1, self.model.id.length);
                        var model = pack.get('diagrams').findWhere({ 'id': altId + modelId });
                        var diagrams = pack.get('diagrams');
                        for (var i = 0; i < diagrams.length; i++) {
                            pack.fixDiagramOnRevision(diagrams.at(i), altId);
                        }
						var hasMoreMappings = false;
						if (shapes) {
							for (var i = 0; i < shapes.length; i++) {
								if (mid === shapes[i].get('mid')) {
									hasMoreMappings = true;
									break;
								}
                                if (shapes[i].$type === "vdml:Perspective") {
                                    var childrenShapes = shapes[i].flowElements;
                                    if (childrenShapes && childrenShapes.length > 0) {
                                        for (var j = 0; j < childrenShapes.length; j++) {
                                            if (mid === childrenShapes[j].get('mid')) {
                                                hasMoreMappings = true;
                                                break;
                                            }
                                        }
                                    }
                                }
							}
						}
                        
						if (!hasMoreMappings) {
							var modelType = configObj.parentView.mappingObject[type];
                            if (!modelType) {
                                modelType = configObj.parentView.mappingObject[type.replace("vdml", "smm")];
                            }
                            var modelColl = modelType ? model.get(modelType.collectionName) : null;
							
							if (mid.indexOf(window.plansKey) != -1) {
								var newMid = mid;
							} else {
								newMid = altId + mid.substr(mid.lastIndexOf('@') + 1, mid.length);
							}
							if (modelColl) {
								modelColl.remove(newMid);
							}
						}
						configObj.parentView.enableComplete(false);
						self.cleanModal();
                        window.utils.stopSpinner('revisionSpinner');
                        DataManager.getDataManager().get('router').navigate("views/" + window.utils.getPlanNavigateUrl() + "/" + pack.get('id') + "/" + pack.get('type') + "/" + pack.get('version') + "/" + model.id + "/" + model.get('type') + "/" + DataManager.getDataManager().get('viewAlternative'), { trigger: true });
                    } else {
                        model = self.model;
						var capRequirement = self.mappedElementModel.get('capabilityRequirement') ? self.mappedElementModel.get('capabilityRequirement') : self.mappedElementModel.get('capability');
						configObj.addOptions.businessObject.set('mid', undefined);
						configObj.addOptions.businessObject.set('mpid', undefined);
						configObj.addOptions.businessObject.set('syncName', undefined);
						self.syncName(false);
						//configObj.addOptions.businessObject.set('mcid', capRequirement.get('id'));
						//configObj.addOptions.businessObject.set('mcpid', capRequirement.getNestedParent().id);
						if(capRequirement){
						   self.showCapabilityId=capRequirement.get('id');self.showCapabilityName(capRequirement.get('name'));
						   self.showParentCapabilityId = capRequirement.getNestedParent().id;
						}
                        self.mappedTo(null);
                        self.mappedValueDef(null)
						/*if(configObj.addOptions.businessObject.$type === "vdml:Activity" || configObj.addOptions.businessObject.$type === "vdml:Competency"){
							self.capMappedDiv(true);
							$('#capMappedTo').show();
							$('#capabilityName').html('');
							$('#showCapMappedInfo').hide();
						}*/
						self.mappedToValues(false);
						var hasMoreMappings = null;
						if (shapes) {
							for (var i = 0; i < shapes.length; i++) {
								if (mid === shapes[i].get('mid')) {
									hasMoreMappings = true;
									break;
								}
                                if (shapes[i].$type === "vdml:Perspective") {
                                    var childrenShapes = shapes[i].flowElements;
                                    if (childrenShapes && childrenShapes.length > 0) {
                                        for (var j = 0; j < childrenShapes.length; j++) {
                                            if (mid === childrenShapes[j].get('mid')) {
                                                hasMoreMappings = true;
                                                break;
                                            }
                                        }
                                    }
                                }
							}
						}
                        if(type == "vdml:Value"){
                            var shapename = configObj.addOptions.businessObject.name;
                            self.modeling.updateLabel(configObj.addOptions.element, shapename);
                        }
                        if (!hasMoreMappings) {
                            var modelType = configObj.parentView.mappingObject[type];
                            if (!modelType) {
                                modelType = configObj.parentView.mappingObject[type.replace("vdml", "smm")];
                            }
                            var modelColl = modelType ? model.get(modelType.collectionName) : null;                           
                            modelColl.remove(mid);
							if ((type == "vdml:Value" || type == "vdml:Activity" || type == "vdml:ValueProposition" || type == "vdml:ValueStream") && configObj.parentView.chartViewInstance && configObj.parentView.chartViewInstance.initialize) {
								configObj.parentView.chartViewInstance.initialize(configObj.parentView.model, true, configObj.parentView, self.getValueList(configObj.parentView.model));
							}
						}
						configObj.parentView.loadTable();
                    }
                })
			}

			this.getValueList = function (model) {
				var valueList = [];
				if (model.get('type') === "vdml_EcoMapDiagram") {
					_.each(model.get('valueProposition').models, function (vp) {
						valueList = valueList.concat(vp.get('component').models.concat());
					});
				}
				else if (model.get('type') === "vdml_StrategyMapDiagram") {
					valueList = model.get('value').models.concat();
				} else if (model.get('type') === "vdml_ValueStreamMapDiagram"){
					_.each(self.model.get('valueStream').models, function (vs) {
						//valueList = valueList.concat(vs.get('activityValue').models.concat());
						if(vs.get('persuedProposition')) {
							valueList = valueList.concat(vs.get('persuedProposition').get('component').models.concat());
						}
					});
					_.each(self.model.get('activity').models, function (act) {
						//valueList = valueList.concat(vs.get('activityValue').models.concat());
						act.get('containedPort').each(function (port) {
	                        if (port.get('type') === "vdml_OutputPort") {
	                            port.get('valueAdd').each(function (valueAdd) {
	                                valueList.push(valueAdd);
	                            });
	                        }
	                    });
					});
				}
				return valueList;
			}

            this.addValueClick = function () {//for vp and activity shape
                var id = htmlEscape(window.guidGenerator()) + "valueProposition";
                var currentAltId = DataManager.getDataManager().get('viewAlternative');
                var docTypeObj = Backbone.Relational.store.getObjectByName("transformation.Alternative");
                var currentAlt = docTypeObj.find({ id: currentAltId });
                var scenario = currentAlt.getMainScenario(self.mappedElementModel.getNestedParent());
                //DataManager.getDataManager().get('currentPlan').getValuesThatCanBeRefferedTo(function (values) {
                var aggregatedColl = new Backbone.Collection();
                var valuesColl = [];
                    //self.aggregatedColl.add(values);
                if (self.mappedElementModel.get('type') === "vdml_Activity") {
                    self.mappedElementModel.get('containedPort').each(function (port) {
                        if (port.get('type') === "vdml_OutputPort") {
                            port.get('valueAdd').each(function (actValue) {
                                valuesColl.push({ modalId: htmlEscape(actValue.id), name: actValue.get('name'), activityModalId: htmlEscape(self.mappedElementModel.id)});
                            })
                        }
                    });
					var actObj = [{ originalId: self.mappedElementModel.get('id'), modalId: htmlEscape(self.mappedElementModel.get('id')), name: self.mappedElementModel.get('name') }];
					var addOptions = { 'type': 'createWhatValues', 'activities': actObj, 'aggregatedColl': aggregatedColl, 'aggregationView': true, 'mainScenario': scenario, 'valuesColl': valuesColl, 'bm': self.mappedElementModel, 'currentAlternativeModel': currentAlt, 'disableAggr': true};
                } else {
                    var targetModal = self.mappedElementModel;
                    if (self.mappedElementModel.get('type') === 'vdml_ValueStream') {
                        targetModal = self.mappedElementModel.get('persuedProposition');
                    }
					if(!targetModal){
						return;
					}
                    targetModal.get('component').each(function (comp) {
                        valuesColl.push({ modalId: htmlEscape(comp.id), name: comp.get('name')});
                    });
                    var overAllSatisfaction = targetModal.get('overAllSatisfaction');
                    var addOptions = { 'type': 'valueProposition', 'aggregatedColl': aggregatedColl, 'mainScenario': scenario, 'aggregationView': true, 'overAllSatisfaction': overAllSatisfaction, 'valuesColl': valuesColl, 'enableOverallSatisfaction': false, 'currentAlternativeModel': currentAlt, 'valuePropositionOwner': targetModal };
                }
                window.getAndCreateModalDialog(self.model, id, BusinessModelMixin, null, "ValueDetails", self.addValue, addOptions);
                //});
            };

			this.showDefinitionSection = function(contentDefinition){
                var summernote = $('#summernoteDefinition' + self.encodeId);
				summernote.summernote('destroy');
                summernote.summernote(
                    {
                        airMode: true,
                       // dialogsInBody: true,
					    height: 100, 
                        minHeight: null,             // set minimum height of editor
                        maxHeight: null,             // set maximum height of editor
                        focus: true,
						placeholder: '',//'write here...',
                        disableResizeEditor: true, //disable resize 
                        toolbar: [
                            ['style', ['bold', 'italic', 'underline', 'clear']],
                            ['font', ['fontname', 'fontsize', 'color', 'strikethrough', 'superscript', 'subscript']],
                            ['para', ['style', 'ul', 'ol', 'paragraph']],
                            ['height', ['height']]
                            //['insert', ['link', 'table', 'hr']]
                        ]
                    });
                //summernote.find('.note-statusbar').hide();
				$('.note-insert').hide();
				$('.note-table').hide();
                if (contentDefinition) {
                    summernote.summernote('code', contentDefinition);
                    summernote.summernote("editor.clearHistory", 'editor');
                }
				$('.note-editor').css({"border-color": "#C1E0FF", "border-width":"1px", "border-style":"solid"});
                $("#summernotecss").attr("href", "js/libs/summernote/dist/summernote.css");
			}
            this.addOrEditDocumentation = function (data, event) {
                var documentationName, modalTitle, newDoc = true;
                var encodeId;
                var disabled = '';
                if (self.hideIcons()) {
                    disabled = 'hide';
                }
                if (data.type === "vdml:Documentation" || data.type === "cmmn:Documentation" || data.type === "bpmn:Documentation") {
                    newDoc = false;
                    encodeId = data.id;
                    documentationName = data.name;
                    modalTitle = "Edit Documentation";
                } else {
                    encodeId = htmlEscape(window.guidGenerator());
                    modalTitle = "Create Documentation";
                }
                var nameID = "documentationName" + encodeId;
                var htmlContent = "<div class=\"row\">";
                htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
                htmlContent = htmlContent + "<div class=\"col-xs-4\">";
                htmlContent = htmlContent + "<span class=\"control-label\">Name<label style=\"color:orange\">*</label></span>";
                htmlContent = htmlContent + "</div>";
                htmlContent = htmlContent + "<div class=\"col-xs-6\">";
                if (!newDoc && documentationName != "") {
                    htmlContent = htmlContent + "<input class=\"form-control \" type=\"text\" value='" + documentationName + "' id ='" + nameID + "'></input>";
                } else {
                    htmlContent = htmlContent + "<input class=\"form-control \" type=\"text\" id ='" + nameID + "'></input>";
                }

                htmlContent = htmlContent + "</div>";
                htmlContent = htmlContent + "<div class=\"col-xs-1\"></div>";
                htmlContent = htmlContent + "</div><br>";
                htmlContent = htmlContent + "<div class=\"row\">";
                htmlContent = htmlContent + "<div id=\"removeElement\" class=\"col-xs-1\"></div>";
                htmlContent = htmlContent + "<div id=\"changeClass\" style=\"border-color: rgb(193, 224, 255); border-width: 1px; border-style: solid;\" class=\"col-xs-10\">";
                htmlContent = htmlContent + "<div id='summernote" + encodeId + "'+></div></div>";
                htmlContent = htmlContent + "</div>";
                bootbox.dialog({
                    title: '  &nbsp;' + modalTitle,
                    message: htmlContent,
                    buttons: {
                        main: {
                            label: "Close",
                            className: "btn btn-default pull-left",
                        },
                        /*edit: {
                            label: "Edit",
                            className: "docEdit btn btn-default " + disabled,

                            callback: function () {
                                initializeSummernote(false);
                                $('.docComplete').show();
                                $('#' + nameID).removeAttr('disabled');
                                $('.docEdit').hide();
                                $('#removeElement').remove();
                                
                                $('#changeClass')[0].className = "col-xs-12";
                                return false;
                            }
                        },*/
                        success: {
                            label: "Complete",
                            className: "docComplete btn btn-default " + disabled,
                            callback: function () {
                                $('.docComplete').show();
                                var summernote = $('#summernote' + encodeId);
                                var contentData = summernote.summernote('code');
                                var contentJQuery = $(contentData);
                                contentData = "";
                                var canvasImgs = contentJQuery.find('img').each(function () {
                                    if (this.getAttribute('diagramId')) {
                                        this.src = "";
                                    }
                                });
                                contentJQuery.each(function () {
                                    contentData = contentData + this.outerHTML;
                                });
                                if (contentJQuery.length == 0) {
                                    contentData = summernote.summernote('code');
                                }
                                //var documentations = configObj.businessObject.get('documentation');
                                var documentations = configObj.businessObject.cmmnElementRef ? configObj.businessObject.cmmnElementRef.get('documentation') : configObj.businessObject.get('documentation');
                                if (newDoc) {
                                    var attributes = {};
                                    attributes.name = $('#documentationName' + encodeId).val();
                                    attributes.text = contentData;
                                    //var tag = self.bmpn?'bpmn:Documentation':'vdml:Documentation';
                                    var tag = null;
                                    if (self.bmpn) {
                                        tag = 'bpmn:Documentation';
                                    } else if (self.cmmn) {
                                        tag = 'cmmn:Documentation';
                                    }else {
                                        tag = 'vdml:Documentation';
                                    }                                   
                                    var newDocumentation = self.vdmlFactory.create(tag, attributes);
                                    documentations.push(newDocumentation);
                                    self.refreshDocumentationsList();
                                } else {
                                    var documentation = _.find(documentations, function (e) { return e.id === data.id; });
                                    if (documentation) {
                                        documentation.set('text', contentData);
                                        documentation.set('name', $('#documentationName' + encodeId).val());
                                        self.refreshDocumentationsList();
                                    }
                                }
                                configObj.parentView.enableComplete(true);
                            }
                        }
                    }
                });
                function initializeSummernote(airMode) {
                    var summernote = $('#summernote' + encodeId);
					summernote.summernote('destroy');
                    summernote.summernote(
                        {
                            airMode: airMode,
                            dialogsInBody: true,
                            minHeight: null,             // set minimum height of editor
                            maxHeight: null,             // set maximum height of editor
                            focus: true,
                            placeholder: '',//'write here...',
                            disableResizeEditor: true, //disable resize 
                            toolbar: [
                                ['style', ['bold', 'italic', 'underline', 'clear']],
                                ['font', ['fontname', 'fontsize', 'color', 'strikethrough', 'superscript', 'subscript']],
                                ['para', ['style', 'ul', 'ol', 'paragraph']],
                                ['height', ['height']],
                               // ['insert', [ 'link', 'table', 'hr']]
                            ]
                        });
                    summernote.find('.note-statusbar').hide();
					$('.note-insert').hide();
					$('.note-table').hide();
                    $('#' + nameID).attr('enabled', 'enabled');
                    if (!newDoc) {
                        summernote.summernote('code', data.text);
                        summernote.summernote("editor.clearHistory", 'editor');
                    }
                    $('#documentationName' + encodeId).keyup(function (view) {
                        if (view.currentTarget.value != "") {
                            $('.docComplete').removeAttr('disabled');
                        } else {
                            $('.docComplete').attr('disabled', 'disabled');
                        }
                    });
                    var modalBody = summernote.parents('.modal-body');
                    modalBody.css('max-height', '400px');
                    modalBody.css('overflow-y', 'auto');
                    $("#summernotecss").attr("href", "js/libs/summernote/dist/summernote.css");
                }
                initializeSummernote(true);
                //$('.docComplete').hide();
            }
            this.deleteDocumentation = function (data, event) { 
                bootbox.confirm("Do you want to delete the Documentation?", function (result) {                  
                    if (result) {
                        var documentations = configObj.businessObject.cmmnElementRef ? configObj.businessObject.cmmnElementRef.get('documentation') : configObj.businessObject.get('documentation');
                        var index = documentations.findIndex(function (e) { return e.id === data.id });
                        documentations.splice(index, 1);
                        self.refreshDocumentationsList();
                        configObj.parentView.enableComplete(true);
                    }
                });
            }
            
            this.saveDefinition = function () {               
				var summernote = $('#summernoteDefinition' + self.encodeId);
                var contentData = summernote.summernote('code');
				if(contentData == "<p><br></p>"){
					contentData = null;
				}
                if ((contentData && !configObj.businessObject.definition) || (configObj.businessObject.definition && configObj.businessObject.definition.text !== contentData)) { 
					if (self.mappedElementModel  && self.mappedElementModel.get('definition') !== contentData){
						self.mappedElementModel.set('definition', contentData);
						configObj.businessObject.set('definition', null);
						configObj.parentView.enableComplete(true);
					}else if(!self.mappedElementModel) {
						if (!configObj.businessObject.definition) {
							var attributes = {};
							attributes.text = contentData;
							var tag = self.bmpn?'bpmn:Definition':'vdml:Definition';
							var newDefinition = self.vdmlFactory.create(tag, attributes);
							configObj.businessObject.set('definition', newDefinition);
						} else {
							configObj.businessObject.definition.text = contentData;
						}
						 configObj.parentView.enableComplete(true);
					}
                }
            }

            this.viewValueClick = function () {
                if (self.mappedElementModel.get('type') === 'vdml_ValueProposition' || self.mappedElementModel.get('type') === 'vdml_ValueStream' || self.mappedElementModel.get('type') === 'vdml_Activity') {
                    var targetModal = self.mappedElementModel;
                    if (self.mappedElementModel.get('type') === 'vdml_ValueStream') {
                        targetModal = self.mappedElementModel.get('persuedProposition');
                    }
                    var mypackage = targetModal.getNestedParent();
                    function fetchMappedBm(pack) {
                        var bms = pack.get('businessModel');
                        for (var i = 0; i < bms.length; i++) {
                            if (targetModal.get('isValueFormula')) {
                                var bmVFs = bms.at(i).get('bmValueFormula');
                                for (var j = 0; j < bmVFs.length; j++) {
                                    var vp = bmVFs.at(j).get('valueFormula');
                                    if (vp === targetModal) {
                                        self.chartBM = bms.at(i);
                                        break;
                                    }
                                }
                            } else if (targetModal.get('type') === 'vdml_ValueProposition') {
                                var bmVPs = bms.at(i).get('bmValueProposition');
                                for (var j = 0; j < bmVPs.length; j++) {
                                    var vp = bmVPs.at(j).get('valueProposition');
                                    if (vp === targetModal) {
                                        self.chartBM = bms.at(i);
                                        break;
                                    }
                                }
                            } else {
                                var bmActs = bms.at(i).get('bmActivity');
                                for (var j = 0; j < bmActs.length; j++) {
                                    var act = bmActs.at(j).get('activity');
                                    if (act === targetModal) {
                                        self.chartBM = bms.at(i);
                                        break;
                                    }
                                }
                            }
                            if (self.chartBM) {
                                break;
                            }
                        }
                    }
                    fetchMappedBm(mypackage);
                    if (!self.chartBM) {
                        var depPack = mypackage.get('dependentPackage');
                        for (var i = 0; i < depPack.length; i++) {
                            if (depPack.at(i).get('type') === "vdml_ValueDeliveryModel") {
                                fetchMappedBm(depPack.at(i));
                                if (self.chartBM) {
                                    break;
                                }
                            }
                        }
                    }
                    if (self.chartBM) {
                        var addOptions = { 'chartBM': self.chartBM };
                        window.getAndCreateModalDialog(self.model, htmlEscape(targetModal.id), EcoMapDiagramMixin, targetModal, "ViewValues", null, addOptions);
                    }
                }
            };

            this.getBMList = function (mappedObj) {
                var mypackage = mappedObj.getNestedParent();
                var bmList = [];
                function fetchMappedBm(pack) {
                    var bms = pack.get('businessModel');
                    for (var i = 0; i < bms.length; i++) {
                        var bmActs = bms.at(i).get('bmActivity');
                        for (var j = 0; j < bmActs.length; j++) {
                            var act = bmActs.at(j).get('activity');
                            if (act === mappedObj) {
                                bmList.push(bms.at(i));
                            }
                        }
                    }
                }
                fetchMappedBm(mypackage);
                var depPack = mypackage.get('dependentPackage');
                for (var i = 0; i < depPack.length; i++) {
                    if (depPack.at(i).get('type') === "vdml_ValueDeliveryModel") {
                        fetchMappedBm(depPack.at(i));
                    }
                }
                return bmList;
            }

            this.addValue = function (view, modalId, type, del) {
                window.utils.startSpinner('values', 'Saving Value ...');
                window.setTimeout(function () {
                    if (self.mappedElementModel.get('type') != 'vdml_Activity') {
                        var targetModal = self.mappedElementModel;
                        if (self.mappedElementModel.get('type') === 'vdml_ValueStream') {
                            targetModal = self.mappedElementModel.get('persuedProposition');
                        }
                        view.saveData(targetModal, targetModal, function () {
                        	if (configObj.parentView.chartViewInstance && configObj.parentView.chartViewInstance.initialize) {
								configObj.parentView.chartViewInstance.initialize(configObj.parentView.model, true, configObj.parentView, self.getValueList(configObj.parentView.model));
                            }
                            window.cleanDialogModel(modalId, view);
                            window.utils.stopSpinner('values');
                        });
                    }
                    else {
                        view.saveWhatValuesData(self.mappedElementModel, [], [], function (activityValue) {
                            
                            var act = activityValue.getParent().getParent();
                            var actBMList = self.getBMList(act);
                            var valueStreamId = configObj.businessObject.$parent.mid;
                            if (valueStreamId) {
                                var vs = Backbone.Relational.store.getObjectByName('vdml.ValueStream').find({ id: valueStreamId });
                                vs.get('activityValue').add(activityValue);
                                _.each(actBMList, function (bm) {
                                    var vsExists = bm.get('bmValueStream').findWhere({ 'id': valueStreamId });
                                    var bmAct = bm.get('bmActivity').findWhere({ 'activity': act });
                                    if (bmAct && vsExists) {
                                        bmAct.get('activityValue').add(activityValue);
                                    }
                                });
                            } else {
                                _.each(actBMList, function (bm) {
                                    var bmAct = bm.get('bmActivity').findWhere({ 'activity': act });
                                    if (bmAct) {
                                        _.each(bm.get('bmValueStream').models, function (valStream) {
                                            var actExists = valStream.get('valueStreamActivity').findWhere({ 'id': act.id });
                                            if (actExists){
                                                valStream.get('activityValue').add(activityValue);
                                                bmAct.get('activityValue').add(activityValue);
                                            }
                                        });
                                        
                                    }
                                });
                            }
                            if (configObj.parentView.chartViewInstance && configObj.parentView.chartViewInstance.initialize) {
								configObj.parentView.chartViewInstance.initialize(configObj.parentView.model, true, configObj.parentView, self.getValueList(configObj.parentView.model));
                            }
                            window.cleanDialogModel(modalId, view);
                            window.utils.stopSpinner('values');
                        }, []) 
                    }
                }, 100);
            }
            function getRandomInt(min, max) {
                return Math.floor(Math.random() * (max - min + 1)) + min;
            }
            this.name.subscribe(function (val) {
                self.description(val);
            });

			this.syncName.subscribe(function (val) {
				if(val){
					//self.enableName(false);
					self.name(self.mappedTo());
					var desc = self.mappedElementModel.get('description');
					if(self.mappedElementModel.get('type') == "vdml_ValueStream"){
						desc = self.mappedElementModel.get('persuedProposition').get('description');
					}
					self.description(desc);
					//self.enableDescription(false);
				}else {
					//self.enableName(true);
					//self.enableDescription(true);
				}
            });

            this.afterRenderView = function () {
                $('#alternativeName' + self.encodeId).focus();
                //$('#editDetailsModalContent'+self.encodeId).css('width',"660px");
				$("#alternativeName"+self.encodeId).keyup(function (view) {
					if(self.syncName() && self.mappedElementModel) {
						var newValue = view.currentTarget.value.trim();
						if(!self.disallowedNames){
							var alt = DataManager.getDataManager().getAlternativeSync(DataManager.getDataManager().get('viewAlternative'));
							var parentType = "";
							if(self.mappedElementModel.get('type') == "vdml_ValueProposition" || self.mappedElementModel.get('type') == "vdml_ValueFormula" || self.mappedElementModel.get('type') == "vdml_ValueStream"){
								parentType = "providedProposition";
							}else if(self.mappedElementModel.get('type') == "vdml_Activity"){
								parentType = "activity";
							}else if(self.mappedElementModel.get('type') == "vdml_OrgUnit" || self.mappedElementModel.get('type') == "vdml_MarketSegment" || self.mappedElementModel.get('type') == "vdml_Actor"){
								parentType = "collaboration";
							}else if(self.mappedElementModel.get('type') == "vdml_Party"){
								parentType = "collaborationRole";
							}else if(self.mappedElementModel.get('type') == "CapabilityOffer"){
								parentType = "capabilityOffer";
							}else if(self.mappedElementModel.get('type') == "vdml_BusinessItem"){
								parentType = "businessItem";
							} else if(self.mappedElementModel.get('type') == "vdml_ValuePropositionComponent"){
								parentType = "component";
							} else if(self.mappedElementModel.get('type') == "vdml_ValueAdd"){
								parentType = "valueAdd";
							}
							self.disallowedNames = parentType?alt.getDisallowedNames(self.mappedElementModel, parentType):null;
						}
						var unique = self.mappedElementModel.getNestedParent().checkUniqueName(newValue,self.mappedElementModel,self.disallowedNames);
						if(!unique || newValue == ''){
                    		self.enableComplete(false); 
							$('#message'+self.encodeId).text(DataManager.getDataManager().get('localeManager').get('DuplicateSyncMessage'));
						}else {
							self.enableComplete(true); 
							$('#message'+self.encodeId).text("");
						}
					}
				});
                $('#addAnotherColor').on('click', function () {
                    event.stopPropagation();
                    $('#slipsColor').colorpicker();
                    $('.colorpickerComp').removeClass('hide');
                });
                $('#editDetailsModalContent' + self.encodeId).resize(function () {
                    $('#editDetailsModalContent' + self.encodeId).css('max-height', $('#editDetailsModalContent' + self.encodeId).height())
                })
                if (configObj.businessObject.$type === "vdml:Value") {
					self.showValueDefinition(true);
                    $('#showValueMappedInfo' + self.encodeId).hide();
                    $('#deleteValueDef' + self.encodeId).hide();
                    $('#addValueDefinition' + self.encodeId).show();
                }
				var mappingEleId = configObj.businessObject.mid;
                if (mappingEleId) {
                    self.getMappedElement();
                } else {
                    if (configObj.businessObject && configObj.businessObject.mcid && configObj.businessObject.$type === "vdml:Value") {
                        var valueDef = Backbone.Relational.store.getObjectByName('vdml.ValueDefinition').find({ id: configObj.businessObject.mcid });
                        if (valueDef) {
                            self.mappedValueDef(valueDef.get('name'));
                            self.usedValue = valueDef.id;
                            $('#showValueMappedInfo' + self.encodeId).show();
                            $('#addValueDefinition' + self.encodeId).hide();
                            $('#editValueDefinition' + self.encodeId).show();
                            $('#deleteValueDef' + self.encodeId).show();
                        }
                    }
                    var capElementId = (configObj.businessObject.mcid) ? configObj.businessObject.mcid.slice() : configObj.businessObject.isCapElement;
                    configObj.businessObject.mcid = capElementId;
                    var capModel = self.getCapabilityElementModel(capElementId);
                    if (capModel) {
					    $('#capabilityName').html(capModel.get('name'));
						$('#capMappedTo').hide();
						$('#showCapMappedEdit').show();
				        $('#removeCapMapping').show();
						$('#isOutput' + self.encodeId).attr('disabled','disabled');
						$('#stopActivity' + self.encodeId).attr('disabled','disabled');
					}
					if(self.showDefinition()){
						var annotation = configObj.businessObject.definition ? configObj.businessObject.definition.text : '';
						self.showDefinitionSection(annotation);
					}
				}
				if (self.model.get('type') === "vdml_ValueStreamMapDiagram") {
                    if (configObj.businessObject.$type === "vdml:ValueStream" && configObj.businessObject.mid && configObj.businessObject.flowElements) {
                        for (var i = 0; i < configObj.businessObject.flowElements.length; i++) {
                            if (configObj.businessObject.flowElements[i].mid) {
                                $("#deleteMapping" + self.encodeId).attr('disabled', 'disabled');
                                break;
                            }
                        }
                    }
					var parent = configObj.businessObject.$parent;
					if (parent && parent.$type === "vdml:CapabilityContainer" && parent.incoming && parent.incoming[0] && parent.incoming[0].sourceRef) {
						var actId = parent.incoming[0].sourceRef.mid;
						self.allowMapping(actId ? true : false);
					}
				}
				if (self.model.get('type') === "vdml_CapabilityLibraryDiagram") {
                    self.hideIcons(false);var parent = configObj.businessObject.$parent;
                    if (configObj.businessObject.$parent && configObj.businessObject.$parent.$type !== 'vdml:CapabilityLibraryDiagram') {
                        self.allowMapping(parent.mid ? true : false);
                    }
                    if (configObj.businessObject.flowElements) {
                        for (var i = 0; i < configObj.businessObject.flowElements.length; i++) {
                            if (configObj.businessObject.flowElements[i].mid) {
                                $("#deleteMapping" + self.encodeId).attr('disabled', 'disabled');
                                break;
                            }
                        }
                    }
                }
                $(document).on('colorAdd', '.colorpicker', function () {
                    if (!self.usedColors) {
                        return;
                    }
                    $('.colorpickerComp').addClass('hide');
                    var lengthPrev = self.usedColors.length;
                    var value = $('#slipsColor input').val();
                    value = value.replace(/ /g, '');
                    self.usedColors.push(value);
                    $.unique(self.usedColors);
                    var length = self.usedColors.length;
                    if (length > lengthPrev) {
                        var wrapper_id = 'w' + getRandomInt(1, 1000);
                        $('<div class="dwrapper" type="button" id="' + wrapper_id + '" rel="' + value + '"> </div>').appendTo('.colors-wrapper');
                        $('<div class="dcolor" style="background-color:' + value + '"> </div>').appendTo('#' + wrapper_id);
                    }
                });
                if (!self.isShape()) {
                    $('.modal-title span').text("Connector Details");
                    $("#createMapping" + self.encodeId).hide();
                    $("#targetMapping" + self.encodeId).hide();
                    $('#valueElementMapping' + self.encodeId).hide();
                    $("#updateMapping" + self.encodeId).hide();
                    $("#type" + self.encodeId).hide();
                }
                if (self.usedColors) {
                    $.each(self.usedColors, function (it, value) {
                        if (!$('div[rel=\'' + value + '\']').length) {
                            var wrapper_id = 'w' + it;
                            if (value === self.defaultColor) {
                                $('<div class="dwrapper" type="button" id="' + wrapper_id + '" rel="' + value + '" active="active"> </div>').appendTo('.colors-wrapper');
                            } else {
                                $('<div class="dwrapper" type="button" id="' + wrapper_id + '" rel="' + value + '"> </div>').appendTo('.colors-wrapper');
                            }
                            $('<div class="dcolor" style="background-color:' + value + '"> </div>').appendTo('#' + wrapper_id);
                        }
                    });
                }
                $('.colors-wrapper').on('click', '.dwrapper', function () {
                    event.stopPropagation();
                    var colorBoxes = $('.dwrapper');
                    for (var i = 0; i < colorBoxes.length; ++i) {
                        if (colorBoxes[i] !== this) {
                            colorBoxes[i].removeAttribute("active");
                        }
                    }
                    if (!this.active) {
                        this.setAttribute("active", "active");
                    }
                    var color = $(this).children()[0].style.backgroundColor;
                    color = color.replace(/ /g, '');
                });
				$('#clickEditValues' + self.encodeId).on('click', function () {
				        var model = self.model;var addOptions = {};
					    var mappedElementModel = self.mappedElementModel;
					    var type = 'Activity';
						if(mappedElementModel && self.mappedElementModel.get('type') === 'vdml_ValuePropositionComponent') {
							if(self.mappedElementModel.get('componentOwner').get('isValueFormula')) {
								type = 'valueFormula';
							}
							else if(DataManager.getDataManager().getRepositoryId(self.mappedElementModel.get('id')) === window.plansKey){
								type = 'planValues';
							}
							else {
								type = 'valueProposition';
							}
						}
						var plan = DataManager.getDataManager().get('currentPlan');
                        function getAggregatedColl(isPlan,bm,callback){
                            if(isPlan){
                                plan.getValuesThatCanBeRefferedTo(function(values){
                                    callback(new Backbone.Collection(values));
                                });
                            } else {
                                var aggregatedColl = new Backbone.Collection;
                                var allValues = [];
                                bm.getValues(allValues,true);
                                aggregatedColl.add(allValues);
                                bm.fillPlanValues(aggregatedColl);
                                callback(aggregatedColl);
                            }
                        };
						
		    				//self.aggregatedColl = new Backbone.Collection(values);
						
							self.currentAlternativeModel = DataManager.getDataManager().getAlternativeSync(DataManager.getDataManager().get('viewAlternative'));
							var scenario = self.currentAlternativeModel.getMainScenario(mappedElementModel.getNestedParent());
							if(!scenario){
                    			var altComp = self.currentAlternativeModel.getModelLinkedSync(mappedElementModel);
                    			if(altComp){
                    				scenario = self.currentAlternativeModel.getMainScenario(altComp.getNestedParent());
                    				self.mappedElementModel = altComp;
								}
							}
							var alt = scenario.getAlternative();
							if(!alt) {
								alt = self.currentAlternativeModel;
							}
                            var enableAgg = true;
                            if(window.checkContextForRevision()){
                                enableAgg = false;
                            }
                            function loadValueDetailsPage(selectedBM,addOptions){
                                addOptions.bm = selectedBM;
                                var planType = false;
                                if (DataManager.getDataManager().getRepositoryId(self.mappedElementModel.id) == window.plansKey) {
                                    planType = true;
                                }
                                getAggregatedColl(planType,selectedBM,function(aggregatedColl){
                                    addOptions.aggregatedColl = aggregatedColl;
                                    window.getAndCreateModalDialog(self.mappedElementModel, window.utils.htmlEscape(self.mappedElementModel.get('id')) + "_edit", BusinessModelMixin, self.mappedElementModel, "ValueDetails",self.saveValue, addOptions);
                                });
                            }
                            var pack = self.mappedElementModel.getNestedParent();
                            var actModel = null;
							if(type == "Activity"){
								actModel = self.mappedElementModel.get('valueAddOwner').get('containedPortOwner');
								var actObj = [{originalId:actModel.get('id'),modalId: htmlEscape(actModel.get('id')),name:actModel.get('name')}];
								addOptions = { 'type': 'createWhatValues', 'aggTo': !enableAgg, 'activities': actObj, 'aggregationView': !enableAgg,'mainScenario': scenario, view: self, 'valuesColl': [], 'bm': null, 'currentAlternativeModel': alt, 'disableDelete': true};
                                //loadValueDetailsPage(null,addOptions);
							} else {
                                var overAllSatisfaction = self.mappedElementModel.get('componentOwner').get('overAllSatisfaction');
                                addOptions = {'type': type, 'disableAggr':!enableAgg, 'aggregationView':!enableAgg, 'aggTo': !enableAgg, 'mainScenario': scenario, 'overAllSatisfaction': overAllSatisfaction, 'currentAlternativeModel':alt,'disableDelete':true,'collaboration': collaboration};
                                var vp = self.mappedElementModel.getParent();
                                var role = vp.get("provider");
                                if(!role){ //plan value
                                    addOptions.type='planValues'; addOptions.planValue= true;
                                    loadValueDetailsPage(null,addOptions);
                                    return;
                                }
                                var collaboration = role.getParent();
                                //var pack = collaboration.getNestedParent();
                                var vf = vp.get('isValueFormula');
							}
                            var bms = pack.get('businessModel');
                            var vpFoundList = [];
                            for(var i=0; i<bms.length; i++){
                                var vpFound = null;
                                if(type == "Activity"){
                                    var bmact = bms.at(i).get('bmActivity').findWhere({'activity':actModel});
                                    if(bmact){
                                        vpFound = bmact.get('activityValue').findWhere({ 'id': self.mappedElementModel.get('id') });
                                    }
                                }else {
                                    if(vf){
                                        vpFound = bms.at(i).get('bmValueFormula').findWhere({'valueFormula':vp});
                                    } else {
                                        vpFound = bms.at(i).get('bmValueProposition').findWhere({'valueProposition':vp});
                                    }
                                }
                                
                                if(vpFound){
                                    vpFoundList.push(bms.at(i));
                                }
                            }
                            if(vpFoundList.length > 1){
                                var message = "The value "+self.mappedElementModel.get('name')+" is used in folowing Business Models ?<br>Navigate:<br>";
                                for(var i=0; i<vpFoundList.length; i++){
                                    message=message.concat("<input type='radio' checked name='location' value='"+vpFoundList[i].get('id')+"'>&nbsp;"+vpFoundList[i].get('name')+"<br>");
                                }
                                bootbox.dialog({
                                    title: "<h4 style=\"font-family:Roboto; display: inline-block;\"><b>Business Models</b></h4>",
                                    message: message,
                                    className: "bootboxModal",
                                    buttons: {
                                        success: {
                                            label: 'Complete',
                                            className: "btn btn-complete complete-btn",
                                            callback: function () {
                                                var bmId = $('input[name="location"]:checked').val();
                                                if(type != "Activity" && self.mappedElementModel.id.startsWith(self.currentAlternativeModel.id)){
                                                    addOptions = {'type': type, 'mainScenario': scenario, 'aggregationView': !enableAgg, 'overAllSatisfaction': overAllSatisfaction, 'currentAlternativeModel': alt,'disableDelete': true, 'collaboration': collaboration};
                                                }
                                                loadValueDetailsPage(bms.findWhere({'id':bmId}),addOptions);
                                            }
                                        }
                                    }
                                });
                            } else if(vpFoundList.length == 0){
                                bootbox.alert("Value is not linked to any Business Model");
                                return;
                            } else {
                                if(type != "Activity" && self.mappedElementModel.id.startsWith(self.currentAlternativeModel.id)){
                                    addOptions = {'type': type, 'mainScenario': scenario, 'aggregationView': !enableAgg, 'overAllSatisfaction': overAllSatisfaction, 'currentAlternativeModel': alt,'disableDelete': true, 'collaboration': collaboration};
                                }
                                loadValueDetailsPage(vpFoundList[0],addOptions);
                            }							
                });
                $('#showValueMappedInfo' + self.encodeId).on('click',function () {
                    var id = window.utils.htmlEscape(window.guidGenerator());
                    var currentAltId = DataManager.getDataManager().get('viewAlternative');
                    var docTypeObj = Backbone.Relational.store.getObjectByName("transformation.Alternative");
                    var currentAlt = docTypeObj.find({
                        id: currentAltId
                    });
                    var valModel = window.utils.getElementModel(self.usedValue, ['vdml.ValueCategory', 'vdml.ValueDefinition']);
                    if (!valModel) {
                        bootbox.alert("No such Element");
                        return;
                    }
                    var scenario = currentAlt.getMainScenario(valModel.getNestedParent());
                    var options = {};
                    options.mappedModal = valModel;
                    options.context = scenario;
                    options.width = '800px';
                    window.getAndCreateModalDialog(self.model, id, EcoMapDiagramMixin, self.model, "TargetDetails", function(refresh) {}, options);
                });

                $('#addValueDefinition' + self.encodeId).on('click', function () {
                    var id = htmlEscape(window.guidGenerator());
                    var options = {
                        valueLibs: self.valueLibs, callback: function (valId, phases) {
                            if (!valId) {
                                return;
                            }
                            self.usedValue = valId[0];
                            
                           // configObj.businessObject.mcid = self.usedValue;
                            var valueDef = Backbone.Relational.store.getObjectByName('vdml.ValueDefinition').find({ id: valId[0] });
                            if (valueDef) {
                                self.showParentValueId = valueDef.getNestedParent().get('id');
                                self.mappedValueDef(valueDef.get('name'));
                                $('#addValueDefinition' + self.encodeId).hide();
                                $('#showValueMappedInfo' + self.encodeId).show();
                                $('#editValueDefinition' + self.encodeId).show();
                                $('#deleteValueDef' + self.encodeId).show();
                                if(self.name() == null){
                                    self.name(valueDef.get('name'));
                                    self.description(valueDef.get('name'));
                                    $('#alternativeName' + self.encodeId).val(valueDef.get('name'));
                                    $('#alternativeDescription' + self.encodeId).val(valueDef.get('name'));
                                }
                            }
                        }
                    };
                    options.showPhases = true;
                    options.hideCheck = true;
                    if(self.usedValue){
                        options.showValueElementId = self.usedValue;
                    }
                    window.getAndCreateModalDialog(self, id, ValueDeliveryModelMixin, self.model, "ValueExplorerJson", null, options);
                });

                $('#editValueDefinition' + self.encodeId).on('click', function () {
                    var id = htmlEscape(window.guidGenerator());
                    var options = {
                        valueLibs: self.valueLibs, callback: function (valId, phases) {
                            if (!valId) {
                                return;
                            }
                            self.usedValue = valId[0];
                            
                           // configObj.businessObject.mcid = self.usedValue;
                            var valueDef = Backbone.Relational.store.getObjectByName('vdml.ValueDefinition').find({ id: valId[0] });
                            if (valueDef) {
                                self.showParentValueId = valueDef.getNestedParent().get('id');
                                self.mappedValueDef(valueDef.get('name'));
                                $('#addValueDefinition' + self.encodeId).hide();
                                $('#showValueMappedInfo' + self.encodeId).show();
                                if(self.name() == null){
                                    self.name(valueDef.get('name'));
                                    self.description(valueDef.get('name'));
                                    $('#alternativeName' + self.encodeId).val(valueDef.get('name'));
                                    $('#alternativeDescription' + self.encodeId).val(valueDef.get('name'))
                                }
                            }
                        }
                    };
                    options.showPhases = true;
                    options.hideCheck = true;
                    if(self.usedValue){
                        options.showValueElementId = self.usedValue;
                    }
                    window.getAndCreateModalDialog(self, id, ValueDeliveryModelMixin, self.model, "ValueExplorerJson", null, options);
                });
				
                $('#showMappedInfo' + self.encodeId).on('click', function () {
                    event.stopPropagation();
                    var mappedElement;
                    var shape = configObj.addOptions.businessObject;
                    var mappingElementType = shape.$type;
                    var mappingElementId = shape.mid;
                    var packageId = shape.mpid;
                    if (mappingElementId) {
                        self.model.getMappedElement(mappingElementId, packageId, mappingElementType, null, function (mappedElement) {
                            if (!mappedElement) {
                                bootbox.alert("No data found");
                                return;
							}
							if (mappedElement.get('type') === 'vdml_CapabilityDefinition' || mappedElement.get('type') === 'vdml_CapabilityCategory') {
								var id = htmlEscape(window.guidGenerator());
								var currentPlan = DataManager.getDataManager().get('currentPlan');
								currentPlan.loadCapabilityLibraries(function (capabilityLibraries) {
									var options = {
										capabilityLibs: capabilityLibraries, targetDetailsMode: true, callback: function (capId, capName, capDesc, capParentId) {
											if (!capId) {
												return;
											}
										}
									};
									options.showCapabilityId = mappingElementId;
									window.getAndCreateModalDialog(self, id, ValueDeliveryModelMixin, self.model, "CapabilityExplorerJson", null, options);
								}); 
                            } /*else if (mappedElement.get('type') === 'smm_BusinessIndicatorCategory' || mappedElement.get('type') === 'smm_BusinessIndicatorDefinition'){
							    var id = htmlEscape(window.guidGenerator());
								var currentPlan = DataManager.getDataManager().get('currentPlan');
								currentPlan.loadMeasureLibraries(function (capabilityLibraries) {
									var options = {
										measurementLibs: capabilityLibraries, targetDetailsMode: true, callback: function (capId, capName, capDesc, capParentId) {
											if (!capId) {
												return;
											}
										}
									};
									options.showMeasurementId = mappingElementId;
                                    window.getAndCreateModalDialog(self, id, ValueDeliveryModelMixin, self.model, "BusinessIndicatorExplorerJson", null, options);
								}); 
							}*/
							else {
								var currentAltId = DataManager.getDataManager().get('viewAlternative');
								var docTypeObj = Backbone.Relational.store.getObjectByName("transformation.Alternative");
								var currentAlt = docTypeObj.find({ id: currentAltId });
								var scenario = currentAlt.getMainScenario(self.mappedElementModel.getNestedParent());
								var id = window.utils.htmlEscape(window.guidGenerator());
								var options = {};
								options.context = scenario;
								options.mappedModal = mappedElement;
								options.width = '800px';
								window.getAndCreateModalDialog(self.model, id, EcoMapDiagramMixin, self.model, "TargetDetails", function (refresh) {
									if (refresh && configObj.parentView.chartViewInstance && configObj.parentView.chartViewInstance.initialize) {
										configObj.parentView.chartViewInstance.initialize(configObj.parentView.model, true, configObj.parentView, self.getValueList(configObj.parentView.model));
									}
								}, options);
							}
                        });
                    }
                });
                $('#deleteValueDef' + self.encodeId).on('click', function () {
                    $("#editValueDefinition" + self.encodeId).hide();
                    $("#showValueMappedInfo" + self.encodeId).hide();
                    $("#addValueDefinition" + self.encodeId).show();
                    self.mappedValueDef("");
                    $('#deleteValueDef' + self.encodeId).hide();
                    self.usedValue = null;
                });
				
				$('#capMappedTo').click( function (element) {
                    event.stopPropagation();var capabilityId;
                    var id = htmlEscape(window.guidGenerator());
			        var currentPlan = DataManager.getDataManager().get('currentPlan');
			        currentPlan.loadCapabilityLibraries(function (capabilityLibraries) {
				    var options = {capabilityLibs: capabilityLibraries, callback: function (capId, capName, capDesc, capParentId) {
										if (!capId) {
										    return;
										}
										self.showCapabilityId = capId;
										self.showParentCapabilityId = capParentId;
										self.showCapabilityName(capName);
										if(capId && $('#alternativeName'+self.encodeId).val() == ""){
											$('#alternativeName'+self.encodeId).val(capName);
											$('#alternativeDescription'+self.encodeId).val(capName);
							            }
										$('#capabilityName').html(capName);
										$('#capMappedTo').hide();
										$('#showCapMappedEdit').show();
										$('#removeCapMapping').show();
								}
							};
						    window.getAndCreateModalDialog(self, id, ValueDeliveryModelMixin, self.model, "CapabilityExplorerJson", null, options);
			        }); 
                });
                $('#showCapMappedInfo').on('click', function () {
                    event.stopPropagation();
                    var shape = configObj.businessObject;var capElementId;
					if(!shape.mcid && configObj.businessObject.mid){
						var capShape = Backbone.Relational.store.getObjectByName('vdml.Activity').find({'id':configObj.businessObject.mid});
						if(!capShape){
							capShape = Backbone.Relational.store.getObjectByName('vdml.Competence').find({'id':configObj.businessObject.mid});
						}
						if(capShape){
						   capElementId = capShape.get('capabilityRequirement') ? capShape.get('capabilityRequirement').id: capShape.get('capability') ? capShape.get('capability').id : '';
						}
					}
					var capMappingElementId = shape.mcid ? shape.mcid : capElementId;
                    if (capMappingElementId) {
                    	var id = htmlEscape(window.guidGenerator());
			            var currentPlan = DataManager.getDataManager().get('currentPlan');
			            currentPlan.loadCapabilityLibraries(function (capabilityLibraries) {
							var options = {capabilityLibs: capabilityLibraries, targetDetailsMode : true, callback: function (capId, capName, capDesc, capParentId) {
										if (!capId) {
											return;
										}
								}
							};
							if(capMappingElementId){
								options.showCapabilityId = capMappingElementId;
							}
							window.getAndCreateModalDialog(self, id, ValueDeliveryModelMixin, self.model, "CapabilityExplorerJson", null, options);
			            }); 
                    }
                    else{
                    	bootbox.alert("No data found");
                        return;
                    }
                });
				$('#showCapMappedEdit').on('click', function () {
                    event.stopPropagation();var capMappingElementId;var capModel;
                    var shape = configObj.businessObject;var capElement;
					capMappingElementId = shape.mcid ? shape.mcid : self.showCapabilityId;
					if (capMappingElementId) {
                    	var id = htmlEscape(window.guidGenerator());
			            var currentPlan = DataManager.getDataManager().get('currentPlan');
			            currentPlan.loadCapabilityLibraries(function (capabilityLibraries) {
							var options = {capabilityLibs: capabilityLibraries, targetDetailsMode : false, callback: function (capId, capName, capDesc, capParentId) {
										if (!capId) {
											return;
										}
										$('#capabilityName').html(capName);
										self.showCapabilityId = capId;
										self.showCapabilityName(capName);
										self.showParentCapabilityId = capParentId;

								}
							};
							if(capMappingElementId){
								options.showCapabilityId = capMappingElementId;
							}
							window.getAndCreateModalDialog(self, id, ValueDeliveryModelMixin, self.model, "CapabilityExplorerJson", null, options);
			            }); 
                    }
                });
                $('#removeCapMapping').on('click', function () {
                    event.stopPropagation();
                    var shape = configObj.businessObject;
					var item = Backbone.Relational.store.getObjectByName('vdml.Activity').find({'id':configObj.businessObject.mid});
					if(!item){
						item = Backbone.Relational.store.getObjectByName('vdml.Competence').find({'id':configObj.businessObject.mid});
					}
					if(item == null && (!shape.mid && (!shape.mid || shape.mcid))){
					   $('#capabilityName').html('');
					   $('#removeCapMapping').hide();
					   $('#capMappedTo').show();
					   $('#showCapMappedEdit').hide();
					   self.showCapabilityId = null;
					   self.showCapabilityName(null);
					   self.showParentCapabilityId = null;
					}
				});
                if(DataManager.getDataManager().get('readMode')){
                    self.allowMapping(false);
                }
			}

			this.getCapabilityElementModel = function (id) {
				var capModel = Backbone.Relational.store.getObjectByName('vdml.CapabilityCategory').find({ id: id });
				if (!capModel) {
					capModel = Backbone.Relational.store.getObjectByName('vdml.CapabilityDefinition').find({ id: id });
				}
				return capModel;
		    }
			this.checkInitialSettings = function(businessObj,changedAttr,name){
				var initialDesc = false;
				if(!businessObj && changedAttr == name){
					initialDesc = true;
				}
				return initialDesc;
			}
			this.saveValue = function(view,modalId,type,del){
                window.utils.startSpinner('values','Saving Value ...');
                window.setTimeout(function(){
                    var parent = view.model.getParent();
        
                        var previousWorkspace = DataManager.getDataManager().getWorkspace();
                        DataManager.getDataManager().getWorkspaceDataWithId(view.currentAlternativeModel.id, function (wsData) {
                            var altWorkspace = wsData.get('workspace'); 
                            self.currentPlan = DataManager.getDataManager().get('currentPlan');
                            DataManager.getDataManager().set('currentPlan', view.currentAlternativeModel.getNestedParent());
                            DataManager.getDataManager().setWorkspace(altWorkspace,function(){
                                function refreshModalWindow(changeInValue) {
                                    if (self.nonPrimedValue){
                                        changeInValue = true;
                                    }
                                    DataManager.getDataManager().setWorkspace(previousWorkspace, function () {
                                        if (changeInValue) {
                                            self.changeInValue = true;
                                            if(self.mappedElementModel){
                                                var name = self.mappedElementModel.get('name');
                                                self.mappedTo(name);
                                                if(self.syncName()){
                                                    self.name(name);
                                                }
                                            }
                                        } else if(view.changeInSigValue){
                                            self.changeInValue = true;
                                        }
                                        window.cleanDialogModel(modalId, view);
                                        window.utils.stopSpinner('values');
                                    });
                                }
                                if (parent.get('type') === 'vdml_ValueProposition') {
                                    view.saveData(parent.getNestedParent(), parent,  refreshModalWindow, null);
                                } else {
                                    var activity = parent.getParent();
                                    view.saveWhatValuesData(activity, [{ modalId: window.utils.htmlEscape(activity.id), originalId: activity.id }], [], function (actValue, changeInValue) {
                                        refreshModalWindow(changeInValue);
                                    }, null);
                                    }
                                    if(configObj.parentView.chartViewInstance){
                                    configObj.parentView.chartViewInstance.initialize(configObj.parentView.model, true, configObj.parentView, self.getValueList(configObj.parentView.model));
                                    }
                            });
                        });
                },100);        
	    	}
            this.saveShapeDetails = function () {
                var shapeDesc = self.description() ? self.description().trim() : null;
                if (configObj.businessObject.$type == "vdml:BusinessItem" || configObj.businessObject.$type == "vdml:CausalRelationship" || configObj.businessObject.$type == "vdml:DeliverableFlow") {
                    //configObj.element.curveType = self.radioSelectedOptionValue();
					configObj.businessObject.curveType = self.radioSelectedOptionValue();
                }
				var shapename = self.name() ? self.name().trim() : null;
                if (configObj.businessObject.description != shapeDesc && configObj.businessObject.$type != "vdml:BusinessItem" || (configObj.businessObject.$type != "vdml:ValueStream" && configObj.businessObject.description != shapeDesc)) {
                    var initialDesc = self.checkInitialSettings(configObj.businessObject.description,shapeDesc,shapename);
					configObj.businessObject.description = shapeDesc;
					if(!initialDesc) {
						configObj.parentView.enableComplete(true);
					}
                    if (configObj.businessObject.$type.indexOf('Capability') > -1) {
                        self.eventBus.fire('elements.changed', {
                            elements: [configObj.addOptions.element]
                        });
                    }
                }
                if (self.showCapabilityId != configObj.businessObject.mcid && configObj.businessObject.$type != "vdml:Value") {
					if(configObj.parentView.tempCapbilityIdList){
						var capId = self.showCapabilityId?self.showCapabilityId:configObj.businessObject.mcid;
						configObj.parentView.tempCapbilityIdList.push(capId);
					}
					configObj.businessObject.mcid = self.showCapabilityId;
					configObj.businessObject.mcpid = self.showParentCapabilityId;				
					configObj.parentView.enableComplete(true);
					//if(!configObj.businessObject.mid && self.showCapabilityId != null){
						configObj.businessObject.isCapElement = self.showCapabilityId;
					//}
                }
                if (self.usedValue != configObj.businessObject.mcid && configObj.businessObject.$type === "vdml:Value") {
                    if (configObj.parentView.tempValueDefList) {
                        var valId = self.usedValue ? self.usedValue : configObj.businessObject.mcid;
                        configObj.parentView.tempValueDefList.push(valId);
                    }
                    configObj.businessObject.mcid = self.usedValue;
                    configObj.businessObject.mcpid = self.showParentValueId;
                    configObj.parentView.enableComplete(true);
                    //if (!configObj.businessObject.mid && self.usedValue != null) {
                        configObj.businessObject.isValueDef = self.usedValue;
                    //}
                }
				if((!self.name() && !self.description()) && self.showCapabilityId){
				     //configObj.businessObject.name = self.showCapabilityName();
					 configObj.businessObject.description = self.showCapabilityName();
					 shapename = self.showCapabilityName();
				}

				if (!configObj.businessObject.get('syncName') && self.syncName()) {
					configObj.businessObject.set('syncName', true);
					configObj.parentView.enableComplete(true);
				} else if (configObj.businessObject.get('syncName') && !self.syncName()){
					configObj.businessObject.set('syncName', undefined);
					configObj.parentView.enableComplete(true);
				}

                if(self.syncName()){
					configObj.parentView.saveShapeDetailsData(self);
				}

                if (configObj.businessObject.name != shapename && configObj.businessObject.$type != "vdml:BusinessItem") {
                    configObj.businessObject.name = shapename;
                    configObj.parentView.loadTable();
					configObj.parentView.enableComplete(true);
					/*if(configObj.addOptions.element && configObj.addOptions.element.label){
						configObj.addOptions.element.businessObject.ignoreSync = true
						self.eventBus.fire('elements.changed', {
							elements: [configObj.addOptions.element.label]
						});
						
					} else if(configObj.addOptions.element){
						configObj.addOptions.element.businessObject.ignoreSync = true
						self.eventBus.fire('elements.changed', {
							elements: [configObj.addOptions.element]
						});
					}*/
					if(configObj.addOptions.element){
						configObj.addOptions.element.businessObject.ignoreSync = true;
						if(self.modeling.updateLabel){
						    self.modeling.updateLabel(configObj.addOptions.element, shapename);
						}else{
						    self.eventBus.fire('elements.changed', {
								elements: [configObj.addOptions.element]
							});
						}
					}
                }
                if (self.changeInValue && self.modeling.updateLabel && configObj.businessObject.mid && configObj.businessObject.$type === "vdml:Value") {//refresh values
                    if(configObj.addOptions.element){
						configObj.addOptions.element.businessObject.ignoreSync = true;
						if(self.modeling.updateLabel){
                            var aggComp = window.utils.getElementModel(configObj.businessObject.mid,['vdml.ValuePropositionComponent','vdml.ValueAdd']);
                            if(aggComp && (aggComp.get("aggregatedFrom").length > 0 || aggComp.get("aggregatedTo").length > 0)){
                                var obj = self.elementRegistry._elements;
                                for (var key in obj) {
                                    if (obj.hasOwnProperty(key)) {
                                        var valShape = obj[key].element;
                                        if(valShape.businessObject.mid && valShape.businessObject.$type === "vdml:Value"){
                                            window.vdmModelView.customTrigger = true;
                                            //self.modeling.updateLabel(valShape, valShape.businessObject.name);
                                            var gfx = self.elementRegistry.getGraphics(valShape);
                                            if(gfx.childNodes[0]){
                                                var txt = gfx.childNodes[0].childNodes[2];
                                                gfx.childNodes[0].removeChild(txt);
                                                window.vdmModelView.modeler.get('vdmlRenderer').handlers.label(gfx.childNodes[0],valShape);
                                            }
                                        }
                                    }
                                }
                            } else {
                                window.vdmModelView.customTrigger = true;
                                self.modeling.updateLabel(configObj.addOptions.element, shapename);
                            }
						}
					}
                }
                if (configObj.parentView.model.get('type') === "vdml_ValueStreamMapDiagram") {
                    if (configObj.businessObject.isProcessStart != self.isStartActivity() && configObj.businessObject.$type == "vdml:Activity"){
						var initialDesc = self.checkInitialSettings(configObj.businessObject.isProcessStart,self.isStartActivity(),false);
                        configObj.businessObject.isProcessStart = self.isStartActivity();
						if(!initialDesc){
							configObj.parentView.enableComplete(true);
						}
                    }
                    if (configObj.businessObject.isProcessEnd != self.isStopActivity() && configObj.businessObject.$type == "vdml:Activity") {
						var initialDesc = self.checkInitialSettings(configObj.businessObject.isProcessEnd,self.isStopActivity(),false);
                        configObj.businessObject.isProcessEnd = self.isStopActivity();
                        if(!initialDesc){
							configObj.parentView.enableComplete(true);
						}
                    }
                    if (self.isOutput() == "false" && $("#isOutput" + self.encodeId)[0].value == "true"&& configObj.businessObject.isOutput == "true" && configObj.businessObject.$type == "vdml:Competency") {
                        //configObj.businessObject.isOutput = self.isOutput();
						configObj.businessObject.isOutput  = "false";
                        configObj.parentView.enableComplete(true);
                    }
					if(self.isOutput() != configObj.businessObject.isOutput && $("#isOutput" + self.encodeId)[0].value == "true" && configObj.businessObject.$type == "vdml:Competency"){
					   configObj.businessObject.isOutput = "true";
					   //configObj.businessObject.isInput  = "true";
                       configObj.parentView.enableComplete(true);
					}
                }
				if(configObj.parentView.model.get('type') === "vdml_CapabilityLibraryDiagram" || configObj.parentView.model.get('type') === "vdml_ValueStreamMapDiagram"){
                	self.saveDefinition();
                }
					
                if ($('.dwrapper[active="active"] .dcolor').length) {
                    var color = $('.dwrapper[active="active"]').attr('rel');
                        if (self.isShape() && configObj.businessObject && configObj.element.di.fill != color) {
                            configObj.parentView.enableComplete(true);
                            self.modeling.setColor([configObj.addOptions.element], {
                                fill: color
                            });
                        } else if (!self.isShape() && configObj.businessObject && configObj.element.di.stroke != color) {
						    configObj.parentView.enableComplete(true);
							configObj.parentView.previouslyUsedColor = color;
                            self.modeling.setColor([configObj.addOptions.element], {
                                stroke: color
							});
                        }

                }
				//configObj.parentView.loadTable()
                self.cleanModal();
            }
			function trim (str) {
				return str.replace(/^\s+|\s+$/gm,'');
			}
			this.cleanModal = function () {
                if (self.wizard) {
                    window.cleanViewModel(self.wizard, $('#wizardModalContent'));
                }
                window.cleanDialogModel(self.encodeId, self);
            }
            this.cleanUp = function () {
                configObj = null;
                self.wizard = null;
                $(document).off('colorAdd');
				if ($("#summernoteDefinition"+self.encodeId).next('.note-editor').length !== 0) {
					$("#summernoteDefinition").summernote('destroy');
					$("#summernotecss").attr("href", "");
				}
            }
            this.labels = kb.viewModel(DataManager.getDataManager().get('localeManager'), [
                'name'
                , 'description'
                , 'Close'
                , 'Delete'
                , 'Complete'
                , 'startActivity'
                , 'endActivity'
                , 'inputDataStore'
                , 'outputDataStore'
                , 'Curve'
                , 'Elbow'
                , 'StraightLine'
                ,'ConnectorStyle'
                ,'shapeDetails'
                ,'ValueDefinition'
                ,'CapabilityElement'
                ,'targetElement'
                ,'Documentations'
                ,'addAnother'
                ,'Color'
                ,'type'
                ,'addMapping'
                ,'createMapping'
                ,'deleteMapping'
                ,'addValue'
                ,'viewValues'
                ,'SyncTargetElement'
                ,'ValueDetails'
            ]);
        }
        
        static getInstance(model, configObj, callback){
			var view = new ShapePropertiesViewModel(model, configObj, callback);
			return view;
		};
    }
	path.ShapePropertiesViewModel = ShapePropertiesViewModel;
