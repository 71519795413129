import * as $ from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as bootbox from '../../../../libs/bootbox/bootbox'
//define(["require","underscore", "backbone", "jquery","knockout","knockback","bootbox"],
//	function(require,_, Backbone,$,ko,kb,bootbox) {
	    var Router = Backbone.Router.extend({
	        routes: {
                "views/:currentLegalEntity/:wsId/:planId/:documentVersion/:pac/:pactype/:version/:id/:type(/:project)(/:view)": "openView",
                "adminviews/:modeltype(/:view)(/:context1)(/:context2)(/:context3)(/:context4)(/:context5)(/:context6)(/:context7)(/:context8)(/:context9)(/:context10)(/:context11)":"openAdminView",
	            "search/:currentLegalEntity/:wsId/:planId:/:documentVersion/:searchValue(/:project)":"openExplorer",
	            "*actions(/:currentLegalEntity)(/:wsId)(/:planId)(/:documentVersion)(/:project)": "defaultRoute" // Backbone will try match the route above first
	        },
			refreshProject: function(router,wsId,callback){
				window.utils.startSpinner('planReload', 'Reloading Plan...');
				window.showSettings(function(){
					if(!router.dataManager.get('currentLegalEntity')){
						router.dataManager.set('currentLegalEntity',{'currentLegalEntity':currentLegalEntity,'entityId':currentLegalEntity});
					}
					var query = {tags:[],type: null,name : null,documentOwner : null};
					if(wsId && wsId != "null"){
						var defaultWorkspaceId = router.dataManager.get('currentWorkspaceId');
						window.utils.getOrFetchWsGroup(wsId,function(){
							router.dataManager.set('currentWorkspaceId',wsId);
							if(defaultWorkspaceId != wsId){
								router.dataManager.applyWorkspaceById(wsId,function(){
									router.dataManager.getPlansFromBackend(query,callback);
								});
							} else {
								router.dataManager.getPlansFromBackend(query,callback);
							}
						});
					} else {
						router.dataManager.getPlansFromBackend(query,callback);
					}
				});
			},
			setProject: function(project,callback){
				var router = this;
				//$('.navbar-collapse').removeClass('in');
				if(project && project !== ""){
					var currentWorkspace = router.dataManager.get('currentWorkspace').get('id');
					if(currentWorkspace !== project){
						var workspaceStore = router.dataManager.get("workspaceStore");
						window.getDefaultRepository(workspaceStore,callback,project,project);
					}else{
						callback();
					}
				}else{
					callback();
				}
				$("#newModal").find(".close").click();
				var modalNew = document.getElementsByClassName("modal-backdrop")[0];
				if (modalNew) {
					modalNew.parentNode.removeChild(modalNew);
				}
			},	        
	        openView: function(currentLegalEntity,wsId,currentPlanId,documentVersion,beepPackageId,pacType,version,docId,docTypePackage,project,view){
	        	//var currentPhaseId;
	        	var currentVersion;
	        	var router = this;
	        	//router.dataManager.clearSaveInterval();
	        	router.dataManager.clearContextMenus();
	        	var re=/[\._]/g;
				docTypePackage = "appbo/" + docTypePackage.replace(re,"/");
				pacType = "appbo/" + pacType.replace(re,"/");
				function callback(){
		        	function activateRouterView(plan){
		        		if(plan){
		        			router.setCurrentPlan(plan,loadDocumentAndActivitateView);
		        			//setTimeout(loadDocumentAndActivitateView,500);
		        		}else{
		        			router.resetCurrentPlan();
		        			loadDocumentAndActivitateView();
		        		}
		        	}	        		
	        		if(currentPlanId){
						if(!router.dataManager.get("artifactsData")[wsId]){
							router.refreshProject(router,wsId,function(){
								router.dataManager.getPlanInstance(currentPlanId, currentVersion ? currentVersion : router.dataManager.get('currentPlanVersion'), activateRouterView,documentVersion);
							});
						} else {
							router.dataManager.getPlanInstance(currentPlanId, currentVersion ? currentVersion : router.dataManager.get('currentPlanVersion'), activateRouterView,documentVersion);
						}
	        		}else{
	        			loadDocumentAndActivitateView();	
	        		}
	        		
	        		function loadDocumentAndActivitateView(){
						router.dataManager.fetchDocumentFromPackage(beepPackageId,pacType,parseInt(version),docId,docTypePackage,router.dataManager.get('vdmStore'),{
							success:function(model){
								//router.dataManager.set('currentPlan',model);
								if(!router.dataManager.get('viewAlternative')){
									router.dataManager.set('viewAlternative',project);
								}
								if(!router.dataManager.get('currentPlan') && project.indexOf('-Common@') != -1){
									currentPlanId = window.plansKey + project.split('-Common@')[0].substring(1);
									router.dataManager.getPlanInstance(currentPlanId, currentVersion ? currentVersion : router.dataManager.get('currentPlanVersion'), activateRouterView,documentVersion);
								}else {
									router.activateView(model,view,"",project);
								}
							},
							error:function(error){
								console.log(error);
								bootbox.alert('Request is Denied. Navigate to Home Page?', function() { 
									window.utils.navigatePage(false);
								});
							}
						});
	        		}
				}
				function callSetProject(planId,phaseId,version){
					currentPlanId = planId;
					//currentPhaseId = phaseId;
					currentVersion = version;
					router.setProject(project,callback);
				}	
				//setTimeout(callSetProject,100);
				if(project === window.plansKey){
					callSetProject(docId); 
				}else{
					function getRDFModelAndSetProject(){
						var plansRdfModel = router.dataManager.getRDFModel(window.plansKey);
						if(plansRdfModel){
							//plansRdfModel.getPlanPhaseOfAlternative(project,callSetProject);
							callSetProject(currentPlanId);
							return true;
						}
						return false;
					}
					if(!router.dataManager.get('readMode') && !window.utils.checkIsLinked(null,true) && window.vdmModelView && window.vdmModelView.hasUnsavedData && window.vdmModelView.hasUnsavedData() && window.vdmModelView.saveDataBeforeClosing) {
						bootbox.confirm(router.dataManager.get('localeManager').get('SaveDataBeforeClosing'), function(result) {
							if(result){
								window.vdmModelView.saveDataBeforeClosing(function(){
									if(!getRDFModelAndSetProject()){
										setTimeout(getRDFModelAndSetProject,1000);
									}
								});
							}else {
								if(!getRDFModelAndSetProject()){
									setTimeout(getRDFModelAndSetProject,1000);
								}
							}
						});
					}	
					else if(!getRDFModelAndSetProject()){
						setTimeout(getRDFModelAndSetProject,1000);
					}
				}				
            },
            openAdminView: function (modeltype, view, context1, context2, context3, context4, context5, context6, context7, context8, context9, context10, context11 ) {
                var router = this;
				$('.navbar-collapse').removeClass('in');
				import('../../../../app/DynamicClass').then(({ default: DynamicClass }) => {
					var modelType = DynamicClass.getView(modeltype);
					var options = {};
                    if (context1) {
                        options.context1 = context1;
                    }
                    if (context2) {
                        options.context2 = context2;
                    }
                    if (context3) {
                        options.context3 = context3;
                    }
                    if (context4) {
                        options.context4 = context4;
                    }
					if (context5) {
                        options.context5 = context5;
                    }
					if (context6) {
                        options.context6 = context6;
                    }
					if (context7) {
                        options.context7 = context7;
                    }
					if (context8) {
                        options.context8 = context8;
                    }
					if (context9) {
                        options.context9 = context9;
                    }
					if (context10) {
                        options.context10 = context10;
                    }
                    if (context11) {
                        options.context11 = context11;
                    }
					if(router.dataManager.get('vmpServerService')){
						if(Object.keys(router.dataManager.get("artifactsData")).length === 0){
							router.refreshProject(router,null,function(){
								router.activateView(modelType,view,null,null,options);
							});
						}else {
							router.activateView(modelType,view,null,null,options);
						}
					}else{
						router.initializeSnapshotService(function(){
							window.showSettings();
							router.activateView(modelType,view,null,null,options);
						})
					}
                    
				})
            },
			initializeSnapshotService(callback) {
				import('../filesystem/Workspace').then(({ Workspace }) => {
					Workspace.authenticateAzureWithChrome(callback);
				})
			},
	        openExplorer: function(currentLegalEntity,wsId,currentPlanId,documentVersion,searchValue,project){
	        	var router = this;
	        	//var currentPhaseId;
	        	var currentVersion;
				//router.dataManager.clearSaveInterval();
				if(!router.dataManager.get('currentLegalEntity') && currentLegalEntity){
					router.dataManager.set('currentLegalEntity',{'currentLegalEntity':currentLegalEntity,'entityId':currentLegalEntity});
				}
				if(wsId && wsId != "null"){
					router.dataManager.set('currentWorkspaceId',wsId);
				}
	        	function callback(){
	        		var currentWorkspace = router.dataManager.get('currentWorkspace');
		        	function activateRouterView(plan){
		        		if(plan){
		        			router.setCurrentPlan(plan,function(){
		        				router.activateView(plan,"",searchValue,project);
		        			});
		        		}else{
		        			router.resetCurrentPlan();
		        			router.activateView(currentWorkspace,"","",project);
		        		}
		        	}	        		
	        		if(currentPlanId){
	        		    router.dataManager.getPlanInstance(currentPlanId, currentVersion ? currentVersion : router.dataManager.get('currentPlanVersion'), activateRouterView,documentVersion);
	        		}else{
	        			router.activateView(currentWorkspace,"","",project);	
	        		}
				
	        	}
				function callSetProject(planId,phaseId,version){
					currentPlanId = planId;
					//currentPhaseId = phaseId;
					currentVersion = version;
					router.setProject(window.plansKey,callback);
				}				

				if(project === window.plansKey){
					callSetProject(); 
				}else{
					function getRDFModelAndSetProject(){
						var plansRdfModel = router.dataManager.getRDFModel(window.plansKey);
						if(plansRdfModel){
							//plansRdfModel.getPlanPhaseOfAlternative(project,callSetProject);
							callSetProject(currentPlanId);
							return true;
						}
						return false;
					}
					if(!router.dataManager.get('readMode') && !window.utils.checkIsLinked(null,true) && window.vdmModelView && window.vdmModelView.hasUnsavedData && window.vdmModelView.hasUnsavedData() && window.vdmModelView.saveDataBeforeClosing) {
						bootbox.confirm(router.dataManager.get('localeManager').get('SaveDataBeforeClosing'), function(result) {
							if(result){
								window.vdmModelView.saveDataBeforeClosing(function(){
									if(!getRDFModelAndSetProject()){
										setTimeout(getRDFModelAndSetProject,1000);
									}
								});
							}else {
								if(!getRDFModelAndSetProject()){
									setTimeout(getRDFModelAndSetProject,1000);
								}
							}
						});
					}
					else if(!getRDFModelAndSetProject()){
						setTimeout(getRDFModelAndSetProject,1000);
					}					
				}
				       		
	        },
	        defaultRoute:function(currentLegalEntity,wsId,currentPlanId,documentVersion,project){
	        	var router = this;
	        	//var currentPhaseId;
	        	var currentVersion;
	        	//router.dataManager.clearSaveInterval();
	        	function callback(){
	        		var currentWorkspace = router.dataManager.get('currentWorkspace');
		        	function activateRouterView(plan){
						if(plan){
							router.setCurrentPlan(plan,function(){
								router.activateView(plan,"","",project);
								//router.activateView(plan,"","",currentPhaseId);
							});
						}else{
							router.resetCurrentPlan();
							router.activateView(currentWorkspace,"","",project);
						}
		        	}	        		
	        		if(currentPlanId && currentPlanId != 'null' && wsId != 'null'){
						if(!router.dataManager.get("artifactsData")[wsId]){
							router.refreshProject(router,wsId,function(){
								router.dataManager.getPlanInstance(currentPlanId, currentVersion ? currentVersion : router.dataManager.get('currentPlanVersion'), activateRouterView,documentVersion);
							});
						} else {
							router.dataManager.getPlanInstance(currentPlanId, currentVersion ? currentVersion : router.dataManager.get('currentPlanVersion'), activateRouterView,documentVersion);
						}
	        		}else{
	        			router.dataManager.get('localeManager').reset();
	        			router.activateView(currentWorkspace,"","",project);	
	        		}					
	        	}
        		
				function callSetProject(planId,phaseId,version){
					currentPlanId = planId;
					//currentPhaseId = phaseId;
					currentVersion = version;
					router.setProject(window.plansKey,callback);
				}				
				if(project === window.plansKey){
					callSetProject(); 
				}else{
					function getRDFModelAndSetProject(){
						var plansRdfModel = router.dataManager.getRDFModel(window.plansKey);
						if(plansRdfModel){
							/*plansRdfModel.getPlanPhaseOfAlternative(project,function(planId,phaseId,version){
								if(!project){
									router.dataManager.invokeValueUpdates(function(){
										callSetProject(planId,phaseId,version);	
									});
								}else {
									if(phaseId != "" && !planId){//Refresh on phase page
										//window.utils.navigatePage(false);
										//TODO load Plan
										router.dataManager.getPlanInstance(currentPlanId, currentVersion ? currentVersion : router.dataManager.get('currentPlanVersion'), function(){
											setTimeout(function(){
												plansRdfModel.getPlanPhaseOfAlternative(project,function(planId,phaseId,version){
													if(!project){
														router.dataManager.invokeValueUpdates(function(){
															callSetProject(planId,phaseId,version);	
														});
													}else {
														if(phaseId != "" && !planId){//Refresh on phase page
															window.utils.navigatePage(false);
														}else {
															callSetProject(planId,phaseId,version);
														}
													}
												});
											},1000);
										},documentVersion);
									}else {
										callSetProject(planId,phaseId,version);
									}
								}
								
							});*/
							callSetProject(currentPlanId,null,currentVersion);
							return true;
						}
						return false;
					}
					if(!router.dataManager.get('readMode') && !window.utils.checkIsLinked(null,true) && window.vdmModelView && window.vdmModelView.hasUnsavedData && window.vdmModelView.hasUnsavedData() && window.vdmModelView.saveDataBeforeClosing) {
						bootbox.confirm(router.dataManager.get('localeManager').get('SaveDataBeforeClosing'), function(result) {
							if(result){
								window.vdmModelView.saveDataBeforeClosing(function(){
									if(!getRDFModelAndSetProject()){
										setTimeout(getRDFModelAndSetProject,1000);
									}
								});
							}else {
								if(!getRDFModelAndSetProject()){
									setTimeout(getRDFModelAndSetProject,1000);
								}
							}
						});
					}
					else if(!getRDFModelAndSetProject()){
						setTimeout(getRDFModelAndSetProject,1000);
					}
				}				        		
	        },
	        activateView:function(model,view,searchValue,project,viewOptions){
				$("#breadcrumb").empty();
                var options = { 'searchValue': searchValue, 'project': project };
                if (viewOptions) {
                    _.extend(options, viewOptions);
                }
				var viewProperties = model.getViewProperties(view);
				var activiateView = function(vdmModelViewType,vdmModelView){
					// window.updateIframe();
					//window.handleApplications();
					if(vdmModelView.handlePublicMenus){
						vdmModelView.handlePublicMenus();
					}
				};
				window.getAndCreateView(viewProperties.templatePath,
								viewProperties.templateName,
								model,
								viewProperties.viewTypeStr,
								viewProperties.tabId,
								viewProperties.tabName,											
								activiateView,options);	
				        	
	        },
	        setCurrentPlan:function(plan,callback){
	        	var router = this;
    			router.dataManager.set('currentPlan',plan);
				var count = 0;
				function loadCommonScenario(){
					if(window.loadedPackages[plan.get('id')] || count > 100){
						plan.getCommonScenario(function(commonScenario){
							router.dataManager.set('currentCommonScenario',commonScenario);	
							if(callback){
								callback();
							}
						});	
					} else {
						setTimeout(function(){
							count++;
							loadCommonScenario(); 	
						},100);
					}
				}	
				loadCommonScenario();			
	        },
	        resetCurrentPlan:function(){
	        	var router = this;
	        	router.dataManager.set('currentPlan',null);
	        	router.dataManager.set('currentCommonScenario',null);
	        }
    	});
    	//return Router;
		export {Router};
    //});