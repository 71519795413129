import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import { DataManager } from '../../../com/vbee/data/DataManager'
import { PhaseMixin } from './PhaseMixin'


var path = DataManager.getDataManager().buildAppNsPath("transformation", global.version);
export class Phase2Mixin {
    getMixinClass() {
        return Phase2Mixin;
    };

    deletePhaseAndStep(callback) {
        var self = this;
        const phaseId = self.get('id');
        const plan = self.get('phaseOwner');
        const scenarioId = plan.get("defaultScenario");
        const planScenario = Backbone.Relational.store.getObjectByName('transformation.PlanScenario').find({ id: scenarioId });
        const sortedDataset = planScenario.getSortedDataSets(plan);
        const stepData = planScenario.get('step').models.find((obj) => obj.get('phaseId') === phaseId);
        const executionScenarioId = planScenario.get("defaultExecutionScenario");
        const executionScenario = Backbone.Relational.store.getObjectByName('transformation.ScenarioExecution').find({ id: executionScenarioId });
        const phaseNoOfPeriods = parseInt(stepData.get('noPeriods'));
        stepData.destroy();
        for (let index = 0; index < phaseNoOfPeriods; index++) {
            let inputDataset = executionScenario.get("input").findWhere(sortedDataset[sortedDataset.length - index - 1]);
            inputDataset.destroy();
        }
        callback();
    };
    static getDialogViewProperties(type) {
        if (type == "PhaseDetails") {
            return {
                templatePath: "views/transformation/views/properties/PhaseDetailsTemplate.html",
                templateName: "PhaseDetailsTemplate",
                viewTypeStr: "appviews/transformation/views/properties/PhaseDetails2ViewModel",
                tabId: "PhaseDetails2View",
                tabName: "Phase"
            }
        } else {
            return PhaseMixin.getDialogViewProperties(type);
        }
    }
}

path.Phase2Mixin = Phase2Mixin;
utils.customExtendClass(Phase2Mixin, new PhaseMixin());

