import * as $ from 'jquery';
import * as jqueryui from 'jquery-ui';
import * as bootstrap from './libs/bootstrap/bootstrap-3.4.1/js/bootstrap';
import * as colResizable from './libs/colResizable-1.6'
import * as wizard from './libs/fuelux/wizard'
import * as _ from 'underscore'
import { v4 as uuidv4 } from 'uuid'
import * as async from 'async'
import * as  BackboneRelational from './libs/backbone-relational/backbone-relational'
import * as  backboneLawnchair from './libs/lawnchair/backbone.lawnchair'
import * as  Spinner from './libs/spin/spin.min'
import * as  enhsplitter from './libs/enhsplitter/jquery.enhsplitter'
import * as  actual from './libs/jqueryactual/jquery.actual'
import * as  Backbone from 'backbone'
import * as Lawnchair from './libs/lawnchair/Lawnchair.js'
import IdleTracker from 'idle-tracker';
import * as typeahead from './libs/bootstrap-tagsinput/bootstrap3-typeahead'
import * as tagsinput from './libs/bootstrap-tagsinput/bootstrap-tagsinput'
import * as bootbox from './libs/bootbox/bootbox'
import * as ko from 'knockout'
import * as kb from 'knockback'
import * as math from 'mathjs'
import {DataManager} from './app/com/vbee/data/DataManager'
import {Workspace} from './app/com/vbee/filesystem/Workspace'
import {Router} from './app/com/vbee/router/Router'
import {LocaleManager} from './app/com/vbee/data/LocaleManager'
import DynamicClass from './app/DynamicClass';
import {PlanMixin} from './app/version1/bo/transformation/PlanMixin'
import {Annotation} from './app/version1/bo/smm/Annotation'
import {Attribute} from './app/version1/bo/smm/Attribute'
import {SmmElement} from './app/version1/bo/smm/SmmElement'
import {Dashboard} from './app/version1/bo/dashboard/Dashboard'
import {CodeContainer} from './app/version1/bo/beeppackage/CodeContainer'
import {BinaryMeasurementMixin} from './app/version1/bo/smm/BinaryMeasurementMixin'
import {Base1MeasurementRelationship} from './app/version1/bo/smm/Base1MeasurementRelationship'
import {Base2MeasurementRelationship} from './app/version1/bo/smm/Base2MeasurementRelationship'
import {CountingMeasurement} from './app/version1/bo/smm/CountingMeasurement'
import {CountingMeasure} from './app/version1/bo/smm/CountingMeasure'
import {NamedMeasure} from './app/version1/bo/smm/NamedMeasure'
import {RescaledMeasurement} from './app/version1/bo/smm/RescaledMeasurement'
import {NamedMeasurement} from './app/version1/bo/smm/NamedMeasurement'
import {ProcessCollaboration} from './app/version1/bo/vdml/ProcessCollaboration'
import {ApplicationCollaboration} from './app/version1/bo/vdml/ApplicationCollaboration'
import {CaseCollaboration} from './app/version1/bo/vdml/CaseCollaboration'
import { CmmnDiagram } from './app/version1/bo/vdml/CmmnDiagram';
import {Person} from './app/version1/bo/vdml/Person'
import {AbstractMeasureElement} from './app/version1/bo/smm/AbstractMeasureElement'
import {ValuePropositionDiagram} from './app/version1/bo/vdml/ValuePropositionDiagram'
import {RoleCollaborationDiagram} from './app/version1/bo/vdml/RoleCollaborationDiagram'
import {ProcessDiscoveryDiagram} from './app/version1/bo/vdml/ProcessDiscoveryDiagram'
import {CapabilityOfferDiagram} from './app/version1/bo/vdml/CapabilityOfferDiagram'
import {CustomerDimension} from './app/version1/bo/vdml/CustomerDimension'
import {RatioMeasurement} from './app/version1/bo/smm/RatioMeasurement'
import {ValueFormulaDimension} from './app/version1/bo/vdml/ValueFormulaDimension'
import {ProcessRole} from './app/version1/bo/vdml/ProcessRole'
import {CaseRole} from './app/version1/bo/vdml/CaseRole'
import {ValuePropositionDimension} from './app/version1/bo/vdml/ValuePropositionDimension'
import {NetworkPartnersDimension} from './app/version1/bo/vdml/NetworkPartnersDimension'
import {BusinessIndicatorLibraryDiagram} from './app/version1/bo/smm/BusinessIndicatorLibraryDiagram'
import {CompetenceDimension} from './app/version1/bo/vdml/CompetenceDimension'
import {ApplicationRole} from './app/version1/bo/vdml/ApplicationRole'
import {ActivityDimension} from './app/version1/bo/vdml/ActivityDimension'
import {OCLOperation} from './app/version1/bo/smm/OCLOperation'
import {Member} from './app/version1/bo/vdml/Member'
import {Party} from './app/version1/bo/vdml/Party'
import {Position} from './app/version1/bo/vdml/Position'
import {UserMixin} from './app/version1/bo/tickets/UserMixin'
import {UserPreferences} from './app/version1/bo/preference/UserPreferences'
import { RefinementMeasureRelationship } from './app/version1/bo/smm/RefinementMeasureRelationship'
import { RatioMeasure } from './app/version1/bo/smm/RatioMeasure'
import * as VS from './libs/visualsearch/js/visualsearch'
import * as search_box from './libs/visualsearch/js/views/search_box'
import * as search_facet from './libs/visualsearch/js/views/search_facet'
import * as search_input from './libs/visualsearch/js/views/search_input'
import * as search_facets from './libs/visualsearch/js/models/search_facets'
import * as search_query from './libs/visualsearch/js/models/search_query'
import * as search_backbone_ext from './libs/visualsearch/js/utils/backbone_extensions'
import * as search_hotkeys from './libs/visualsearch/js/utils/hotkeys'
import * as search_jquery_ext from './libs/visualsearch/js/utils/jquery_extensions'
import * as search_parser from './libs/visualsearch/js/utils/search_parser'
import * as search_inflector from './libs/visualsearch/js/utils/inflector'
import * as search_templates from './libs/visualsearch/js/templates/templates'
import * as DataTable from './libs/DataTable/jquery.dataTables'
import DynamicClass2 from './app/DynamicClass2.js';
//import env from '../config'



//import { backendurl } = require('../config');
//console.log(`Your port is ${backendurl}`); // 8626

/*import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.css';
import dt from 'datatables.net-bs';
global.$.DataTable = dt;*/


window.$ = window.jQuery = $;
$.noConflict();
// Check if HMR interface is enabled
if (module.hot) {
  // Accept hot update
  module.hot.accept();
}

//window.async = async;
//console.log("backend url" + process.env.backendurl);
$.get("locale/locale.json",function(localData){
	var dataManager = DataManager.getDataManager();
	Backbone.Relational.store.getObjectByName("vdml.PortContainer").initializeModelHierarchy();
	Backbone.Relational.store.getObjectByName("vdml.Port").initializeModelHierarchy();
	Backbone.Relational.store.getObjectByName("vdml.Store").initializeModelHierarchy();
	Backbone.Relational.store.getObjectByName("vdml.Role").initializeModelHierarchy();
	Backbone.Relational.store.getObjectByName("vdml.VdmlElement").initializeModelHierarchy();
	Backbone.Relational.store.getObjectByName("vdml.MeasurableElement").initializeModelHierarchy();
	Backbone.Relational.store.getObjectByName("vdml.CapabilityMethod").initializeModelHierarchy();
	Backbone.Relational.store.getObjectByName("beeppackage.BeepPackage").initializeModelHierarchy();
	Backbone.Relational.store.getObjectByName("vdml.CollaborationDiagram").initializeModelHierarchy();
	Backbone.Relational.store.getObjectByName("vdml.PortDelegation").initializeModelHierarchy();
	// Backbone.Relational.store.getObjectByName("smm.MeasurementRelationship").initializeModelHierarchy();
	Backbone.Relational.store.getObjectByName("cmof.EObject").initializeModelHierarchy();
	Backbone.Relational.store.setupSuperModel(Backbone.Relational.store.getObjectByName("vdml.Activity"));
	
	
	$.get(".env",function(env){
		var paresedEnv = dataManager.setEnvScope(env);
		if ('serviceWorker' in navigator) {
			var serviceUrl = '/service-worker.js';
			if(!paresedEnv.REACT_APP_apiConfig_webApi.includes('localhost')){
				serviceUrl = '/cloud/service-worker.js';
			}
			navigator.serviceWorker
				.register(serviceUrl)
				.then(function () {
					 console.log('Service Worker Registered'); 
					})
				.catch(function(e){
					console.log('Service Worker not Registered'); 
					console.log(e); 
				});
		}
		console.log("webapi values: " + paresedEnv.REACT_APP_apiConfig_webApi);
		dataManager.setServerUrl(paresedEnv);
		if(!dataManager.get('debugMode') && !paresedEnv.REACT_APP_apiConfig_webApi.includes('localhost')){
			dataManager.setApplicationInsights();
		}
		openDefaultRepository(Lawnchair,window.initializeRouter);
	});
	var localeManager;
	window.setMathParser();
	if(typeof localData === "object"){
	   localeManager = new LocaleManager(localData, 'en');
	} else {
	   localeManager = new LocaleManager(JSON.parse(localData),'en');
	}
	var localList = localeManager.getLocales();
	var userLang = navigator.language || navigator.userLanguage; //en-US
	if(userLang) {
		var userLangPrefix = userLang.substr(0,userLang.lastIndexOf('-'));
		if(localList.indexOf(""+userLangPrefix) > -1){
			localeManager.setLocale(userLangPrefix);
		}
	}
	dataManager.set('localeManager',localeManager);
	//dataManager.set("require1",requirejs);
	var h = $(window).height();
	$("iframe").height(h - 145);
	handleMenus();
	window.loadedPackages = [];
	//DateFormat.setDateFormat();
	/*let myPromise = new Promise(function(myResolve, myReject) {
	  openDefaultRepository(Lawnchair,myResolve);
	  //myResolve(); // when successful
	  myReject();  // when error
	});
	myPromise.then(
	  function(value) { initializeRouter() },
	  function(error) { console.log(error) }
	);*/
	
	
	window.getLocalStorage("autoSave",function(autoSave){
		if(autoSave == undefined || autoSave == null){
			autoSave = true;
		}
		if(autoSave === "true" || autoSave === true ){
			autoSave = true;
		}else{
			autoSave = false;
		}
		dataManager.set("autoSave", autoSave);
	});
});

window.legalEntitySelectionChange = function(){
	var selValue = jQuery("#legalEntitySelection :selected").val();
	var dataManager = DataManager.getDataManager();
	//dataManager.set('readMode',false);
	if(selValue && dataManager.get("legalEntityDetails")){
		if (!dataManager.get('offLineMode')) {
			var currentLegalEntity = dataManager.get("legalEntityDetails")[selValue];
			dataManager.set("currentLegalEntity", currentLegalEntity);
			$("#userFullName").html(currentLegalEntity.userName);
			dataManager.set('gapiDisplayName',currentLegalEntity.userName);
			var currentEndPoint = window.location.href.substr(window.location.href.indexOf('#')+1);
			if(currentEndPoint && currentEndPoint.length == 0){
				dataManager.get('router').navigate("/", { trigger: true });
			}
			if(currentLegalEntity.entityId){
				dataManager.set("serverRoles", currentLegalEntity.roles);
			}
			dataManager.setProductLicence(selValue);
			showAdminMenus(selValue);
			/*var roles = dataManager.get('serverRoles');
			if((roles && roles.length == 1 && roles.indexOf('REVIEWER') != -1) || !roles || (roles && roles.length == 0)){
				dataManager.set('readMode',true);
			}*/
			dataManager.setAccessRights(currentLegalEntity.entityId);
		} else {
			dataManager.setProductLicence(selValue);
		}
	}
};
function handleMenus(){
	/*$("#legalEntitySelection").on('change', function () {
		var selValue = this.selectedOptions[0] ? this.selectedOptions[0].value : null;
		 if(selValue && DataManager.getDataManager().get("legalEntityDetails")){
			 if (!DataManager.getDataManager().get('offLineMode')) {
				 var currentLegalEntity = DataManager.getDataManager().get("legalEntityDetails")[selValue];
				 DataManager.getDataManager().set("currentLegalEntity", currentLegalEntity);
				 DataManager.getDataManager().get('router').navigate("/", { trigger: true });
				 setProductLicence(selValue);
				 showAdminMenus(selValue);
			 } else {
				 setProductLicence(selValue);
			 }
		 }
	 });*/
	$('.menuButton').on('click', function(event) {
		$('.navbar-collapse').removeClass('in');
		var $target = $(event.target);
		var navMenu =  $('.navbar-collapse');
		if($target.hasClass('navbar-toggle') || $target.parent().hasClass('navbar-toggle')){
			//var ul = navMenu.children();
			//var li = ul.children();
			var top = $('.navbar-toggle')[0].offsetTop + $('.navbar-fixed-top').outerHeight();
			navMenu.css('top', top - 10 - $('.navbar-toggle')[0].offsetTop);
		}
	});
	/*$('#back').click(function(){
		Backbone.history.history.back();
	});
	$('#forward').click(function(){
		Backbone.history.history.forward();
	});
	$('#zoomin').click(function(){
		var zoomStr = document.documentElement.style.zoom;
		if(zoomStr === ""){
			zoomStr = '1';
		}
		zoomStr = parseFloat(zoomStr) + 0.1;
		document.documentElement.style.zoom = zoomStr;
		if(zoomStr >= 1){
			document.documentElement.style.overflowX = "auto";
			document.documentElement.style.width = window.innerWidth+"px";
		}
		var rep = window.plansKey;
		UserPreferences.setUserPreference(rep,'Zoom','Zoom',zoomStr);
		var slickWrappers = $('.slickWrapper');
		if(slickWrappers.length>0){
			slickWrappers.slick('refresh');
		}
		$('.zoomPercent').remove();
		$('#zoomin').append("<span class='zoomPercent'>"+parseFloat(zoomStr*100).toFixed(0)+"%</span>");
	});*/
	window.introductionMenu = function(){
		$('.navbar-collapse').removeClass('in');
		showOpeningPage(true);	
	}
	/*window.storeMenu = function(){
		if(!DataManager.getDataManager().get('vmpServerService')){
			bootbox.alert("Please Login");
			return;
		}
		$('.navbar-collapse').removeClass('in');
		var id = window.utils.htmlEscape(window.guidGenerator());
		var user;
		var options = { viewId: id };
		window.getAndCreateModalDialog(false, id, UserMixin, user, "ProductList", null, options);
	}*/
	window.guideMenu = function(){
		$('.navbar-collapse').removeClass('in');
		var id = window.utils.htmlEscape(window.guidGenerator());
		var user;
		var options = { viewId: id , selectedId: 'userStores'};
		window.getAndCreateModalDialog(false, id, UserMixin, user, "UserStores", null, options);
	}
	window.aboutMenu = function(){
		$('.navbar-collapse').removeClass('in');
		$.get("js/app/com/vbee/filesystem/views/About.html", function (AboutPage) {
			bootbox.dialog({message: AboutPage, className: "aboutModal"});
				var version = window.utils.getAppVersion();
				var currentYear = new Date().getFullYear();
				$('#year').text(currentYear);
				if (window.utils.checkChrome()) {
					$('#appVersion').text("Version: " + version);
					//$('#revision').text(process.env.description);
                    //$('#revision').text(chrome.runtime.getManifest().description);
				}else{
					$('#appVersion').text("Version: " + version);
				}
		});
	}

	/*$('#community').click(function(){
		$('.navbar-collapse').removeClass('in');
		window.open("https://vdmbee.com/forums/forum/value-management-platform-forum", "Community Registration");
	});*/
	/*$('#zoomout').click(function(){
		var zoomStr = document.documentElement.style.zoom;
		if(zoomStr === ""){
			zoomStr = '1';
		}
		zoomStr = parseFloat(zoomStr) - 0.1;
		document.documentElement.style.zoom = zoomStr;
		if(zoomStr <= 1){
			document.documentElement.style.overflowX = "hidden";
			var navBarWidth = $('.navbar').outerWidth();
			document.documentElement.style.width = navBarWidth+"px";
			$(".rightPanel").css('width', navBarWidth- $(".left_panel"));
		}
		if($('#splitPanels').length>0){
			$("#splitPanels").enhsplitter('refresh');
		}
		var rep = window.plansKey;
		UserPreferences.setUserPreference(rep,'Zoom','Zoom',zoomStr);
		var slickWrappers = $('.slickWrapper');
		if(slickWrappers.length>0){
			slickWrappers.slick('refresh');
		}
		$('.zoomPercent').remove();
		$('#zoomin').append("<span class='zoomPercent'>"+parseFloat(zoomStr*100).toFixed(0)+"%</span>");
	});*/
	$('#printRDF').click(function(){
		DataManager.getDataManager().getRDFModel().print();
	});

	$('#printReferencesRDF').click(function(){
		var rdfModel = DataManager.getDataManager().getRDFModel();
		//rdfModel.getReferenceToAlternative();
		DataManager.getDataManager().loadBeepPackages(rdfModel);
	});
	$("#scriptError").click(function(){
		window.onerror('Test', 'Test', 'Test', 'Test', null);
	});

	/*$('#incFont').click(function(event){
		var rep = window.plansKey;
		var fontScale = UserPreferences.getUserPreference(rep,'fontSize','fontSize');
		if(fontScale === ""){
			fontScale = '0';
		}
		fontScale++;
		var allElements;
		if(window.vdmModelView.ValueStreamMapDesignerViewModel){
			allElements = document.querySelectorAll('.showDashboardValueStreamMap');
		}else if(window.vdmModelView.EcoMapDesignerViewModel){
			allElements = document.querySelectorAll('.dashboardEcoMap');
		}else if(window.vdmModelView.StrategyMapDesignerViewModel){
			allElements = document.querySelectorAll('.dashboardStrategyMap')
		}else if(window.vdmModelView.CapabilityLibraryDiagramViewModel){
			allElements = document.querySelectorAll('.dashboardEcoMap');
		}else{
			allElements = document.querySelectorAll('*');
		}
		var chartElements = $('.chart-container').find('*');
		for (var i = allElements.length-1; i >= 0; i--) {
			var chartEleFound =  false;
			for(var j = 0; j < chartElements.length; j++){
				if(allElements[i] === chartElements[j]){
					chartEleFound = true;
					break;
				}
			}
			if(chartEleFound)
			continue;
			var curSize= parseInt($(allElements[i]).css('font-size')) + 1;
			$(allElements[i]).css('font-size', curSize);
		}
		UserPreferences.setUserPreference(rep,'fontSize','fontSize',fontScale);
		var navSize= parseInt($('.navbar-position').css('width')) + 6;
		$('.navbar-position').css('width',navSize)
		event.preventDefault();
	});*/
	/*$('#decFont').click(function(event){
		var rep = window.plansKey;
		var fontScale = UserPreferences.getUserPreference(rep,'fontSize','fontSize');
		if(fontScale === ""){
			fontScale = '0';
		}
		fontScale--;
		var allElements;
		if(window.vdmModelView.ValueStreamMapDesignerViewModel){
			allElements = document.querySelectorAll('.showDashboardValueStreamMap');
		}else if(window.vdmModelView.EcoMapDesignerViewModel){
			allElements = document.querySelectorAll('.dashboardEcoMap');
		}else if(window.vdmModelView.StrategyMapDesignerViewModel){
			allElements = document.querySelectorAll('.dashboardStrategyMap')
		}else if(window.vdmModelView.CapabilityLibraryDiagramViewModel){
			allElements = document.querySelectorAll('.dashboardEcoMap');
		}else{
			allElements = document.querySelectorAll('*');
		}
			
		var chartElements = $('.chart-container').find('*');
		for (var i = allElements.length-1; i >= 0; i--) {
			var chartEleFound =  false;
			for(var j = 0; j < chartElements.length; j++){
				if(allElements[i] === chartElements[j]){
					chartEleFound = true;
					break;
				}
			}
			if(chartEleFound)
			continue;
			var curSize= parseInt($(allElements[i]).css('font-size')) - 1;
			$(allElements[i]).css('font-size', curSize);
		}
		UserPreferences.setUserPreference(rep,'fontSize','fontSize',fontScale);
		var navSize= parseInt($('.navbar-position').css('width')) - 6;
		$('.navbar-position').css('width',navSize)
		event.preventDefault();
	});
	$('#Refresh').click(function(){
		//var win = electron.getGlobal('win');
		//win.reload();
		location.reload(true);
	});*/

	var marginTop = $('.navbar-fixed-top').outerHeight();
	$('#wrapper').css('margin-top', marginTop);
}
/*window.closeApplication = function () {
	console.log("closing app");
	if (window.utils.checkChrome()) {
		return;
	}
	function persistData() {
		window.utils.startSpinner('loadSaveSpinner', "Saving Data...");
		function closeApp() {
			window.utils.showSpinnerText('Closing...');
			setTimeout(function () {
				window.utils.stopSpinner('loadSaveSpinner');
				if (DataManager.getDataManager().get("repositorySaver")) {
					DataManager.getDataManager().get("repositorySaver").terminate();
				}
				window.close();
			}, 1000);
		}
		function checkWorker() {
			DataManager.getDataManager().get("repositorySaver").postMessage({ action: "repositorySaver", value: "closeApp"});
		}
		window.setTimeout(function () {
			var dataManager = DataManager.getDataManager();
			dataManager.set('closingApp', true);
			dataManager.set('isActive', false);
			dataManager.saveData({
				success: checkWorker,
				error: checkWorker,
				persist: true
			});
			console.log("done closing app");
		}, 100);
	}
	if (window.vdmModelView && window.vdmModelView.hasUnsavedData && window.vdmModelView.hasUnsavedData() && window.vdmModelView.saveDataBeforeClosing) {
		bootbox.confirm(DataManager.getDataManager().get('localeManager').get('SaveDataBeforeClosing'), function (result) {
			if (result) {
				window.vdmModelView.saveDataBeforeClosing(function () {
					persistData();
				});
			} else {
				persistData();
			}
		});
	} else {
		persistData();
	}
}*/


window.setMathParser = function() {
	if (window.parser) {
		window.parser.clear();
	} else {
		window.parser = math.parser();
	}
	window.parser.set('toFixed', function (measurementValue, roundingFactor) {
		if (isNaN(measurementValue) || measurementValue === Infinity || measurementValue === -Infinity || measurementValue == Number.MAX_VALUE) {
			return Number.MAX_VALUE;
		} else if (measurementValue !== '') {
			return measurementValue.toFixed(roundingFactor);
		}
	});
	window.parser.set('measureRelation', function (m, of, op, val) {
		switch (op) {
			case 3:
				return math.evaluate(m + "*" + val + "+" + of);
			case 0:
				return 1/math.evaluate(m + "*" + val + "+" + of);
			case 1:
				return math.square(math.evaluate(m + "*" + val + "+" + of));
			case 2:
				return math.sqrt(math.evaluate(m + "*" + val  + "+" + of));
		}
	});
	window.parser.set('measurement', function (acc) {
		switch (acc) {
			case 0:
				var sumStr = "";
				for (var i = 1; i < arguments.length; i++) {
					sumStr = sumStr + (i === 1 ? "sum(" : ",");
					sumStr = sumStr + arguments[i];
				}
				sumStr = sumStr + ")";
				return math.evaluate(sumStr);
			case 3:
				var maxStr = "";
				for (i = 1; i < arguments.length; i++) {
					maxStr = maxStr + (i === 1 ? "max(" : ",");
					maxStr = maxStr + arguments[i];
				}
				maxStr = maxStr + ")";
				return math.evaluate(maxStr);
			case 4:
				var minStr = "";
				for (i = 1; i < arguments.length; i++) {
					minStr = minStr + (i === 1 ? "min(" : ",");
					minStr = minStr + arguments[i];
				}
				minStr = minStr + ")";
				return math.evaluate(minStr);
			case 2:
				var medStr = "";
				for (i = 1; i < arguments.length; i++) {
					medStr = medStr + (i === 1 ? "mean(" : ",");
					medStr = medStr + arguments[i];
				}
				medStr = medStr + ")";
				return math.evaluate(medStr);
			case 1:
				var prdStr = "";
				for (i = 1; i < arguments.length; i++) {
					prdStr = prdStr + (i === 1 ? "prod(" : ",");
					prdStr = prdStr + arguments[i];
				}
				prdStr = prdStr + ")";
				return math.evaluate(prdStr);
			case 5:
				var stdStr = "";
				for (i = 1; i < arguments.length; i++) {
					stdStr = stdStr + (i === 1 ? "std(" : ",");
					stdStr = stdStr + arguments[i];
				}
				stdStr = stdStr + ")";
				return math.evaluate(stdStr);
			case 6:
				var wtStr = "(";
				var divisor = "(";
				for (i = 1; i < arguments.length; i++) {
					wtStr = wtStr + (i === 1 ? "" : "+");
					wtStr = wtStr + "(" + arguments[i] + ")";
					i++;
					wtStr = wtStr + " * " + "(" + arguments[i] + ")";
					if (i === 2) {
						divisor = divisor + "(" + arguments[i] + ")";
					} else {
						divisor = divisor + " + " + "(" + arguments[i] + ")";
					}
					
				}
				wtStr = wtStr + ")";
				divisor = divisor + ")";
				wtStr = wtStr + "/" + divisor;
				
				return math.evaluate(wtStr);
		}
	});
	window.parser.set("measure", window.parser.get("measurement"));
	//parser.set("_sdf_sdf4dfg",10);
	//console.log(math.evaluate(parser.evaluate('mr(1,0,0,_sdf_sdf4dfg)')));
	//console.log(math.evaluate(parser.evaluate('mt(0,[mr(1,0,0,1),mr(1,0,0,2)])')));

};

window.plansKey = "@BEEPPlans@";
// window.vdmbee = "VDMBee";
// window.university = "VDMBeeUniversity";
// window.enterprise = "VDMBeeEnterprise";
window.vdmbee = "c5df1fb1-555e-4dbe-82ae-c127ee6da5da".toUpperCase();
window.university = "dd35bd9e-ff9b-4051-84f0-a455d90f12c2".toUpperCase();
window.enterprise = "a549c7f3-3b1d-4a71-870d-e6b96b123218".toUpperCase();
window.community = "f9434115-d5a8-4d4c-b13d-1bdb83549499".toUpperCase();

window.getDefaultRepository = function(lc,callback,workspaceKey,workspaceName,skipSwitchWorkspace){
	var defaultWorkSpaceKey = window.plansKey;
	var defaultWorkSpaceName = "Strategyplanner";
	if(workspaceKey){
		defaultWorkSpaceKey = workspaceKey;
		if(workspaceName){
			defaultWorkSpaceName = workspaceName;
		}else{
			defaultWorkSpaceName = "Phase";
		}
	}
	var dataManager = DataManager.getDataManager();
	var oldCurrentWorkSpaceKey = dataManager.get('currentWorkSpaceKey');
	dataManager.set('currentWorkSpaceKey', defaultWorkSpaceKey);
	var ws = Workspace.find({ id: defaultWorkSpaceKey });
	if (ws) {
		setWorkspaceModel(ws);
		return;
	}
	lc.keys(function(keys) {
		var foundKey = false;
		if(keys){
			for(var i=0;i<keys.length;i++)	{
				if(keys[i] === defaultWorkSpaceKey){
					foundKey = true;
					lc.get(keys[i],handleExistingWorkspace);
				}
			}
		}
		if(!foundKey){
			createNewWorkspace();
		}
	});

	function setWorkspaceModel(workspaceModel) {
		Backbone.Relational.store.register(workspaceModel);	// mrajender Found that the workspace is not registered in backbone relational store
		dataManager.set('suppressLogging', false);
		if (!skipSwitchWorkspace) {
			dataManager.setWorkspace(workspaceModel, function () {
				dataManager.set('currentWorkspace', workspaceModel);
				if (callback) {
					callback(workspaceModel);
				}
			});
		} else {
			dataManager.set('currentWorkSpaceKey', oldCurrentWorkSpaceKey);
			if (callback) {
				callback(workspaceModel);
			}
		}
	}
	function handleExistingWorkspace(record,index){
		if(record && (record.key === defaultWorkSpaceKey)){
			dataManager.set('suppressLogging',true);
			Workspace.fetch(record.key,lc,{
				success:function(workspaceModel){
					setWorkspaceModel(workspaceModel);
				},
				error:function(){
					dataManager.set('suppressLogging',false);
					dataManager.set('currentWorkspace',null);
					dataManager.set('currentFolder',null);
				}
			});
		}else{
			if(callback){
				callback();
			}
		}
	}
	function createNewWorkspace(){
		var defaultWorkspace;
		//console.log("creating workspace with id:" + defaultWorkSpaceKey);
		var ret = dataManager.get('workspacesData').findWhere({'workspaceId':defaultWorkSpaceKey});
		if(ret){
				defaultWorkspace  = ret.get('workspace');
		}else{
			defaultWorkspace = new Workspace({id:defaultWorkSpaceKey,name:defaultWorkSpaceName});
			//dataManager.saveData();
			defaultWorkspace.save();
		}
		if(!skipSwitchWorkspace){
			dataManager.setWorkspace(defaultWorkspace,function(){
				dataManager.set('currentWorkspace',defaultWorkspace);
				//$("#currentRepository")[0].innerText = defaultWorkspace.get('name');
				//$("#currentRepository")[0].innerText = DataManager.getDataManager().get('localeManager').get('MyPlans');
				//window.folderView.loadFolderDocuments(defaultWorkspace.get('id'),defaultWorkspace.get('name'));
				if(callback){
					callback(defaultWorkspace);
				}
			});
		}else{
			dataManager.set('currentWorkSpaceKey',oldCurrentWorkSpaceKey);
			if(callback){
				callback(defaultWorkspace);
			}
		}
	}

};
function launchRepositorySaverWebWorker() {
	//$.get("libs/repositorySaver.js",function(localData){
	var worker = new Worker("js/repositorySaver.js");
	worker.onmessage = function (e) {
		if (e.data.value === 'closeApp') {
			if (DataManager.getDataManager().get("repositorySaver")) {
				DataManager.getDataManager().get("repositorySaver").terminate();
			}
			window.utils.showSpinnerText('Closing...');
			window.close();
		}
	};
	worker.onerror = function (e) {
		console.log('Error: Line ' + e.lineno + ' in ' + e.filename + ': ' + e.message);
	};
	DataManager.getDataManager().set("repositorySaver", worker);
	worker.addEventListener('message', function (e) {
		var options = e.data;
		var vdmStore = DataManager.getDataManager().getVDMStore(options.name);
		if (!vdmStore) {
			if (options.name === "WorkspacesStore") {
				vdmStore = DataManager.getDataManager().workspaceStore;
				if (!vdmStore) {
					DataManager.getDataManager().createWorkspaceStore(function (vdmStore) {
						initializeVDMStore(vdmStore, options);
					})
				} else {
					initializeVDMStore(vdmStore, options);
				}
			} else {
				var repId = options.name.substr(0, options.name.lastIndexOf('@')+ 1);
				DataManager.getDataManager().getWorkspaceDataWithId(repId, function (wsData) {
					vdmStore = wsData.get('vdmStore');
					initializeVDMStore(vdmStore, options);
				});
			}
		}
	}, false);
}
function initializeVDMStore(vdmStore,options) {
	vdmStore.init(options, function (store, bytes) {
		DataManager.getDataManager().get("repositorySaver").postMessage({ action: "repositorySaver", value: "initDone", name: options.name, postOptions: options, grantedBytes: bytes});
	})
}
/*window.onerror = function (errorMsg, url, lineNumber, column, errorObj) {
	var aKey = 65, cKey = 67; //keyCode for a - 65 and keyCode for c - 67
	DataManager.getDataManager().set("errorOccured", true);
	window.autoProcess = false;
	var spinnerDiv = $('#SpinnerDiv');
	var isSpinnerInProgress = spinnerDiv.attr('spinnerProgress');
	var stack = '';
	if (errorObj) {
		stack = errorObj.stack ? (errorObj.stack.replace(/^[^\(]+?[\n$]/gm, '')
			.replace(/^\s+at\s+/gm, '')
			.replace(/^Object.<anonymous>\s*\(/gm, '{anonymous}()@')
			.split('\n')) : errorObj;
	} else if(column == "0" || !column.includes('DataTables')){
		window.utils.stopSpinner(spinnerDiv.prop("spinnerId"));
		bootbox.alert("Your plan is corrupted. Please delete and re-import the plan");
		return;
	}

	if (isSpinnerInProgress) {
		var errorDetailsDiv = "<div  style=\"display:none\" class=\"restartDialog\">\
				<br>\<div contenteditable id=\"errorStack\" style=\"font-family: Roboto;overflow-x:scroll\">"+ 'Error: ' + errorMsg + ' Script: ' + url + '<br><b> Line: </b>' + lineNumber + '<br> <b>Column: </b>' + column + '<br><b> StackTrace: </b>' + stack + "</div></div>";
		var appError = bootbox.dialog({
			message: "<b>Application encountered an error. Do you want to restart the App? </b>" + errorDetailsDiv,
			title: "App Restart Confirmation Dialog",
			//closeButton: false,
			buttons: {
				details: {
					label: "Error Details",
					className: "",
					callback: function () {
						$(".restartDialog").toggle("slow", "swing");
						$("#errorStack").keydown(function (e) {
							if ((e.ctrlKey && (e.keyCode == aKey || e.keyCode == cKey))) {
								return true;
							} else {
								return false;
							}
						});
						return false;
					}
				},
				noclose: {
					label: "Send to Support",
					className: "btn btn-default",
					callback: function () {
						var params = {};
						params.email = DataManager.getDataManager().get('email');
						params.createdDate1 = new Date();
						var stackArr = "";
						for (var i = 0; i < stack.length; i++) {
							stackArr += stack[i] + ', ';
						}
						params.stackTraceInfo = stackArr;
						params.lineNumber = lineNumber.toString();
						params.columnNumber = column.toString();
						params.errorMessage = errorMsg;
						var version = window.utils.getAppVersion();
						params.scriptUrl = url + "-" + version;
						params.status = "false";

						utils.httpRequest("POST", DataManager.getDataManager().get('vmpServerHost') + '/invitation/errorloghistory',function(response){
							if (response === false || (response.includes && !response.includes('apierror'))) {
								bootbox.alert('Thank you for your feedback.');
							}
						},params);
						return false;
					}                        
				},					
				primary: {
					label: "Shutdown",
					className: "btn btn-primary",
					callback: function () {
						if (window.utils.checkChrome()) {
							if (DataManager.getDataManager().get("repositorySaver")) {
								DataManager.getDataManager().get("repositorySaver").terminate();
							}
							window.close();
						} else {
							DataManager.getDataManager().get('router').navigate("/", { trigger: true })
							if (DataManager.getDataManager().get("repositorySaver")) {
								DataManager.getDataManager().get("repositorySaver").terminate();
							}
						}
						//closeSpinner();
					}
				}
			}
		});
		function closeSpinner() {
			window.utils.stopSpinner(spinnerDiv.prop("spinnerId"));
		}
		closeSpinner();
	} else {
		bootbox.alert('Error: ' + errorMsg + ' Script: ' + url + ' Line: ' + lineNumber
			+ ' Column: ' + column + ' StackTrace: ' + errorObj);
	}
};*/
function openDefaultRepository(Lawnchair,callback) {
	launchRepositorySaverWebWorker();
	var dm = DataManager.getDataManager();
	var workspaceStore = dm.get("workspaceStore");
	if(workspaceStore){
		window.getDefaultRepository(workspaceStore,callback);
	}
	else{
		workspaceStore = new Lawnchair({name:"WorkspacesStore",adapter: DataManager.getLanwnchairAdapters()},function(){
			this.projectId = window.plansKey;
			window.getDefaultRepository(this,callback);
		});
	}
}
window.initializeRouter = function(){
	var dataManager = DataManager.getDataManager();
	function createRouterInstance(){
		var router = new Router();
		router.dataManager = dataManager;
		dataManager.set("router",router);
		if(!Backbone.History.started){
			Backbone.history.start();
		}
	}
	//createRouterInstance();
	/*var currentWorkSpace = dataManager.get('currentWorkspace');
	if(dataManager.get('debugMode')){
		currentWorkSpace.set('accepted',true);
		createRouterInstance();
		return;
	}
	if(currentWorkSpace.get('accepted')){*/
		dataManager.login(createRouterInstance);
		//dataManager.login();
	/*} else{
		currentWorkSpace.set('accepted',false);
		$.get("js/app/com/vbee/filesystem/views/EndUserAgreement.html", function (EndUserAgreement) {
			bootbox.dialog({closeButton: false, message: EndUserAgreement, className: "aggrementModal"});
			document.getElementById('aggrementCheckBox').onclick = function(view,event) {
				currentWorkSpace.set('accepted',this.checked);
				$('.aggrementModal').modal('hide')
				dataManager.login(createRouterInstance);
				//dataManager.login();
			};
		});
	}*/
}
window.handleBackboneChange = function(){
	DataManager.getDataManager().addObjectToChangedObjects.apply(this,arguments);
};
window.handleBackboneAddRelated = function(){
	DataManager.getDataManager().addRelatedObjectToChangedObjects.apply(this,arguments);
};
window.handleBackboneRemove = function(){
	DataManager.getDataManager().addDeletedObjectToChangedObjects.apply(this,arguments);
	if(this.previousAttributes() && this.previousAttributes().file){
		this.previousAttributes().file.destroy();
	}
};
window.handleBackboneDeleteRelated = function(){
	DataManager.getDataManager().addRelatedDeletedObjectToChangedObjects.apply(this,arguments);
};


window.utils = {
	focusElement: function(selector){
		var ele = $(selector);
		if(ele)
			ele.focus();
	},
	isVMPUser: function(){
		var serverRoles = DataManager.getDataManager().get("serverRoles");
		if(serverRoles && (serverRoles.includes('VMP_ADMIN') || serverRoles.includes('VMP_USER'))){
			return true;
		}
		return false;
	},
	getOrFetchWsGroup: function(planWorkspaceId,callback){
		var dataManager = DataManager.getDataManager();
		var groupList = dataManager.get("workspaceGroups") ? dataManager.get("workspaceGroups")[planWorkspaceId] : null;
		if(groupList){
			callback(groupList);
		} else {
			dataManager.getGroupsFromBackend(function(){
				callback(groupList);
			},planWorkspaceId);
		}
	},
	getCanvasJson: function(canvasType){
		var config;
		var dataManager = DataManager.getDataManager();
		//var planWorkspaceId = dataManager.getWorkspaceIdByPlanId(dataManager.get('currentPlan').id);
		var leId = dataManager.get('currentLegalEntity').entityId
		var canvasList = dataManager.get("canvasModules")[leId];
		if(canvasList){
			for(var i=0;i < canvasList.length; i++){
				var canvasName = canvasList[i].name;
				if(canvasName.replace(/ /g,'') == canvasType){
					config = JSON.parse(canvasList[i].artifact[0].code);
					break;
				}
			}
		}
		return config;
	},
    hideIconsOnReadMode: function(){
        $(".btn-complete").hide();$(".btn-danger").hide();$(".btn-action").hide();$(".btn-default").hide();
        $(".glyphicon-trash").hide();$(".glyphicon-plus-sign").hide();$(".glyphicon-minus").hide();
        $(".read-access").hide();
        $(".glyphicon-plus").hide();$(".glyphicon-pencil").hide();$(".glyphicon-button").hide();
        //$(".djs-palette-entries").hide();
        $(".entry icon-value").hide();
        $(".entry icon-activity").hide();
        $(".entry icon-competency").hide();
        $(".entry icon-perspective").hide();
        $(".entry icon-valueproposition").hide();
        $(".entry icon-marketsegment").hide();
        $(".entry icon-enterprise").hide();
        $(".entry icon-individual").hide();
        $(".entry icon-role").hide();
        $(".entry icon-businessmodel").hide();
        $(".entry bpmn-icon-sub-process-marker").hide();
        $(".entry icon-capabilitycategory").hide();
        $(".entry icon-capabilitydefinition").hide();
        $(".entry icon-text-annotation").hide();$(".entry icon-connection-multi").hide();
        $(".entry icon-map").hide();
        $(".entry icon-image-upload").hide();$(".entry icon-image-unload").hide();
        $(".entry icon-trash").hide();
    },
	checkIsLinked: function(pack,isDialog){
		var linked = false;
		var dataManager = DataManager.getDataManager();
		var packModel = null;
		if(dataManager.get("currentPlan")){
			packModel = dataManager.get("currentPlan");
		}
		if(!packModel && pack && pack.getNestedParent){
			packModel = pack.getNestedParent();
		}
		/*if(isDialog && !packModel && dataManager.get("currentPlan")){
			packModel = dataManager.get("currentPlan");
		}*/
		var packArtDoc = packModel ? dataManager.get("artifactsDocuments")[packModel.id] : null;
		if(packArtDoc && packArtDoc.ref){
			linked = true;
		}
		return linked;
	},
	getView(className){
		if(DataManager.getDataManager().isSP2Enabled()){
			return DynamicClass2.getView(className)
		}else{
			return DynamicClass.getView(className)
		}
	},
	
	/*checkPackageUpgrade: function(packId, packCallback) {
		var dataManager = DataManager.getDataManager();
		var purchasedProductId = dataManager.getPurchasedProductId(packId);
		if(purchasedProductId){
			dataManager.checkMarketPlacePackageVersion(purchasedProductId,packId,function(available){
				if(available){
					window.utils.stopSpinner('spinnerPhaseExplorer');
					bootbox.confirm("New version is available. Do you want to Upgrade it?", function(result) { 
						if(result){
							window.utils.startSpinner("upgrade", "Upgrade...");
							var planWorkspaceId = dataManager.getWorkspaceIdByPlanId(packId);
							planWorkspaceId = planWorkspaceId ? planWorkspaceId : dataManager.get('currentWorkspaceId');
							dataManager.upgradePackageVersion(packId,planWorkspaceId,function(){
								dataManager.applyWorkspaceById(planWorkspaceId,function(){
									window.utils.stopSpinner('upgrade');
									window.location.reload();
								});
							});
						} else {
							packCallback();
						}
					});
				} else {
					packCallback();
				}
			});
		} else {
			packCallback();
		}
	},*/
	loadPlan: function(planId, callback) {
        var dataManager = DataManager.getDataManager();
        var planWorkspaceId = dataManager.getWorkspaceIdByPlanId(planId);
        if(planWorkspaceId != dataManager.get("currentWorkspaceId")){
            var planWorkspaceName = dataManager.getWorkspaceName(planWorkspaceId);
			window.utils.getOrFetchWsGroup(planWorkspaceId,function(){
				dataManager.saveAndSwitchWorkspace(planWorkspaceName, function(){
					window.utils.showSpinnerPercentage(20);
					dataManager.acquireSaveLock(function(){
						//window.utils.checkPackageUpgrade(planId,function(){
							var plan = window.utils.getElementModel(planId,['transformation.Plan']);
							var skipChanges = plan ? false : true;
							dataManager.pullServerChanges(function(){
								dataManager.clearSaveInterval();
								dataManager.releaseSaveLock();
								dataManager.getPlanInstance(planId, dataManager.get('currentPlanVersion'), callback);
							},skipChanges); 
						//});
					});
				},planWorkspaceId);
			});
        } else {
			window.utils.showSpinnerPercentage(20);
			dataManager.getPlanInstance(planId, dataManager.get('currentPlanVersion'), callback);
        }
    },
	getPlanNavigateUrl: function(planId,planVer){
		var dataManager = DataManager.getDataManager();
		if(!planId){
			var plan = dataManager.get('currentPlan');
			planId = plan.id; planVer = plan.get('documentVersion');
		}
		var wsId = dataManager.getWorkspaceIdByPlanId(planId);
		//var wsId = dataManager.get('artifactsDocuments')[planId].workspaceId;
		var url = dataManager.get("currentLegalEntity").entityId + "/" + wsId + "/" + planId + "/" + planVer;
		return url;
	},
	blobToBase64(blob,callback) {
		var reader = new FileReader();
		reader.readAsDataURL(blob); 
		reader.onloadend = function() {   
			callback(reader.result);
		}
	},
	thousandSeparator(num) {
		if(!isNaN(num) && num>999){
			num = parseFloat(num).toLocaleString('en').replace(/,/g," ");
		}
		return num
	},
	pasteHandle: function(e){
		// access the clipboard using the api
		var clipdata = e.originalEvent.clipboardData || window.clipboardData;
		var str = clipdata? clipdata.getData('text'):null;
		if(str){
			const regex = /[\x00-\x1F\x7F-\x9F\u2000-\u200F\u2028-\u202F\u2060-\u2064\u206A-\u206F]/g;
			var newStr = str.replace(regex, "");
			if(newStr !== str){
				return newStr;
				// bootbox.alert("Copied Text with Hidden Characters are not allowed: "+str.match(/[^\x00-\x7F](.*)[^\]]/g)[0]);
				// return false;
			}
		}
		return null;
	},
	pasteContent: function(e, validTxt){
		const preText = $(e.target).val();
		const ele = $(e.target)[0];
		const startPos = ele.selectionStart
		var endPos = ele.selectionEnd;
		var selectedText = preText.substring(startPos,endPos);
		if(selectedText) return(preText.substring(0, startPos)+validTxt+preText.substring(endPos, preText.length));
		else if(startPos === 0) return(validTxt+preText);
		else if(startPos === preText.length) return(preText+validTxt);
		else return(preText.substring(0, startPos)+validTxt+preText.substring(startPos, preText.length));
	},
	navigatePage: function(homePage){
		var dataManager = DataManager.getDataManager();
		var bo = dataManager.get("currentPlan");
		if(!homePage && bo) {
			var planNavUrl = window.utils.getPlanNavigateUrl(bo.id,bo.get('documentVersion'));
			dataManager.get('router').navigate("views/" + planNavUrl + "/" + bo.getNestedParent().get('id') + "/transformation_Plan/" + bo.getNestedParent().get('version') + "/" + bo.get('id') + "/transformation_Plan/" + window.plansKey, { trigger: true });
		}else {
			dataManager.get('router').navigate("/", { trigger: true });
		}
	},
	getHtmlPage(templateName){
		var htmlPage
		var sp2Enabled = DataManager.getDataManager().isSP2Enabled();
		if(sp2Enabled){
			htmlPage =  DynamicClass2.getHtml(templateName);
		}else{
			htmlPage =  DynamicClass.getHtml(templateName);
		}
		return htmlPage
	},
	searchImage(plan,codeContainerId,packList,callback){
		var imageFoundList = [];
		var titleMap = {};
		titleMap['report_BeepReport'] = "Report";
		titleMap['vdml_EcoMapDiagram'] = "EcoMap Diagram";
		titleMap['vdml_StrategyMapDiagram'] = "StrategyMap Diagram";
		titleMap['vdml_ValueStreamMapDiagram'] = "ValueStreamMap Diagram";
		titleMap['vdml_CapabilityLibraryDiagram'] = "Capability Library Diagram";
		window.utils.startSpinner('loadPackageSpinner', "Searching...");
		setTimeout(function(){
			async.eachSeries(packList,function(pack,handlePac){
				if(pack.id.indexOf("CommonPackage") != -1 || (pack.get('type') != "ecomap_EcoMap" && pack.id.indexOf("Common") == -1)){
					handlePac();
				} else {
					plan.getBeepPackageWithId(pack.id, pack.get('type'), function(beepPackage){
						if(beepPackage) {
							if(beepPackage.get('type') == "report_BeepReport"){
								var contentData = beepPackage.get('content');
								var contentJQuery = jQuery(contentData);
								var canvasImgs = contentJQuery.find('img').each(function () {
									if ((this.getAttribute('diagramId') == codeContainerId || this.getAttribute('id') == codeContainerId)) {
										imageFoundList.push({ id: beepPackage.get('id'), name: beepPackage.get('name'), type: titleMap[beepPackage.get('type')] });
										return false;
									}
								});
							}else {
								var diag = beepPackage.get('diagrams');
								for(var i=0;i<diag.length;i++){
									var xml = diag.at(i).get('data');
									var xmlDoc = jQuery.parseXML(xml);
									var $xml = jQuery(xmlDoc);
									var nodes = $xml.find('[backgroundUrl="' + codeContainerId + '"]');
									if(nodes.length > 0){
										imageFoundList.push({ id: diag.at(i).get('id'), name: diag.at(i).get('name'), type: titleMap[diag.at(i).get('type')], diagType: diag.at(i).get('type')});
									}
								}
							}
							handlePac();						
						}else {
							handlePac();
						}
					});
				}
			},function(){
				window.utils.stopSpinner('loadPackageSpinner');
				callback(imageFoundList);
			});
		},100);
	},
	getPackageModels(currentAlt,type,diagCallback){
		var searchBeepType;
		var searchCommon = false;
		if(type == "dashboard"){
			searchBeepType = "dashboard_Analytics";
		} else if(type == "capabilityLibrary"){
			searchBeepType = "vdml_ValueDeliveryModel";
			searchCommon = true;
		}
		var searchType = searchBeepType.replaceAll(/\_/g,'.');
		var designPartList = [];
		var vsList = [];
		var plan = currentAlt.getNestedParent();
		var docversion = plan.get("documentVersion");
		_.each(currentAlt.get('phaseDesignPart').models,function(designPart){
			if(designPart.get('beepType') == searchBeepType){
				if(searchCommon){
					if (designPart.get('beepReference').indexOf("Common") > -1) {
						designPartList.push(designPart);
					}
				}else {
					designPartList.push(designPart);
				}
			}
		});
		async.eachSeries(designPartList,function(pack,callback){
			var packId = pack.get('beepReference');
			var packModal = window.utils.getElementModel(packId,[searchType]);
			if(packModal){
				_.each(packModal.get(type).models, function (diag) {
					vsList.push(diag); 
				})
				callback();
			}else {
				window.utils.getDocumentFromBackend(plan.id,packId,docversion,function(model){
					if(model){
						var dashList = model.get(type);
						if(dashList){
							_.each(dashList, function (diag) {
								vsList.push(new Backbone.Model(diag)); 
							});
						}
					}
					callback();
				});
			}
		},function(arr){
			diagCallback(vsList);
		});
	},
	navigateToDashboard(currentAlt) {
		var plan = currentAlt.getNestedParent();
		window.utils.getPackageModels(currentAlt,"dashboard",function(showVsList){
			var htmlContent = "<select class=\"form-control\" id=\"selectDash\">";
			function navigateToDash(selectedVS){
				var dataManager = DataManager.getDataManager();
				// var planWorkspaceId = dataManager.getWorkspaceIdByPlanId(plan.id);
				var parentId = selectedVS.get('analytics');
				if(parentId && parentId.id){
					parentId = parentId.id;
				}
				var parentType = "dashboard_Analytics";
				var version = dataManager.get('currentVDMVersion');
				/*if(planWorkspaceId != dataManager.get("currentWorkspaceId")){
					var planWorkspaceName = dataManager.getWorkspaceName(planWorkspaceId);
					dataManager.saveAndSwitchWorkspace(planWorkspaceName, function(){
						dataManager.pullServerChanges(function(){
							var altId = dataManager.getRepositoryId(selectedVS.id);
							dataManager.set('viewAlternative', altId);
							dataManager.get('router').navigate("views/" + window.utils.getPlanNavigateUrl(plan.id,plan.get('documentVersion')) + "/" + parentId + "/" + parentType + "/" + version + "/" + selectedVS.id + "/" + selectedVS.get('type') + "/" + altId, {trigger: true});
						},true);
					},planWorkspaceId);
				} else {*/
					dataManager.set('viewAlternative', currentAlt.id);
					window.utils.startSpinner('dashboardSpinner', "loading Dashboard...");
					dataManager.get('router').navigate("views/" + window.utils.getPlanNavigateUrl(plan.id,plan.get('documentVersion')) + "/" + parentId + "/" + parentType + "/" + version + "/" + selectedVS.id + "/" + selectedVS.get('type') + "/" + dataManager.getRepositoryId(selectedVS.id), {trigger: true});
				//}
			}
			if(showVsList.length === 1){
				var selectedVS = showVsList[0];
				navigateToDash(selectedVS);
			}else if(showVsList.length > 1){
				htmlContent = htmlContent + "<option>Select Dashboard  ...</option>";
				for (var i = 0; i < showVsList.length; i++) {
					htmlContent = htmlContent + "<option dashId ='" + showVsList[i].id + "'>" + showVsList[i].get('name') + "</option>";
				}
				htmlContent = htmlContent + "</select><p/>";
				bootbox.dialog({
					title: "<div style='display:flex'><svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='currentColor' class='bi bi-graph-up' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M0 0h1v15h15v1H0V0Zm14.817 3.113a.5.5 0 0 1 .07.704l-4.5 5.5a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61 4.15-5.073a.5.5 0 0 1 .704-.07Z'/></svg><div>&nbsp;&nbsp;Dashboards</div></div>",
					message: htmlContent,
					buttons: {
						main: {label: "Close",className: "btn btn-default"},
						success: {
							label: "Complete",
							className: "btn btn-complete complete-btn ",
							callback: function () {
								var dashId = $('#selectDash option:selected').attr('dashId');
								if (dashId !== undefined) {
									for (var i = 0; i < showVsList.length; i++) {
										if (showVsList[i].id == dashId) {
											var selectedVS = showVsList[i];
											break;
										}
									}
									navigateToDash(selectedVS);
								}
							}
						}
					}
				});
			} else {
				bootbox.alert("There are no Dashboards");
			}
		});
	},
	async exportFileToSystem(name,description,type,content,exportMsg) {
		if(window.automation){
			saveAs(content, "" + name + ".vpk",function(){});
				bootbox.alert(exportMsg);
		} else {
			try {
				name = name.replace(/\"/g, "");
				const options = {suggestedName: name,//startIn: "desktop",
					types: [{description: description,accept: {'application/vpk': [type] }}]
				};
				const fileHandle = await window.showSaveFilePicker(options);
				const permissionState = await fileHandle.requestPermission({writable: true,mode: "readwrite"});
				//console.log(window.isSecureContext);console.log(permissionState);
				const writable = await fileHandle.createWritable();
				await writable.write(content);
				await writable.close();
				bootbox.alert(exportMsg);
			} catch(e){
				console.log(e);
				if(e.message.indexOf("The user aborted a request.") == -1){
					saveAs(content, "" + name + "."+type,function(){});
					bootbox.alert(exportMsg);
				}
			}
		}
	},
    getSourceName(component){
		var name;
		if(component.get('type')=="vdml_ValueAdd"){
			name = component.get('valueAddOwner').get('containedPortOwner').get('name');
		}
		else{
			var componentOwner = component.get('componentOwner');
			name = componentOwner.get('name');
			var valueProvider = componentOwner.get('provider');
			if(!valueProvider){
				name = componentOwner.getNestedParent().get('name');
			}

		}
		return name;
	},
	getSourceType(component) {
		var type = "";
		var dataManager = DataManager.getDataManager();
		if (component.get('type') === "vdml_ValueAdd") {
			type = dataManager.get('localeManager').get("Activity");
		}
		else {
			var componentOwner = component.get('componentOwner');
			if (componentOwner.get('isValueFormula')) {
				type = dataManager.get('localeManager').get("MyProposition");
			}
			else {
				if (componentOwner.get('planCriterionSetOwner')) {
					type = dataManager.get('localeManager').get("Plan");
				}
				else {
					type = dataManager.get('localeManager').get("ValueProposition");
				}
			}
		}
		return type;
	},
	convertToSimpleDate(dateFormat){ 
		var newDate = new Date(dateFormat);
		if (dateFormat == null)
		return null;
		//var month = String(newDate.getMonth()).length>1 ? newDate.getMonth() : '0'+(newDate.getMonth()+1);
		var month = newDate.getMonth() >= 9 ? newDate.getMonth()+1 : '0' + (newDate.getMonth() + 1);
		var date = String(newDate.getDate()).length>1 ? newDate.getDate() : '0'+newDate.getDate();
		return date+'-'+ month +'-'+newDate.getFullYear();
	},
	s2ab(s) {//string to array buffer
		var buf = new ArrayBuffer(s.length);
		var view = new Uint8Array(buf);
		for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
		return buf;
	},
	getSuffix: function (id) {
		if(!id){
			return;
		}
		if (id.includes('#')) {
			return id.substr(id.lastIndexOf('#') + 1);
		} else {
			return id.substr(id.lastIndexOf('@') + 1);
		}
	},
	getPrefix : function(id){
		var text = id.lastIndexOf('@');
		if(text >= 0){
			return id.substr(0,id.lastIndexOf('@') + 1);		
		}else{
			return window.plansKey;
		}
	},
	getScenarioSnapshotId : function(altModel,timestamp){
		var altObs;
		/*if(!timestamp){
			altObs = _.find(altModel.getOrCreatePhaseObjectiveSet().get('objectiveObservation').models, function(obs){ return !obs.get('whenObserved'); });
		} else {
			altObs = altModel.getOrCreatePhaseObjectiveSet().get('objectiveObservation').findWhere({ whenObserved: timestamp })
		}*/
		var snapshotId = altObs?altObs.get('snapshotId'):null;
		return snapshotId;
	},
	setDatatableEmpty: function(id){
		$('#'+id).DataTable().destroy();
		$('#'+ id + ' tbody').html('');
		$('#'+ id).dataTable( {"language": {"emptyTable": "No data available in table"}});
	},
	htmlEscape: function(str){
		return String(str)
		.replace(/@/g, '')
		.replace(/ /g, '')
		.replace(/#/g, '');
	},
    escapeHtmlTags: function(str){
		return str.replace(new RegExp('<[^>]*>', 'g'), '');
	},
	getUniquePackageName: function (originalPackageName, packageName, currentAlternative, type, count) {
		var found = false;
		var phaseDesignPart = currentAlternative ? currentAlternative.get('phaseDesignPart') : "";
		for (var j = 0; j < phaseDesignPart.length; j++) {
			if (phaseDesignPart.at(j).get('name') === packageName && phaseDesignPart.at(j).get('beepType') === type) {
				found = true;
				break;
			}
		}
		if (found) {
			if(!count) {
				count = 1;
			}
			packageName = originalPackageName.concat(count);
			packageName = window.utils.getUniquePackageName(originalPackageName, packageName, currentAlternative, type, ++count);
		} else {
			return packageName;
		}
		return packageName;
	},
	removeSpace: function(str){
		return str.replace(/\s/g, '');
	},
	validateEmail: function(mail) {
		if (/^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
			return (true)
		}
		return (false)
	},
	checkImageSize: function(fileObject){
		if(fileObject && (Math.round(fileObject.size/1024) > 2048)){
			bootbox.alert("File size should be less than 2MB");
			return;
		}
		return true;
	},
	checkChrome: function(){
        return false;
		/*if(typeof chrome != "undefined" && chrome && chrome.runtime){
			return true;
		}
		return false;*/
	},
	checkNumericValue: function (event, element) {           
			// Allow: backspace, delete, tab, escape, and enter// Allow: backspace, delete, tab, escape, and enter
			if (event.keyCode == 46 || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 27 || event.keyCode == 13 ||
				// Allow: .
				(event.keyCode == 190 || event.keyCode == 110 || event.keyCode == 189 || event.keyCode == 109) ||
				// Allow: Ctrl+v
				(event.keyCode == 86 && event.ctrlKey === true) ||
				// Allow: Ctrl+c
				(event.keyCode == 67 && event.ctrlKey === true) ||
				// Allow: home, end, left, right
				(event.keyCode >= 35 && event.keyCode <= 39) || (parseInt(event.key) >= 0 && parseInt(event.key) <= 9)) {
				//Prevent excessive periods
				if ((event.keyCode === 190 || event.keyCode === 110) && $(element).val().indexOf('.') !== -1) {
					event.preventDefault();
				}
				return;
			}
			else {
				// Ensure that it is a number and stop the keypress
				//if (event.shiftKey || (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
				if (event.shiftKey || ((event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) ||
					!(parseInt(event.key) >= 0 || parseInt(event.key) <= 9)) {
					event.preventDefault();
				}
			}
	},
	getRoundedValues: function(val, sigDecimal, float) {
		var self = this;
		if (val != null && val !== '' && !isNaN(val) && val != Number.MAX_VALUE && val != Infinity && val != -Infinity) {
			var newVal = parseFloat(val).toFixed(sigDecimal);
			if(float){
				return parseFloat(newVal);
			}else {
				return newVal;
			}
		} else if (val == Number.MAX_VALUE || val == Infinity || val == -Infinity) {
			return '####';
		} else {
			return val;
		}
	},
	validateEngName:function(val) {
		if (val === null || val === undefined || val === '') {
			return false;
		}
		var allowedChars = /^[a-zA-Z0-9\s\-_.,@!#\$%\^&\*\(\)\+=]*$/;
		return allowedChars.test(val);
	},
	loadSpinner: function(){
		var opts = {
			lines: 12, // The number of lines to draw
			length: 6, // The length of each line
			width: 4, // The line thickness
			radius: 10, // The radius of the inner circle
			corners: 1, // Corner roundness (0..1)
			rotate: 0, // The rotation offset
			color: '#000', // #rgb or #rrggbb
			speed: 1, // Rounds per second
			trail: 60, // Afterglow percentage
			shadow: false, // Whether to render a shadow
			hwaccel: false, // Whether to use hardware acceleration
			className: 'spinner', // The CSS class to assign to the spinner
			zIndex: 2e9, // The z-index (defaults to 2000000000)
			top: '50%', // Top position relative to parent in px
			left: '50%' // Left position relative to parent in px
		};
		return new Spinner(opts);
	},
	startSpinner : function(spinnerId, text){
		var target = $('#SpinnerDiv:hidden');
		if(target.length>0){
            target.show();
			target.prop("spinnerId", spinnerId)
			window[spinnerId] = window.utils.loadSpinner().spin();
			target.append(window[spinnerId].el);
			target.attr('spinnerProgress', true);
			if(text){
				$('#SpinnerText').show();
				$('#SpinnerText').text(text);
				var textSize = $('#SpinnerText').width();
				var textPercentage = Math.round(textSize/$(window).width()*50);
				$('#SpinnerText').css('left',(50-textPercentage) + '%');

			}
			var isModalDialogOpened = window.utils.isModalDialogOpened();
			if(isModalDialogOpened){
				$('#spinnerBackdrop').css('opacity', '0.1');
			}
			window.utils.disableMouseEvents();
			window.myWorker = new Worker('js/worker.js');
			window.myWorker.onmessage = function(e) {
				//console.log('received:'+e.data);
			}
			/*
			setTimeout(function(){
				if(window.myWorker){
					window.myWorker.postMessage("d"+new Date().getTime());
				}
			},4000);*/
		}
	},
	showSpinnerText : function(text){
			$('#SpinnerText').show();
			$('#SpinnerText').text(text);
			var textSize = $('#SpinnerText').width();
			var textPercentage = Math.round(textSize/$(window).width()*50);
			$('#SpinnerText').css('left',(50-textPercentage) + '%');
	},
	showSpinnerPercentage : function(percentage,currentObj,list){
		if(window.myWorker){
			window.myWorker.postMessage(percentage+new Date().getTime());
		}
		var currentText = $('#SpinnerText').text();
		if(!percentage && currentObj) {
			percentage = Math.floor((currentObj / list) * 100);
		}
		if(!isNaN(percentage)) {
			var oldText = currentText.substr(currentText.lastIndexOf('.')+1);
			var originalText = currentText.replace(oldText, "");
			var totalText = ''+originalText.concat(' '+percentage+'%');
			$('#SpinnerText').text(totalText);
		}
	},
	stopSpinner : function(spinnerId){
		if(window.myWorker){
			window.myWorker.terminate();
			window.myWorker = null;
		}		
		if(window[spinnerId] && window[spinnerId].el){
			window[spinnerId].stop && window[spinnerId].stop();
		}
		if(!window.autoProcess){
			var target = $('#SpinnerDiv');
			target.attr('spinnerProgress', false);
			target.removeProp("spinnerId");
			target.hide();
			$('#SpinnerText').text("");
			$('#SpinnerText').hide();
			DataManager.getDataManager()._previousAttributes = null;
		}
		window.utils.enableMouseEvents();
	},
	isModalDialogOpened : function(){
		var isModalOpen = $('#newModal .fade.in');
		if(isModalOpen.length>0){
			return true;
		}else{
			return false;
		}
	},
	disableMouseEvents : function(){
		if(document.getElementById("newModal").hasChildNodes()){
            $("#newModal").css("pointer-events", "none");
        }else{
            // creates <div class="overlay"></div> and 
            // adds it to the DOM
            var div= document.createElement("div");
            div.id += "disableScreenDiv";
            div.className += "spinnerOverlay";
            var contentDiv = document.getElementById("content");
            contentDiv.appendChild(div); 
        }
	},
    enableMouseEvents : function(){
        $("#newModal").css("pointer-events", "auto");
        var disableScreenDiv= document.getElementById("disableScreenDiv");
        if(disableScreenDiv != null){
            disableScreenDiv.remove();
        }
	},
	replaceAll : function(str, find, replace) {
		return str.replace(new RegExp(find, 'g'), replace);
	},
	getAppVersion : function(){
		//if (window.utils.checkChrome()) {
		//	return packagejson.version;
			//return chrome.runtime.getManifest().version;
		//}else{
			return "6.8.6";//process.env.version;
			//return require('../package.json').version
			//return window.require('electron').app.getVersion();
		//}
	},
	compareVersions(version1, version2) {
		const normalizeVersion = (version) => version?version.toString().replace(/[^\d.]/g, ''):0;
		const normalizedVersion1 = normalizeVersion(version1);
		const normalizedVersion2 = normalizeVersion(version2);
		if (normalizedVersion1 >= normalizedVersion2) {
			return true;
		}
		return false;
	},
	rgbToHex : function(a){
		a=a.replace(/[^\d,]/g,"").split(","); 
		return"#"+((1<<24)+(+a[0]<<16)+(+a[1]<<8)+ +a[2]).toString(16).slice(1)
	},
	getObservableLength : function(obsArray){
		var arrLength = 0;
		for(var i=0;i<obsArray.length;i++){
			if(!obsArray[i]._destroy){
				arrLength++;
			}
		}
		return arrLength;
	},
	loadTemplate : function(finalCallback,cleanUp){
		var templateFromUrlLoader = {
			loadTemplate: function(name, templateConfig, callback) {
				if (templateConfig.fromUrl) {
					var fullUrl = '' + templateConfig.fromUrl + '?cacheAge=' + templateConfig.maxCacheAge;
					console.log("import "+name+" from "+templateConfig.fromUrl);
					$.get(fullUrl, function(markupString) {
						ko.components.defaultLoader.loadTemplate(name, markupString, callback);
						finalCallback(name);				
					});
				} else {
					// Unrecognized config format. Let another loader handle it.
					callback(null);
					finalCallback(null);
				}
			}
		};

		if(cleanUp && ko.components.loaders.length > 1){
			ko.components.loaders.shift(templateFromUrlLoader);
		}else if(!cleanUp){
			ko.components.loaders.unshift(templateFromUrlLoader);
		}
	},
	searchXmlData : function(xml, attrId, attrValue){
		var $xml = jQuery(xml);
		var nodes = $xml.find('['+attrId+'="'+attrValue + '"]');
		if(nodes.length == 0){
			return false;
		}
		return true;
	},
	getXmlData : function(type,id){
		var data;
		if(type === "vdml.ValueStreamMapDiagram"){
			data = "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n<vdml:definitions xmlns:biocolors=\"http://vdml.io/schema/vdml/biocolors/1.0\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xmlns:vdml=\"http://www.omg.org/spec/VDML/20100524/MODEL\" xmlns:vdmldi=\"http://www.omg.org/spec/VDML/20100524/DI\" xmlns:dc=\"http://www.omg.org/spec/DD/20100524/DC\" xmlns:di=\"http://www.omg.org/spec/DD/20100524/DI\" xsi:schemaLocation=\"http://www.omg.org/spec/VDML/20100524/MODEL VDML.xsd\" id=\"sample-diagram\" targetNamespace=\"http://vdml.io/schema/vdml\">\n  <vdml:ValueStreamMap id=\"v" + id + "\" >\n   </vdml:ValueStreamMap>\n <vdmldi:VDMLDiagram id=\"VDMLDiagram_1\">\n    <vdmldi:VDMLPlane id=\"VDMLPlane_1\" vdmlElement=\"v" + id + "\">\n </vdmldi:VDMLPlane>\n  </vdmldi:VDMLDiagram>\n</vdml:definitions>";
		}else if(type === "vdml.EcoMapDiagram"){
			data = "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n<vdml:definitions xmlns:biocolors=\"http://vdml.io/schema/vdml/biocolors/1.0\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xmlns:vdml=\"http://www.omg.org/spec/VDML/20100524/MODEL\" xmlns:vdmldi=\"http://www.omg.org/spec/VDML/20100524/DI\" xmlns:dc=\"http://www.omg.org/spec/DD/20100524/DC\" xmlns:di=\"http://www.omg.org/spec/DD/20100524/DI\" xsi:schemaLocation=\"http://www.omg.org/spec/VDML/20100524/MODEL VDML.xsd\" id=\"sample-diagram\" targetNamespace=\"http://vdml.io/schema/vdml\">\n  <vdml:EcoMap id=\"e" + id + "\" >\n   </vdml:EcoMap>\n <vdmldi:VDMLDiagram id=\"VDMLDiagram_1\">\n    <vdmldi:VDMLPlane id=\"VDMLPlane_1\" vdmlElement=\"e" + id + "\">\n </vdmldi:VDMLPlane>\n  </vdmldi:VDMLDiagram>\n</vdml:definitions>";
		}else if(type === "vdml.StrategyMapDiagram"){
			data = "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n<vdml:definitions xmlns:biocolors=\"http://vdml.io/schema/vdml/biocolors/1.0\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xmlns:vdml=\"http://www.omg.org/spec/VDML/20100524/MODEL\" xmlns:vdmldi=\"http://www.omg.org/spec/VDML/20100524/DI\" xmlns:dc=\"http://www.omg.org/spec/DD/20100524/DC\" xmlns:di=\"http://www.omg.org/spec/DD/20100524/DI\" xsi:schemaLocation=\"http://www.omg.org/spec/VDML/20100524/MODEL VDML.xsd\" id=\"sample-diagram\" targetNamespace=\"http://vdml.io/schema/vdml\">\n  <vdml:StrategyMap id=\"s" + id + "\" ><vdml:perspective id=\"Perspective_0ou7yqj\" name=\"Business Value\" description=\"Business Value\" /><vdml:perspective id=\"Perspective_1sie849\" name=\"Customer\" description=\"Customer\" /><vdml:perspective id=\"Perspective_1vj6878\" name=\"Value Stream\" description=\"Value Stream\" /><vdml:perspective id=\"Perspective_1ge5bze\" name=\"Competency\" description=\"Competency\" />   </vdml:StrategyMap> <vdmldi:VDMLDiagram id=\"VDMLDiagram_1\">\n <vdmldi:VDMLPlane id=\"VDMLPlane_1\" vdmlElement=\"s" + id + "\"><vdmldi:VDMLShape id=\"Perspective_0ou7yqj_di\" vdmlElement=\"Perspective_0ou7yqj\"><dc:Bounds x=\"341\" y=\"82\" width=\"1032\" height=\"146\" /></vdmldi:VDMLShape><vdmldi:VDMLShape id=\"Perspective_1sie849_di\" vdmlElement=\"Perspective_1sie849\"><dc:Bounds x=\"341\" y=\"246\" width=\"1032\" height=\"164\" /></vdmldi:VDMLShape> <vdmldi:VDMLShape id=\"Perspective_1vj6878_di\" vdmlElement=\"Perspective_1vj6878\"> <dc:Bounds x=\"341\" y=\"426\" width=\"1032\" height=\"166\" /></vdmldi:VDMLShape><vdmldi:VDMLShape id=\"Perspective_1ge5bze_di\" vdmlElement=\"Perspective_1ge5bze\"><dc:Bounds x=\"341\" y=\"610\" width=\"1034\" height=\"161\" /></vdmldi:VDMLShape> </vdmldi:VDMLPlane>\n  </vdmldi:VDMLDiagram>\n</vdml:definitions>";
		}else if(type === "vdml.CapabilityLibraryDiagram"){
			data = "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n<vdml:definitions xmlns:biocolors=\"http://vdml.io/schema/vdml/biocolors/1.0\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xmlns:vdml=\"http://www.omg.org/spec/VDML/20100524/MODEL\" xmlns:vdmldi=\"http://www.omg.org/spec/VDML/20100524/DI\" xmlns:dc=\"http://www.omg.org/spec/DD/20100524/DC\" xmlns:di=\"http://www.omg.org/spec/DD/20100524/DI\" xsi:schemaLocation=\"http://www.omg.org/spec/VDML/20100524/MODEL VDML.xsd\" id=\"sample-diagram\" targetNamespace=\"http://vdml.io/schema/vdml\">\n  <vdml:CapabilityLibraryDiagram id=\"c" + id + "\" >\n   </vdml:CapabilityLibraryDiagram>\n <vdmldi:VDMLDiagram id=\"VDMLDiagram_1\">\n    <vdmldi:VDMLPlane id=\"VDMLPlane_1\" vdmlElement=\"c" + id + "\">\n </vdmldi:VDMLPlane>\n  </vdmldi:VDMLDiagram>\n</vdml:definitions>";
		}else if(type === "vdml.CmmnDiagram"){
			data = "<?xml version=\"1.0\" encoding=\"UTF-8\"?>" + "<cmmn:definitions xmlns:dc=\"http://www.omg.org/spec/CMMN/20151109/DC\" xmlns:cmmndi=\"http://www.omg.org/spec/CMMN/20151109/CMMNDI\" xmlns:cmmn=\"http://www.omg.org/spec/CMMN/20151109/MODEL\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" id=\"definitions_08ix9c0\" targetNamespace=\"http://bpmn.io/schema/cmmn\">" + "<cmmn:case id=\"Case_"+id+"\">" + "<cmmn:casePlanModel id=\"CasePlanModel_1\" name=\"A CasePlanModel\">" + "<cmmn:planItem id=\"PlanItem_1\" definitionRef=\"Task_1\" />" + "<cmmn:task id=\"Task_1\" />" + "</cmmn:casePlanModel>" + "</cmmn:case>" + "<cmmndi:CMMNDI>" +"<cmmndi:CMMNDiagram id=\"CMMNDiagram_1\">" + "<cmmndi:Size width=\"500\" height=\"500\" />" + "<cmmndi:CMMNShape id=\"DI_CasePlanModel_1\" cmmnElementRef=\"CasePlanModel_1\">" + "<dc:Bounds x=\"114\" y=\"63\" width=\"534\" height=\"389\" />" +"<cmmndi:CMMNLabel />" + "</cmmndi:CMMNShape>" + "<cmmndi:CMMNShape id=\"PlanItem_1_di\" cmmnElementRef=\"PlanItem_1\">"+"<dc:Bounds x=\"176\" y=\"125\" width=\"100\" height=\"80\" />"+"</cmmndi:CMMNShape>"+"</cmmndi:CMMNDiagram>"+"</cmmndi:CMMNDI>"+"</cmmn:definitions>";
		}else if(type === "vdml.ProcessDiscoveryDiagram"){
			data =  "<?xml version=\"1.0\" encoding=\"UTF-8\"?>" + "<bpmn2:definitions xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xmlns:bpmn2=\"http://www.omg.org/spec/BPMN/20100524/MODEL\" xmlns:bpmndi=\"http://www.omg.org/spec/BPMN/20100524/DI\" xmlns:dc=\"http://www.omg.org/spec/DD/20100524/DC\" xmlns:di=\"http://www.omg.org/spec/DD/20100524/DI\" xsi:schemaLocation=\"http://www.omg.org/spec/BPMN/20100524/MODEL BPMN20.xsd\" id=\"sample-diagram\" targetNamespace=\"http://bpmn.io/schema/bpmn\">" + "<bpmn2:process id=\"Process_"+id+"\" isExecutable=\"false\">" + "<bpmn2:startEvent id=\"StartEvent_1\"/>" + "</bpmn2:process>" + "<bpmndi:BPMNDiagram id=\"BPMNDiagram_1\">"+ "<bpmndi:BPMNPlane id=\"BPMNPlane_1\" bpmnElement=\"Process_" + id + "\">"+ "</bpmndi:BPMNPlane>" + "</bpmndi:BPMNDiagram>" + "</bpmn2:definitions>";
		}
		return data;
	},
	replaceEscapeCharacters : function(string){
		if (string.indexOf('\'') >= 0) {
			string = string.replace(/["']/g, "&#39;");
		}
		if(string.indexOf('"') >= 0){
			string = string.replace(/["']/g, "&#34;");
		}
		return string;
	},
	restoreEscapeCharacters : function(string){
		if (string.indexOf('&#39;') >= 0) {
			string = string.replace("&#39;", '\'');
		}
		if(string.indexOf('&#34;') >= 0){
			string = string.replace("&#34;", '"');
		}
		return string;
	},
	updateObservablesForRevision : function(viewInstance,oldAltIdReg,filterObservables){
		if(viewInstance){
			function getNewViewBackboneModel(val,oldAltIdReg){
				var docType = val.get('type');
				var pack = docType.substr(0,docType.indexOf('_'));
				docType = pack + '/' + docType;
				var typeLib = DataManager.getDataManager().buildAppNsPath(docType.substr(docType.indexOf('/')+ 1).replace(/[\/]/g,'.'),"version" + 1);
				var newId = val.get('id');
				newId = newId.replace(oldAltIdReg,DataManager.getDataManager().get('viewAlternative'));
				var ret = typeLib.find({id:newId},{silent:true});
				if(ret){
					return ret;
				}else{
					return val;
				}
			};
			function getNewBackboneCollection(valColl,oldAltIdReg){
				var ret = new Backbone.Collection();
				valColl.each(function(val){
					if(val instanceof Backbone.RelationalModel){
						ret.add(getNewViewBackboneModel(val, oldAltIdReg));
					}else if(val instanceof Backbone.Model){
						var newVal = val.id.replace(oldAltIdReg,DataManager.getDataManager().get('viewAlternative'));
						val.set('id',newVal);
						var attributesList = val.attributes;
						for(var att in attributesList){
							if(attributesList[att] instanceof Backbone.Model){
								var newVal1 = getNewViewValue(attributesList[att] ,oldAltIdReg);
								val.set(att,newVal1);
							}
						}
						ret.add(val);
					}else{
						ret.add(val);
					}
				});
				return ret;
			};
			function getNewViewObject(oldObj,oldAltIdReg){
				_.each(oldObj,function(oldVal,key){
					if(key === 'view'){
						var childViewInstance = oldVal;
						window.utils.updateObservablesForRevision(childViewInstance,oldAltIdReg);
					}
					else {
						var newVal = getNewViewValue(oldVal,oldAltIdReg);
						var newKey = key.replace(oldAltIdReg,DataManager.getDataManager().get('viewAlternative'));
						oldObj[newKey] = newVal;
					}
				});
				return oldObj;
			};
			function replaceViewArray(oldValArray,oldAltIdReg){
				_.each(oldValArray,function(oldVal,index){
					var newVal = getNewViewValue(oldVal,oldAltIdReg);
					oldValArray[index] = newVal;
				});
				return oldValArray;
			};
			function getNewViewString(oldVal,oldAltIdReg){
				var newVal = oldVal.replace(oldAltIdReg,DataManager.getDataManager().get('viewAlternative'));
				return newVal;
			};
			function getNewViewValue(val,oldAltIdReg){
				if(typeof val === 'string'){
					return getNewViewString(val,oldAltIdReg);
				}else if ($.isArray(val)){
					return replaceViewArray(val,oldAltIdReg);
				}else if($.isPlainObject(val)){
					return getNewViewObject(val,oldAltIdReg);
				}else if(val instanceof Backbone.RelationalModel){
					return getNewViewBackboneModel(val,oldAltIdReg);
				}else if(val instanceof Backbone.Collection){
					return getNewBackboneCollection(val,oldAltIdReg);
				}else{
					return val;
				}
			};
			var viewProperties = Object.getOwnPropertyNames(viewInstance);
			_.each(viewProperties,function(propKey){
				if(viewInstance[propKey] !== viewInstance && _.indexOf(filterObservables,propKey) === -1) {
					var propVal = viewInstance[propKey];
					if(ko.isObservable(viewInstance[propKey]) && !ko.isComputed(viewInstance[propKey])){
						propVal = viewInstance[propKey]();
					}
					var newVal = getNewViewValue(propVal, oldAltIdReg);
					if(ko.isObservable(viewInstance[propKey]) && !ko.isComputed(viewInstance[propKey])){
						viewInstance[propKey](newVal);
					}
					else if(!ko.isComputed(viewInstance[propKey])){
						viewInstance[propKey] = newVal;
					}
				}
			});
		}
	},
	generateManifest : function(model){
		var name = model.get('name');
		var description = model.get('description');
		var	type = model.get('type');
		var	version = model.get('version');
		var revision = window.utils.getAppVersion();
		var manifestJson = {};
		manifestJson.name = name;
		manifestJson.description = description;
		manifestJson.version = version;
		manifestJson.revision = revision;
		manifestJson.type = type;
		manifestJson.company = "VDMBEE";
		return JSON.stringify(manifestJson);
	},
	splitterBoundary : function(e,splitter_container){
		var mouseX = e.pageX;
		var mouseY = e.clientY;
		if ((mouseY >= 0 && mouseY <= window.innerHeight)
		&& (mouseX >= 14 && mouseX <= window.innerWidth-23))
			return;
		if (splitter_container && e.toElement && e.toElement.className === "splitter_mask"){
			$('.splitter_mask').remove();
			splitter_container.settings.onDragEnd(e, splitter_container);
			splitter_container.removeClass('splitter-active');
		}
	},
	applyFontSize : function(id){
		/*var rep = window.plansKey;
		var fontScale = UserPreferences.getUserPreference(rep,'fontSize','fontSize');
		if(fontScale===null || fontScale===0)
		return;
		var allElements = id.find('*');
		var chartElements = $('.chart-container').find('*');
		for (var i = allElements.length-1; i >= 0; i--) {
			var chartEleFound =  false;
			for(var j = 0; j < chartElements.length; j++){
				if(allElements[i] === chartElements[j]){
					chartEleFound = true;
					break;
				}
			}
			if(chartEleFound)
			continue;
			var curSize= parseInt($(allElements[i]).css('font-size')) + fontScale;
			$(allElements[i]).css('font-size', curSize);
		}*/
	},
	applyFontSizeInDialog : function(){
		/*var rep = window.plansKey;
		var fontScale = UserPreferences.getUserPreference(rep,'fontSize','fontSize');
		if(fontScale===null || fontScale===0)
		return;
		if($('#newModal').children().length === 1){
			var allElements = $('#newModal').find('*');
		}else{
			var id = $('#newModal').children()[$('#newModal').children().length-1].id;
			allElements = $('#'+id).find('*');
		}
		for (var i = allElements.length-1; i >= 0; i--) {
			var curSize= parseInt($(allElements[i]).css('font-size')) + fontScale;
			$(allElements[i]).css('font-size', curSize);
		}*/
	},
	applyFontSizeByElement : function(elements){
		/*var rep = window.plansKey;
		var fontScale = UserPreferences.getUserPreference(rep,'fontSize','fontSize');
		if(fontScale===null || fontScale===0)
		return;
		for (var i = 0; i < elements.length; i++) {
			var curSize= parseInt($(elements[i]).css('font-size')) + fontScale;
			$(elements[i]).css('font-size', curSize);
		}*/
	},
	getSmiliesList : function(){
		var svgSmiliesList = [];
		svgSmiliesList.push({src:'M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5.507 13.941c-1.512 1.195-3.174 1.931-5.506 1.931-2.334 0-3.996-.736-5.508-1.931l-.493.493c1.127 1.72 3.2 3.566 6.001 3.566 2.8 0 4.872-1.846 5.999-3.566l-.493-.493zm-9.007-5.941c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5-.672-1.5-1.5-1.5zm7 0c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5-.672-1.5-1.5-1.5z',value:'1'});
		svgSmiliesList.push({src:'M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm.001 14c-2.332 0-4.145 1.636-5.093 2.797l.471.58c1.286-.819 2.732-1.308 4.622-1.308s3.336.489 4.622 1.308l.471-.58c-.948-1.161-2.761-2.797-5.093-2.797zm-3.501-6c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5-.672-1.5-1.5-1.5zm7 0c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5-.672-1.5-1.5-1.5z',value:'2'});
		svgSmiliesList.push({src:'M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3.5 9c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5-.672-1.5-1.5-1.5zm7 0c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5-.672-1.5-1.5-1.5zm-.941 8.94c-.903-.19-1.741-.282-2.562-.282-.819 0-1.658.092-2.562.282-1.11.233-1.944-.24-2.255-1.015-.854-2.131 1.426-3.967 4.816-3.967 3.537 0 5.666 1.853 4.817 3.968-.308.769-1.136 1.249-2.254 1.014zm-2.563-1.966c1.614 0 3.056.67 3.206.279.803-2.079-7.202-2.165-6.411 0 .138.377 1.614-.279 3.205-.279zm3.881-9.85l-3.877 3.876-3.877-3.876 1.124-1.124 2.753 2.753 2.754-2.753 1.123 1.124z',value:'3'});
		svgSmiliesList.push({src:'M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3.5 8c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5-.672-1.5-1.5-1.5zm7 0c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5-.672-1.5-1.5-1.5zm-3.499 4c-1.658 0-3.001 1.567-3.001 3.501 0 1.932 1.343 3.499 3.001 3.499 1.656 0 2.999-1.567 2.999-3.499 0-1.934-1.343-3.501-2.999-3.501z',value:'4'});
		svgSmiliesList.push({src:'M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4 17h-8v-2h8v2zm-7.5-9c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5-.672-1.5-1.5-1.5zm7 0c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5-.672-1.5-1.5-1.5z',value:'5'});
		return svgSmiliesList;
	},
	getMapName : function(diagType){
		var dataManager = DataManager.getDataManager();
		var type = dataManager.get('localeManager').get("BusinessEcosystemMap");
		if (diagType === "vdml_StrategyMapDiagram") {
			type = dataManager.get('localeManager').get("StrategyMap");
		} else if (diagType === "vdml_ValueStreamMapDiagram"){
			type = dataManager.get('localeManager').get("ValueStreamMap");
		}else if(diagType === "vdml_CapabilityLibraryDiagram"){
			type = dataManager.get('localeManager').get("CapabilityMap");
		}else if (diagType === "vdml_ValueDeliveryModel") {
			type = "Capability Package";
		}else if(diagType === "vdml_CapabilityLibrary"){
			type = dataManager.get('localeManager').get("CapabilityLibrary");
		}else if(diagType === "vdml_ValueLibrary"){
			type = dataManager.get('localeManager').get("ValueLibrary");
		}
		return type;
	},
	postImageToBackend(planId,tags,packageId,source,content,callback){   
		var dataManager = DataManager.getDataManager();
		var legalId;
		if(!packageId){
			var currentLegalEntity = dataManager.get("currentLegalEntity");
			if (currentLegalEntity && currentLegalEntity.entityId) {
				legalId = currentLegalEntity.entityId;
			} else {
				var planWorkspaceId = dataManager.getWorkspaceIdByPlanId(planId);
				var legalId = dataManager.get("workspaceLegal")[planWorkspaceId];
			}
			if (!legalId){
				callback();
			}
		}
		var params = new FormData();
		params.append("entityId",legalId);
		params.append("packageId",packageId);
		params.append("workspaceId",dataManager.get("currentWorkspaceId"));
		params.append("tags",tags);
		params.append("source","");
		params.append("file", content);
		var url = encodeURI("/vdmbee/image/upload");
		dataManager.get('vmpServerService').postAllPromise(url, params).then(function(response) {
			if(response){
				callback(response);
			} else {
				console.log("failed to load"+id);
				callback();
			}
		});
	},
	getDocumentFromBackend(planId,id,documentVersion,callback){   
		var dataManager = DataManager.getDataManager();
		var planWorkspaceId = dataManager.getWorkspaceIdByPlanId(planId);
		var legalId = dataManager.get("workspaceLegal")[planWorkspaceId];
		if (!planWorkspaceId || !legalId){
			callback();
		}
		var url = encodeURI("/vdmbee/workspace/" + planWorkspaceId + "/wsartifact/" + id +"/" + documentVersion + "?entityId=" + legalId);
		dataManager.get('vmpServerService').getAllPromise(url).then(function(response) {
			if(response && response.content){
				callback(new Backbone.Model(response.content));
			} else {
				console.log("failed to load"+id);
				callback();
			}
		});
	},
	getElementModel : function(id,typeList){
		if (!id || id instanceof Array || id instanceof Backbone.RelationalModel) {
			console.log("Inappropriate ID")
			return null;
		}
		for(var i=0;i<typeList.length;i++){
			var capModel = Backbone.Relational.store.getObjectByName(typeList[i]).find({ id: id });
			if (capModel) {
				break;
			}
		}
		return capModel;
	},
	limitExceeded : function(){
		if(DataManager.getDataManager().get('readMode')){
			bootbox.alert("You do not have Permission");
			return;
		}
		bootbox.dialog({
				title: "Note",
				message: DataManager.getDataManager().get('localeManager').get("limitReached"),
				buttons: {
					main: {
						label: "Close",
						className: "btn btn-default",
					},
					success: {
						label: "Purchase",
						className: "btn btn-complete complete-btn",
						callback: function () {
							var dataManager = DataManager.getDataManager();
							var currentLegalEntity = dataManager.get("currentLegalEntity");	
							var id = currentLegalEntity?.entityId;
							// var type = currentLegalEntity?.type === "PARTNER" ? "partner" : "customer";
							if(currentLegalEntity?.type === "PARTNER") {
								dataManager.navigateToCrmPage("partner/"+id+"/subscription")
							} else {
								dataManager.navigateToCrmPage("myaccount/"+id+"/subscription")
							}
				
							// var legalEntityDetails = dataManager.get("legalEntityDetails");
							// var currentLegalEntity = dataManager.get("currentLegalEntity");						
							// window.open("http://localhost:8091/crm/dashboard/partner/C5DF1FB1-555E-4DBE-82AE-C127EE6DA5DA/subscription", '_blank');
							// var showPage = "ProductList";
							// if(!DataManager.getDataManager().get('accessToken') || DataManager.getDataManager().get('offLineMode')){
							// 	showPage = "Registration";
							// }
							// var id = window.utils.htmlEscape(window.guidGenerator());
							// var options = { viewId: id, registerPage : false};
							// window.getAndCreateModalDialog(false, id, UserMixin, null, showPage, null, options);
						}
					}
				}
		})
	},
};
/*window.showTemplates = function() {
	var id = window.utils.htmlEscape(window.guidGenerator());
	var options = { viewId: id };
	var legalEntityDetails = DataManager.getDataManager().get("legalEntityDetails");
	if (legalEntityDetails) {
		DataManager.getDataManager().get('router').navigate("adminviews/" + "Template", { trigger: true });
	} else {
		alert("User not in any Tenant or not registered.")
	}
	
}*/

window.printMetadata = function() {
	var id = window.utils.htmlEscape(window.guidGenerator());
	var options = { viewId: id };
	$('.navbar-collapse').removeClass('in');
	window.getAndCreateModalDialog(false, id, UserMixin, null, "Postman", null, options);
}
window.getMetadata = function() {
	console.log(JSON.stringify(DataManager.getDataManager().getClassMetadata()));
}
/*window.getServerChanges = function(){
	DataManager.getDataManager().pullServerChanges();
}*/
window.showPostman = function() {
	var id = window.utils.htmlEscape(window.guidGenerator());
	var options = { viewId: id };
	$('.navbar-collapse').removeClass('in');
	window.getAndCreateModalDialog(false, id, UserMixin, null, "Postman", null, options);
}
window.showAdminMarketPlace = function() {
	/*if(!DataManager.getDataManager().isEnterpriseEdition()){
		window.utils.limitExceeded();
	} else {*/
		var id = window.utils.htmlEscape(window.guidGenerator());
		var options = {viewId:id, 'width': window.innerWidth-window.innerWidth/5+'px'};
		window.getAndCreateModalDialog(false,window.utils.htmlEscape(id),PlanMixin,null,"ImportFromCloud2",null,options);
	//}
}
window.toggleEnableStrategyplanner2 = function(){
	var dataManager = DataManager.getDataManager();
	var toggleRouter = dataManager.get("toggleRouter");
	toggleRouter.setFeature("strategyPlanner2Enabled",!toggleRouter.featureIsEnabled("strategyPlanner2Enabled"));
	window.setLocalStorage("featureConfig",JSON.stringify(toggleRouter.getFeatures()));
	window.location.reload();
}

/*window.toggleAutoSave = function() {
	var autoSave = !DataManager.getDataManager().get("autoSave");
	DataManager.getDataManager().set("autoSave",autoSave);
	window.setLocalStorage("autoSave",autoSave);
	if(DataManager.getDataManager().get("autoSave")){
		bootbox.alert("You have switched to Normal Mode");
	}else{
		bootbox.alert("You have switched off AutoSave Mode");
	}
}*/

window.showChangeSets = function() {
	var id = window.utils.htmlEscape(window.guidGenerator());
	var options = { viewId: id };
	DataManager.getDataManager().get('router').navigate("adminviews/" + "History" , { trigger: true });
}
window.showMarket = function() {
	var id = window.utils.htmlEscape(window.guidGenerator());
	var options = { viewId: id };
	DataManager.getDataManager().get('router').navigate("adminviews/" + "Market" , { trigger: true });
}
/*window.importWorkspace = function(){
	DataManager.getDataManager().importWorkspace();
}*/
window.migrateDB = function(){
	DataManager.getDataManager().get('vmpServerService').getAllPromise("/vdmbee/invitation/dbmigrate").then(function (data) {
		bootbox.alert("Migration Completed"+data);
	});
}
window.showAdminMenus = function () {
	var dataManager = DataManager.getDataManager();
	var legalEntityDetails = dataManager.get("legalEntityDetails");
	var currentLegalEntity = dataManager.get("currentLegalEntity");
    if(!currentLegalEntity || !legalEntityDetails[currentLegalEntity.entityId]){
        return;
    }
	var roles = legalEntityDetails[currentLegalEntity.entityId].roles;
	//console.log("roles" + roles);
	//dataManager.set("serverRoles", roles);
	dataManager.clearContextMenus();
	var settingsNode = $('#navbarlist');
	$("#showPartners").remove();
	$("#showCustomerLogs").remove();
	//$("#showInvitations").remove();
	//$("#showProducts").remove();
	var settingsNodeAdded = {};
	//$('#products').hide();
	function navigatePage(event) {
		var dataManager = DataManager.getDataManager();
		var currentLegalEntity = dataManager.get("currentLegalEntity");	
		var type = currentLegalEntity?.type === "PARTNER" ? "partner" : "customer";
		const roles =  dataManager.get("serverRoles");
		var id = (roles.length === 1 && roles.includes("REVIEWER")) ? '' : currentLegalEntity?.entityId;
		var menuId = event.currentTarget.id;
		// var page = {'showPartners':'Partner','showAudit':'Audit','showCustomerLogs':'Support','showInvitations':'Invitation','showProducts':'Product'}
		var page = {'showCustomerLogs':'Support'};
		var reactPage = {'showManagement':'partner/'+id,'showAudit':'audit','showInvitations': type+"/"+id+'/invitations','showProducts':'vproducts'}
		// if(currentLegalEntity?.type === "PARTNER") {
		// 	window.open(url+"dashboard/"+pageName, '_blank');
		// } else {
		// 	window.open(url+"dashboard/myaccount/"+id+"/subscription", '_blank');
		// }
		if(reactPage[menuId]) dataManager.navigateToCrmPage(reactPage[menuId]);
		else {
			var id = window.utils.htmlEscape(window.guidGenerator());
			var options = { viewId: id };
			DataManager.getDataManager().get('router').navigate("adminviews/" + page[menuId] , { trigger: true });
		}
	};
		
	for (var i = 0; i < roles.length; i++) {
		var roleType = roles[i];
		dataManager.addContextMenu("", "Market",showMarket , $('#navbarlist'));
		if (roleType === "VMP_ADMIN" || roleType === "VMP_USER") {
			//if(dataManager.get("REACT_APP_apiConfig_webApi").includes('localhost')){
				var toggleRouter = dataManager.get("toggleRouter");
				var sp2Enabled = toggleRouter.featureIsEnabled("strategyPlanner2Enabled");
				dataManager.addContextMenu("", "SP2 Enabled " + " " + (sp2Enabled ? sp2Enabled : false) + " Toggle", toggleEnableStrategyplanner2, $('#navbarlist'));
			//}
			//dataManager.addContextMenu("", "Audit", navigatePage, settingsNode);
			dataManager.addContextMenu("", "CustomerLogs", navigatePage, settingsNode);
			//dataManager.addContextMenu("", "Invitations", navigatePage, settingsNode);
			//dataManager.addContextMenu("", "Products", navigatePage, settingsNode);
			dataManager.addContextMenu("", "Management", navigatePage, settingsNode);
			dataManager.addContextMenu("", "History",showChangeSets , $('#navbarlist'));
			dataManager.addContextMenu("", "AdminMarket", showAdminMarketPlace, $('#navbarlist'));
			
			//dataManager.addContextMenu("", "Purge",purgeWorkspace , $('#navbarlist'));
			//$('#products').show();
			break;
		} else if (roleType === "LEGAL_ENTITY_ADMIN" || roleType === "CONTRACT_ADMIN" || roleType === "CONTRIBUTOR" || roleType === "REVIEWER") {
			if (roleType === "LEGAL_ENTITY_ADMIN") dataManager.addContextMenu("", "History",showChangeSets , $('#navbarlist'));
			//dataManager.addContextMenu("", "Toggle Auto Save",toggleAutoSave , $('#navbarlist'));
			//dataManager.addContextMenu("", "Purge",purgeWorkspace , $('#navbarlist'));
			/*if(!settingsNodeAdded["Invitations"]) {
				dataManager.addContextMenu("", "Invitations", navigatePage, settingsNode);
				settingsNodeAdded["Invitations"] = {};
			}*/
			//dataManager.addContextMenu("", "Purchased", showAdminMarketPlace, $('#navbarlist'));
			var currentLegalEntity = dataManager.get("currentLegalEntity");
			if (dataManager.get("currentLegalEntity").type.toUpperCase().indexOf('Customer'.toUpperCase()) !== -1 && !settingsNodeAdded["Management"]) {
				dataManager.addContextMenu("", "Management", showMyCustomers.bind(legalEntityDetails[currentLegalEntity.entityId]), settingsNode,'myAccount');
				settingsNodeAdded["Management"] = {};
			} else if ((currentLegalEntity.type.toUpperCase().indexOf('Partner'.toUpperCase()) !== -1 && !settingsNodeAdded["Management"]) && (currentLegalEntity.entityId != window.university && currentLegalEntity.entityId != window.vdmbee)) {
				dataManager.addContextMenu("", "Management", showMyPartners.bind(legalEntityDetails[currentLegalEntity.entityId]), settingsNode, 'myAccount');
				settingsNodeAdded["Management"] = {};
			} else {
				if(!settingsNodeAdded["Management"]) {
					dataManager.addContextMenu("", "Management", navigatePage, settingsNode);
					settingsNodeAdded["Management"] = {};
				}
			}
			//$('#products').show();
		}

		/*else {
			if(!settingsNodeAdded["Invitations"]) {
				dataManager.addContextMenu("", "Invitations", navigatePage, settingsNode);
				settingsNodeAdded["Invitations"] = {};
			}
		}*/
	}

	if (dataManager.get('debugMode')) {
		//dataManager.addContextMenu("", "Templates", showTemplates, $('#navbarlist'));
		dataManager.addContextMenu("", "Postman", showPostman, $('#navbarlist'));
		//dataManager.addContextMenu("", "Toggle Auto Save",toggleAutoSave , $('#navbarlist'));
		//dataManager.addContextMenu("", "importWorkspace",importWorkspace , $('#navbarlist'));
		dataManager.addContextMenu("", "getMetadata",getMetadata , $('#navbarlist'));
		//dataManager.addContextMenu("", "getServerChanges",getServerChanges , $('#navbarlist'));
	}
	//dataManager.addContextMenu("", "migrateDB",migrateDB , $('#navbarlist'));
}
window.showMyPartners = function () {
	var id = window.utils.htmlEscape(window.guidGenerator());
	$('.navbar-collapse').removeClass('in');
	var options = { viewId: id, partId: this.entityId, legalEntityName: this.name, myCustomers: true };
	var dataManager = DataManager.getDataManager();
	var currentLegalEntity = dataManager.get("currentLegalEntity");	
	const roles =  dataManager.get("serverRoles");
	var leId = (roles.length === 1 && roles.includes("REVIEWER")) ? '' : currentLegalEntity?.entityId;
	dataManager.navigateToCrmPage("partner/"+leId);
	// DataManager.getDataManager().get('router').navigate("adminviews/" + "Partner/null/" + options.viewId + "/" + options.legalEntityName + "/" + options.partId + "/" + options.myCustomers, { trigger: true });
}
window.showMyCustomers = function () {
	var id = window.utils.htmlEscape(window.guidGenerator());
	$('.navbar-collapse').removeClass('in');
	if(!this.parentEntityName){
		bootbox.alert("Cannot Proceed. Not a User");
		return;
	}
	var options = { viewId: id, partId: this.parentEntityId, custId: this.entityId, legalEntityName: this.parentEntityName, partnerInvoice: this.invoiceToPartner, myCustomers: true };
	var dataManager = DataManager.getDataManager();
	var currentLegalEntity = dataManager.get("currentLegalEntity");
	var leId = currentLegalEntity?.entityId;
	dataManager.navigateToCrmPage("myaccount/"+leId);
	// DataManager.getDataManager().get('router').navigate("adminviews/" + "Customers/null/" + options.viewId + "/" + options.partId + "/" + options.legalEntityName + "/" + options.custId + "/" + options.partnerInvoice + "/" + options.myCustomers, { trigger: true });
}
window.showSettings = function (callback) {
	var dataManager = DataManager.getDataManager();
	dataManager.clearContextMenus();
	if (dataManager.get('vmpServerService') && !window.rolesFetched) {
		/*getLocalStorage("myRoles", function (roles) {
			if (roles && typeof roles === 'object') {
				roles = roles.myRoles;
			}
			if (roles && typeof roles === 'string') {
				roles = JSON.parse(roles);
			} 
			if (roles) {
				if (roles._embedded) {
					showEntityList(roles);
					showAdminMenus();
					if(DataManager.getDataManager().get("currentLegalEntity")) {
						setProductLicence(DataManager.getDataManager().get("currentLegalEntity").entityId);
					}
				} else {
					removeLocalStorage("myRoles");
				}
			} else {*/
				dataManager.get('vmpServerService').getAllPromise("/vdmbee/role/myroles").then(function (data) {
					if (data._embedded) {
						dataManager.clearSaveInterval();
						setLocalStorage("myRoles", JSON.stringify(data));
						showEntityList(data);
						showAdminMenus();
						window.rolesFetched = true;
						/*if(dataManager.get("currentLegalEntity")) {
							setProductLicence(dataManager.get("currentLegalEntity").entityId);
						}*/
						if(dataManager.get('products').length == 0 && dataManager.get('currentLegalEntity').entityId == window.vdmbee){
							$.get("locale/products.json",function(products){
								dataManager.set("products",products.subscriptions);
								window.legalEntitySelectionChange();
								dataManager.onLicenseUpdate(products.subscriptions);
								dataManager.getWorkspacesFromBackend(function(){
									//dataManager.getGroupsFromBackend(function(){
										//window.legalEntitySelectionChange();
										if(callback){
											callback(true);
										}
									//});
								});
							});
						} else {
							dataManager.getWorkspacesFromBackend(function(){
								//dataManager.getGroupsFromBackend(function(){
									window.legalEntitySelectionChange();
									if(callback){
										callback(true);
									}
								//});
							});
						}
					}else {
						//$('#products').hide();						
						if(callback){
							callback(true);
						}
					}
				});
			//}
		//});
	} else {
		showAdminMenus();
		/*getLocalStorage("myRoles", function (roles) {
			var roles = roles ? JSON.parse(roles) : null;
			if (roles && roles._embedded) {
				showEntityList(roles);
				showAdminMenus();
				if(DataManager.getDataManager().get("currentLegalEntity")) {
					setProductLicence(DataManager.getDataManager().get("currentLegalEntity").entityId);
				}
			}else {
				$('#products').hide();
			}
		});*/
		if(callback){
			callback(false);
		}
	}
};
window.showEntityList = function(data) {
	var legalEntityDetails = {};
	var roles = [];
	var rolesData = data._embedded.role;
	for (var i = 0; i < rolesData.length; i++) {
		if (!legalEntityDetails[rolesData[i].entityId]) {
			legalEntityDetails[rolesData[i].entityId] = {};
			legalEntityDetails[rolesData[i].entityId].userName = rolesData[i].name;
			legalEntityDetails[rolesData[i].entityId].entityId = rolesData[i].entityId;
			legalEntityDetails[rolesData[i].entityId].name = rolesData[i].entityName;
			legalEntityDetails[rolesData[i].entityId].type = rolesData[i].entityType;
			if (rolesData[i].parentEntityId) {
				legalEntityDetails[rolesData[i].entityId].parentEntityId = rolesData[i].parentEntityId;
				legalEntityDetails[rolesData[i].entityId].parentEntityName = rolesData[i].parentEntityName;
				legalEntityDetails[rolesData[i].entityId].invoiceToPartner = rolesData[i].invoiceToPartner;
			}
			legalEntityDetails[rolesData[i].entityId].roles = [];
		}
		if (!_.contains(legalEntityDetails[rolesData[i].entityId].roles, rolesData[i].type)) {
			legalEntityDetails[rolesData[i].entityId].roles.push(rolesData[i].type);
		}
	}
	DataManager.getDataManager().set("legalEntityDetails", legalEntityDetails);
	var selectLegalEntityNode = jQuery("#legalEntitySelection");
	selectLegalEntityNode.empty();
	window.fillLegalEntityNode(legalEntityDetails);
}
window.fillLegalEntityNode = function(legalEntityDetails){
	var legalEntityIds = Object.getOwnPropertyNames(legalEntityDetails);
	var selectLegalEntityNode = jQuery("#legalEntitySelection");
	if (legalEntityIds.length > 0) {
		var currentLegalEntity = DataManager.getDataManager().get("currentLegalEntity");
		if (!currentLegalEntity || !currentLegalEntity.entityId) {
			DataManager.getDataManager().set("currentLegalEntity", legalEntityDetails[legalEntityIds[0]]);
			currentLegalEntity = legalEntityDetails[legalEntityIds[0]];
		}
		for (var i = 0; i < legalEntityIds.length; i++) {
			var optionNode = $("<option " + "value=\"" + legalEntityDetails[legalEntityIds[i]].entityId + "\">" + legalEntityDetails[legalEntityIds[i]].name + "</option>");
			if (currentLegalEntity.entityId == legalEntityIds[i]) {
				optionNode.attr("selected", true);
			}
			selectLegalEntityNode.append(optionNode);
			$('#legalEntitySelection').prop('disabled', true);
		}
	}
}
window.fetchNotifications = function(callback){
	$.getJSON("locale/Notifications.json", function(result){
				return callback(result);
	});
	/*var hostBaseUrl = DataManager.getDataManager().get('vmpServerHost');
	utils.httpRequest("GET", hostBaseUrl + "/wp-content/vmp-library/news/Notifications.json",function(response){
		var configObj = null;
		if(response) {
			var fileObject = response;
			configObj = JSON.parse(fileObject);	
		}
		callback(configObj);
	});*/
}
window.showNotification = function(){
	var showNewFeature = UserPreferences.getUserPreference(window.plansKey,'NewFeatures','NewFeatures');	
	//if(showNewFeature === null && DataManager.getDataManager().get('email')){//offline don't show
		fetchNotifications(function(result){
			if(result) {
                $(".unreadCountValue").remove();
				$(".unreadCount").append("<span class=\"unreadCountValue\">"+result.Updates.length+"</span>");
			}
		});
	//}
	if(showNewFeature!==0){
		$('#starIcon').show();	
		if(showNewFeature!==null){
            $(".unreadCountValue").remove();
			//$(".unreadCount").append("<span class=\"unreadCountValue\">"+showNewFeature+"</span>");
		}
		$('#starIcon').click(function(){
			$.get("js/app/com/vbee/filesystem/views/Notification.html", function(NewNotifications){
				var NotificationBox = bootbox.dialog({
					message: NewNotifications,
					className: "newnotifications",
					onEscape:function(){
							
					}
				});
				fetchNotifications(function(result){
					if(result){
						UserPreferences.setUserPreference(window.plansKey,'NewFeatures','NewFeatures',1);
						$(".unreadCountValue").remove();
						/*for(var i=0;i<result.Updates.length;i++ ){	 
							   var unread = UserPreferences.getUserPreference(window.plansKey,'NewFeatures',result.Updates[i].name);
							   if(unread===null){
								   $("#notificationid").append(result.Updates[i].info);
								   continue;
							   }			           				    
						}*/
						for(var j=0;j<result.Updates.length;j++ ){	 
							   //var read = UserPreferences.getUserPreference(window.plansKey,'NewFeatures',result.Updates[j].name);
							   //if(read===result.Updates[j].id){
								   $("#notificationid").append(result.Updates[j].info);
								   var divid="#"+result.Updates[j].id;
								   $(divid).removeAttr("style");
								   //continue;
							   //}     
						}
							
						/*$('a.read_more').click(function(event){
							var count=result.Updates.length;
							 event.preventDefault();
							 $(event.currentTarget).parent().find('.more_text').removeClass('hide');
							 $(event.currentTarget).hide();	        					        					
							 $(event.currentTarget).parent().find('.read_less').removeClass('hide');
							 $(event.currentTarget).parent('h4').parent('div').removeAttr("style");
							var id = $(event.currentTarget).parent('h4').parent('div').attr('id');
							var className = "" //$(event.currentTarget).parent('h4').parent('div').attr('class');
							for (var j = 0; j < result.Updates.length; j++) {
								if (result.Updates[j].id == id) {
									className = result.Updates[j].name;
									break;
								}
							}
							 UserPreferences.setUserPreference(window.plansKey,'NewFeatures',className,id);	
							 for(var j=0;j<result.Updates.length;j++ ){
								 var readNotifications = UserPreferences.getUserPreference(window.plansKey,'NewFeatures',result.Updates[j].name);
								 if(readNotifications===result.Updates[j].id){
									   count=count-1;
									   UserPreferences.setUserPreference(window.plansKey,'NewFeatures','NewFeatures',count);
								   }else{
									   UserPreferences.setUserPreference(window.plansKey,'NewFeatures','NewFeatures',count);
								   }
							   }
							   var showNewNotification = UserPreferences.getUserPreference(window.plansKey,'NewFeatures','NewFeatures');	
							if(showNewNotification>0){		            				
								$(".unreadCountValue").remove();
								$(".unreadCount").append("<span class=\"unreadCountValue\">"+showNewNotification+"</span>");	
							}else if(showNewNotification===0){
								$(".unreadCountValue").addClass('hide');
								$('#starIcon').hide();
							}
						 });
						$('a.read_less').click(function(event){
							 event.preventDefault();
							 $(event.currentTarget).parent().find('.more_text').addClass('hide');	        					
							 $(event.currentTarget).addClass('hide');
							 $(event.currentTarget).parent().find('.read_more').show();
						});*/
					}
				});	        	     	            								    	
			});					
		});
	}
}
window.recheckConnection = function() {
	/*var roles = DataManager.getDataManager().get('serverRoles');
	if(roles && roles.length == 1 && roles.indexOf('REVIEWER') != -1){
		bootbox.alert('Your Role has Read Access Only');
	} else {*/
		bootbox.confirm('There is a Network Connection issue. Do you want to reconnect again?', function(result) { 
			if(result){
                window.utils.startSpinner("connectionSpinner" ,"Reconnecting, please wait...");
				window.checkConnection(0);
			}
		});
	//}
}
window.checkConnection = function(counter){
   utils.httpRequest("GET", DataManager.getDataManager().get('vmpServerHost') + '/version', function(online) {
       if (online) {
           window.utils.stopSpinner("connectionSpinner");
           window.setLocalStorage("server_status", 1);
           DataManager.getDataManager().set('offLineMode', false);
           DataManager.getDataManager().releaseSaveLock();
           /*if(DataManager.getDataManager().get("readMode")){
           	window.toggleAutoSave();
           	bootbox.hideAll();
           	window.location.reload();
           }*/
       } else {
           if (counter == 20) {
               window.utils.stopSpinner("connectionSpinner");
               bootbox.alert('Unable to connect');
           } else {
               window.setTimeout(function(){
                   counter++;
                   window.checkConnection(counter);
               }, 200);
           }
       }
   });
}
window.logout = function() {
	var dataManager = DataManager.getDataManager();
		dataManager.clearContextMenus();
		//$('#products').hide();
		window.utils.startSpinner('logout','Signing out...');
		window.setTimeout(function(){
			var serverChangeSet = dataManager.get(dataManager.CURRENT_CHANGESET);
			dataManager.saveChangeSetToBackend(serverChangeSet,function(){
				dataManager.set('email', null);
				dataManager.set('displayName', null);
				dataManager.set('accessToken', null); 
				dataManager.set("serverRoles", null);
				Workspace.logoutWindows(function(){
					DataManager.getDataManager().get('router').navigate("/", { trigger: true });
					$('#userFullName').html('Unknown');
					$('#loginHere').remove();
					$('<a style="cursor: pointer;" id="loginHere">Sign In/Sign Up</a>').insertAfter('#userMailId')
					$('#loginHere').on('click',function(){
						self.showRegistration(true);
					})
					dataManager.set('appCache', {});
					DataManager.getDataManager().set("legalEntityDetails",null);
					DataManager.getDataManager().set("currentLegalEntity",null);
					window.utils.stopSpinner('logout');   
				});
			});
		},100);      
}
window.showRegistration = function(data){
	var registerPage = true;
	var dataManager = DataManager.getDataManager();
	var id = window.utils.htmlEscape(window.guidGenerator());
	if(data == true || !dataManager.get('email')){
		registerPage = false;
	}
	var options = { viewId: id, registerPage : registerPage};
	$('.navbar-collapse').removeClass('in');
	/*import('./app/version1/bo/tickets/UserMixin').then(({default: UserMixin}) => {
		window.getAndCreateModalDialog(false, id, UserMixin, null, "Registration", null, options);
	});*/
	import('./app/version1/bo/tickets/UserMixin').then(({UserMixin}) => {
		window.getAndCreateModalDialog(false, id, UserMixin, null, "Registration", null, options);
	});
}

window.showLoginOption = function(callback){
	var dataManager = DataManager.getDataManager();
	if (!dataManager.get('email')) {
		//dataManager.addContextBarMenu("","login",$.proxy(login,this),"Login/Register");
		//$('#products').hide();
		if(callback){
			callback()
		}
	}
	else {
		/*if($('#navbarlist').find("li").is("[title='login']")){
			$("#login").remove();
		}*/
		this.showSettings(callback);
	}
};
window.getLocalStoragePersistor = function (callback) {
	var dataManager = DataManager.getDataManager();
	if (dataManager.get('userCache')) {
		if (callback) {
			callback(dataManager.get('userCache'));
		}
		return dataManager.get('userCache');
	}
	new Lawnchair({ name: "UserCache", adapter: DataManager.getLanwnchairAdapters() }, function () {
		this.projectId = window.plansKey;
		dataManager.workspaceStore = this;
		dataManager.set('userCache', this);
		if (callback) {
			callback(this);
		}
	});
};
window.getLocalStorage = function (valueName, callback) {
	var dataManager = DataManager.getDataManager();
	var cacheVal = dataManager.get('appCache')[valueName];
	if (cacheVal) {
		callback(cacheVal);
		return;
	}
	if (!window.utils.checkChrome()) {
		var val = localStorage.getItem(valueName);
		callback(val);
	} else {
		if (dataManager.get('appCache') && dataManager.get('appCache')[valueName]) {
			callback(dataManager.get('appCache')[valueName]);
		} else {
			window.getLocalStoragePersistor(function (store) {
				store.get(valueName, function (val) {
					if(val){
						callback(val.value);
					}else{
						callback(null);
					}
				});
			});
		}
	}
};
window.setLocalStorage = function (name, value) {
	var dataManager = DataManager.getDataManager();
	if (!window.utils.checkChrome()) {
		localStorage.setItem(name, value);
	} else {
		if (dataManager.get('appCache')) {
			dataManager.get('appCache')[name] = value;
		} 
		window.getLocalStoragePersistor(function (store) {
			store.save({ key: name, 'value': value });
		});
	}
	dataManager.get('appCache')[name] = value;
};
window.removeLocalStorage = function (name) {
	var dataManager = DataManager.getDataManager();
	if (!window.utils.checkChrome()) {
		localStorage.removeItem(name);
	} else {
		if (dataManager.get('appCache')) {
			delete dataManager.get('appCache')[name];
		} 
		window.getLocalStoragePersistor(function (store) {
			store.remove({ key: name });
		});
	}
};
window.showEndAgreement = function(callback){
	var endAgreement = UserPreferences.getUserPreference(window.plansKey,'end','end');
	if(!endAgreement) {
		$.get("js/app/com/vbee/filesystem/views/EndUserAgreement.html", function (EndUserAgreement) {
			bootbox.dialog({closeButton: false, message: EndUserAgreement, className: "aggrementModal"});
			document.getElementById('aggrementCheckBox').onclick = function(view,event) {
				UserPreferences.setUserPreference(window.plansKey,'end','end',true);
				$('.aggrementModal').modal('hide');
				callback();
			};
		});	
	} else {
		callback();
	}
}
window.getSavedPreference = function (modelId, filterVPId, chartType) {
    var email = DataManager.getDataManager().get('email');
    if (!email || email === "") {
        return null;
    }
    var repId = filterVPId ? filterVPId : modelId;
    var rep = DataManager.getDataManager().getRepositoryId(repId);

    var valueStr = UserPreferences.getUserPreference(rep, chartType, repId);
    if (valueStr) {
        return JSON.parse(valueStr);
    }
    return null;
};

window.showOpeningPage = function(show){
	//let UserPreferences = await import('./app/version1/bo/preference/UserPreferences');
	import('./app/version1/bo/preference/UserPreferences').then(({UserPreferences}) => {		
		var showOpPage = UserPreferences.getUserPreference(window.plansKey,'StartUpPage','StartUpPage');
		if(DataManager.getDataManager().get('debugMode') && !show){
			return;
		}
		if(jQuery("#startUpCheckBox").length != 0){
			return;
		}
		if(!showOpPage || show){
			$.get("js/app/com/vbee/filesystem/views/StartupPage.html", function (StartupPage) {
				bootbox.dialog({
					message: StartupPage,className: "introductionModal",
					onEscape:function(){
						$("#introduction-carousel").carousel('pause');
						$("#introduction-carousel").removeClass('slide');
					}
				});
				if(showOpPage){
					$('#startUpCheckBox').attr({checked:true});
					var currentYear = new Date().getFullYear();
					$('#year').text(currentYear);
				}
				document.getElementById('startUpCheckBox').onclick = function() {
					UserPreferences.setUserPreference(window.plansKey,'StartUpPage','StartUpPage',this.checked);
				};
				$("#introduction-carousel").carousel({interval: 5000});
			});
		}
	});
}

window.closeUrlDialog = function (dialogWindow) {
	if (!window.utils.checkChrome()) {
		var parent = $("#webview").parent();
		$("#webview").remove();
	} else if (dialogWindow) {
		bootbox.hideAll();
		var webview = dialogWindow.find('#webview');
		if(webview && webview.length > 0){
			webview.parent().remove();
		}
	}	
};
window.showUrlDialog = function (dataUrl, dialogTitle, onStartLoading, onStopLoading, onLoadRedirect, onClose) {
		$.get("js/app/com/vbee/filesystem/views/ExternalPageDialog.html", function (ExternalPage) {
			var customClass = "resizeBox";
			if(dialogTitle == "Server Access Token") {
				customClass = "hide";
			}
			var dialogWindow = bootbox.dialog({
				message: ExternalPage,show: false,className: customClass
			});
			dialogWindow.modal('show');
			var parent = $("#webview").parent();
			$("#webview").remove();
			parent.append("<iframe id='webview' target='_parent' src='' style='width:100%;height:100%' ></iframe>");		
			
			$(".dialogTitle").text(dialogTitle);
			var webView = document.getElementById("webview");
			var indicator = document.querySelector(".indicator");
			var loadstart = function () {
				indicator.innerText = "loading...";
				if (onStartLoading) {
					onStartLoading(arguments);
				}
			}
			var loadstop = function () {
				indicator.innerText = "";
				if (onStopLoading) {
					onStopLoading(arguments,dialogWindow);
				}
			}

			webView.addEventListener("loadstart", loadstart);
			webView.addEventListener("loadstop", loadstop);
			if (onLoadRedirect) {
				webView.addEventListener("loadredirect", function () {
					onLoadRedirect(arguments, dialogWindow);
				});
			}
			
			webView.addEventListener('close', function () {
				dialogWindow.modal('hide');
				if (onClose) {
					onClose(arguments);
				}
			});
			
			$("#webview").attr('src', dataUrl);
			$(".ExternalPage .modal-content").draggable({
				handle: ".regproductheader"
			});
			$(".ExternalPage .modal-content").resizable({
				resize: function (event, ui) {
					var width = $(event.target).css('width');
					var height = $(event.target).css('height');
					var headerHeight = $('#regproductHeader').css('height');
					//38 for left and righ space. 62 for space between header and body.
					$('#regproductbody').css('width', parseFloat(width) - 38);
					$('#regproductbody').css('height', parseFloat(height) - parseFloat(headerHeight) - 62);
				}
			});
		});
	//}
}
/* window.updateIframe = function() {
	var h = $(window).height();
	$("iframe").height(h - 145);
}; */

window.onerror = function (errorMsg, url, lineNumber, column, errorObj) {
	var aKey = 65, cKey = 67; //keyCode for a - 65 and keyCode for c - 67
	DataManager.getDataManager().set("errorOccured", true);
	window.autoProcess = false;
	var spinnerDiv = $('#SpinnerDiv');
	var isSpinnerInProgress = spinnerDiv.attr('spinnerProgress');
	var stack = '';
	if(errorMsg && errorMsg.indexOf && errorMsg.indexOf("Uncaught SyntaxError:") != -1){
		window.utils.stopSpinner(spinnerDiv.prop("spinnerId"));
		var msg = "Your Plan is corrupted. Please cleanup your cookies";
		if(errorMsg.indexOf("Uncaught SyntaxError: Unexpected token e in JSON") != -1){
			msg = "Your Workspace is corrupted. Please cleanup your cookies";
		}
		bootbox.alert(msg);
		return;
	}
	if (errorObj) {
		stack = errorObj.stack ? (errorObj.stack.replace(/^[^\(]+?[\n$]/gm, '')
			.replace(/^\s+at\s+/gm, '')
			.replace(/^Object.<anonymous>\s*\(/gm, '{anonymous}()@')
			.split('\n')) : errorObj;
	} else if(column == "0" || ((typeof column == 'object') && !column.includes('DataTables'))){
		window.utils.stopSpinner(spinnerDiv.prop("spinnerId"));
		bootbox.alert("Your plan is corrupted. Please delete and re-import the plan");
		return;
	}

	if (isSpinnerInProgress) {
		var errorDetailsDiv = "<div  style=\"display:none\" class=\"restartDialog\">\
				<br>\<div contenteditable id=\"errorStack\" style=\"font-family: Roboto;overflow-x:scroll\">"+ 'Error: ' + errorMsg + ' Script: ' + url + '<br><b> Line: </b>' + lineNumber + '<br> <b>Column: </b>' + column + '<br><b> StackTrace: </b>' + stack + "</div></div>";
		var params = {email:DataManager.getDataManager().get('email'),createdDate1:new Date(),status:false,errorMessage:errorMsg};
		var stackArr = "";
		for (var i = 0; i < stack.length; i++) {
			stackArr += stack[i] + ', ';
		}
		params.stackTraceInfo = stackArr;
		params.lineNumber = lineNumber.toString();
		params.columnNumber = column.toString();
		params.scriptUrl = DataManager.getDataManager().getBreadCrumbActivity() + "-" + window.utils.getAppVersion();
		if(!DataManager.getDataManager().get('debugMode')){
			utils.httpRequest("POST", DataManager.getDataManager().get('vmpServerHost') + '/invitation/errorloghistory',function(response){
				/*if (response === false || (response.includes && !response.includes('apierror'))) {
					bootbox.alert('Thank you for your feedback.');
				}*/
			},params);
		}
		var appError = bootbox.dialog({
			message: "<b>Application encountered an error. Do you want to restart the App? </b>" + errorDetailsDiv,
			title: "App Restart Confirmation Dialog",
			closeButton: false,
			buttons: {
				details: {
					label: "Error Details",
					className: "",
					callback: function () {
						$(".restartDialog").toggle("slow", "swing");
						$("#errorStack").keydown(function (e) {
							if ((e.ctrlKey && (e.keyCode == aKey || e.keyCode == cKey))) {
								return true;
							} else {
								return false;
							}
						});
						return false;
					}
				},
				/*noclose: {
					label: "Send to Support",
					className: "btn btn-default",
					callback: function () {
						
						return false;
					}                        
				},	*/			
				primary: {
					label: "Reload",
					className: "btn btn-primary",
					callback: function (event) {
						//DataManager.getDataManager().get('router').navigate("/", { trigger: true })
						//var win = null;//require('electron').getGlobal('win');
						//DataManager.getDataManager().resetWorkspaceChangeset(true);
						if (DataManager.getDataManager().get("repositorySaver")) {
							DataManager.getDataManager().get("repositorySaver").terminate();
						}
						bootbox.hideAll();
						window.location.reload();
						//window.open(event.target.href, '_blank');
						//window.top.close();
					}
				},
				home: {
					label: "Home",
					className: "btn btn-default",
					callback: function (event) {
						//DataManager.getDataManager().get('router').navigate("/", { trigger: true })
						//var win = null;//require('electron').getGlobal('win');
						//DataManager.getDataManager().resetWorkspaceChangeset(true);
						if (DataManager.getDataManager().get("repositorySaver")) {
							DataManager.getDataManager().get("repositorySaver").terminate();
						}
						bootbox.hideAll();
						window.utils.navigatePage(true);
						window.location.reload();
						//window.open(event.target.href, '_blank');
						//window.top.close();
					}
				}
			}
		});
		window.utils.stopSpinner(spinnerDiv.prop("spinnerId"));
	} else {
		bootbox.alert('Error: ' + errorMsg + ' Script: ' + url + ' Line: ' + lineNumber
			+ ' Column: ' + column + ' StackTrace: ' + errorObj);
	}
	//window.utils.enableMouseEvents();
};

window.getAndCreateModalDialog = function(parentView,id,view,model,type,dialogCallback,options,oldData,callback){
	var time = new Date();
	if(window.click && (time.getTime() - window.click) < 1000){
		return;
	}
	window.click = time.getTime();
	var width ="600px" ;
	var height = "auto";
	if(type == "AggregationView"){
		height = window.innerHeight-window.innerHeight/5+'px';
		width = window.innerWidth-window.innerWidth/5+'px';
	}
	if(options && options.width){
		width = options.width;
	}
	if(options && options.height){
		height = options.height;
	}
	if (!id) {
		id = window.utils.htmlEscape(window.guidGenerator());
	}
	var viewProperties = view.getDialogViewProperties(type,model);
	if(viewProperties && document.getElementById(viewProperties.tabId+id) && ko.dataFor(document.getElementById(viewProperties.tabId+id))){
		return;
	}
	var dialogParentId = "newModal";
	if (document.fullscreenElement) {
		dialogParentId = document.fullscreenElement.id;
	}
	$("#"+dialogParentId).append('<div id="modal'+id+'" class="modal fade" data-keyboard="false" data-backdrop="static"><div class="modal-dialog" style= "bottom: 3%; width:' + width + '; height:' + height + '"><div class="modal-content" id="editDetailsModalContent'+id+'"></div></div></div></div>');
	options = $.extend({},{'parentView':parentView,'modalId':id,'callback':dialogCallback,'addOptions':options},options);
	var modalContentNode = $('#editDetailsModalContent'+id);
	var version = /*model?model.getNestedParent().getVersion() :*/"1";
	var newtemplatePath = "js/app/version" + version + "/" + viewProperties.templatePath;
	var appContentNode = $("<div></div>");
	appContentNode.attr("id",viewProperties.tabId+id);
	modalContentNode.append(appContentNode);
	modalContentNode.css("overflow", "hidden"); 
	function createModal(viewModel,$,kb){
		if(window.isDialogBinding){
			return;
		}
		window.isDialogBinding = true;
		function bindTemplate(templates){
			appContentNode.append('<div style=\"display:none\">' + templates + '<\/div>');
			var bindNode = $("<div data-bind=\"template: { name:\'" + viewProperties.templateName + "\', data: " + viewProperties.tabId +  "Model , afterRender: afterRenderView}\"></div>");
			appContentNode.append(bindNode);
			var viewInstance = viewModel.getInstance(model,options);
			if(oldData){
				this.updateView(viewInstance,oldData);
			}
			kb.applyBindings(viewInstance, document.getElementById(viewProperties.tabId+id));
			window.isDialogBinding = false;
			var setHeight = false;
			$('#editDetailsModalContent'+id).on("resize", function(event, ui) {// setting min height of the template, to prevent buttons and content from overflowing
				if(!setHeight){
					ui.element.css("minHeight",$("#editDetailsModalContent"+id).actual('outerHeight'));
					ui.element.css("minWidth",$("#editDetailsModalContent"+id).actual('outerWidth'));
					setHeight = true;
				}
			});
			var containmentDiv = "document"
			/*if($("#content").height() > window.innerHeight/2){
				debugger
				containmentDiv = "#content"
			}*/
			$("#editDetailsModalContent"+id).draggable({
				handle: ".modal-header",
				cursor: "crosshair",
				containment: containmentDiv
			});
			if(options && options.resizable !== false){
				if(viewInstance.custResize){
					$('#editDetailsModalContent'+id).resizable({resize:viewInstance.custResize});
					if(model && model.get('type') == "canvas_BMCanvasDiagram" && model.get('height')){
						$('#editDetailsModalContent'+id).find('.canvas-contain-wrap').height(model.get('height'));
						$('#editDetailsModalContent'+id).width(model.get('width'));
					}
				}else{
					$('#editDetailsModalContent'+id).resizable();
				}

			}
			//window.updateVdmDialogViewArray(viewInstance);
			$("#modal"+id).modal('show');
			var autoCompAttr = 'new-password';
			if(window.browser == "chrome"){
				autoCompAttr = 'off';
			}
			$('input[type="text"]').attr('autocomplete',autoCompAttr);
			$('input[type="text"]').bind("paste", function(e){
				var validTxt = window.utils.pasteHandle(e);
				if(validTxt !== null){
					e.stopPropagation();
					e.preventDefault();
					$(e.target).val(window.utils.pasteContent(e,validTxt));
					return false;
				}
			});
			if(DataManager.getDataManager().get('readMode') || window.utils.checkIsLinked(model,true)){
				window.utils.hideIconsOnReadMode();
			}
			/*var modalDialoguesOpened = $('#newModal').children().length;
			if(modalDialoguesOpened == 1){
				setTimeout(function(){$(document.documentElement).css('overflow','hidden');},50);
			}*/
			if(callback){
				callback(viewInstance);
			}
		}
		var templates = window.utils.getHtmlPage(viewProperties.templateName);
		if(!templates) {
			console.log("import "+viewProperties.templateName+" from "+newtemplatePath)
			$.get(newtemplatePath, function(templates) {
				bindTemplate(templates);
			});
		} else {
			bindTemplate(templates);
		}
	}
	var viewTypeStr = viewProperties.viewTypeStr;
	viewTypeStr = viewTypeStr.replace("appcommon","../app");
	viewTypeStr=viewTypeStr.replace("appviews","../app/version1/views");
	viewTypeStr = viewTypeStr.replace("appbo","../app/version1/bo");
	//requirejs([viewTypeStr],createView(module));
	var className = viewTypeStr.substring(viewTypeStr.lastIndexOf('/')+1)
	createModal(window.utils.getView(className),$,kb)	
	//requirejs([viewProperties.viewTypeStr],createModal);
};
ko.unapplyBindings = function ($node, remove) {
	if($node.length >0){
		// unbind events
		$node.find("*").each(function () {
			$(this).unbind();
		});
		// Remove KO subscriptions and references

		if (remove) {
			ko.removeNode($node[0]);
		} else {
			ko.cleanNode($node[0]);
		}
		$("#chartTooltip").html('');
	}
};

window.cleanViewModel= function(viewModel,$node, remove){
	if(!viewModel){
		return;
	}
	if(viewModel.cleanUp){
		viewModel.cleanUp();
	}
	var props = Object.getOwnPropertyNames(viewModel);
	_.each(props,function(prop){
		if($.isArray(viewModel[prop])){
			if(prop === 'children') {
				for(var j=0;j<viewModel[prop].length;j++) {
					if(viewModel[prop][j].view){
						window.cleanDialogModel(viewModel[prop][j].modalId,viewModel[prop][j].view);
					}
				}
			}else {
				if(viewModel[prop].length > 0 && viewModel[prop][0] instanceof kb.ViewModel){
					for(var i=0;i<viewModel[prop].length;i++){
						this.cleanViewModel(viewModel[prop][i]);
					}
				}
				viewModel[prop].length = 0;
			}
		}
		if(viewModel[prop] instanceof kb.ViewModel){
			viewModel[prop].destroy();
		}
	});
	kb.release(viewModel);
	if($node){
		ko.unapplyBindings($node, remove);
	}
	_.each(props,function(prop){
		viewModel[prop] = null;
	});
	viewModel = null;
};

window.cleanDialogModel = function(id,view){
	var modalContentNode = $('#editDetailsModalContent'+id);
	$("#modal"+id).modal('hide');
	window.cleanViewModel(view,modalContentNode,false);
	$('#modal'+id).children().remove();
	//Dont remove scroll if more than one modal dialog is opened.

	setTimeout(function(){
		var modalDialoguesOpened = $('#newModal').children().length;
		if(modalDialoguesOpened == 1){
			//$(document.documentElement).css('overflow','');
			$(document.body).removeClass('modal-open');
			/*if(window.vdmModelView && window.vdmModelView.setResizeHeight){
				window.vdmModelView.setResizeHeight();
			}*/
		}
		$('#modal'+id).remove();

	}, 300);
}

window.getViewData = function(view){
	var data = {};
	if(view){
		var viewProperties = Object.getOwnPropertyNames(view);
		_.each(viewProperties,function(propKey){
			if(ko.isObservable(view[propKey]) && !ko.isComputed(view[propKey])){
				var propVal = view[propKey]();
				data[propKey] = propVal;
			}
		});
	}
	var dataStr = JSON.stringify(data);
	return JSON.parse(dataStr);
};

window.updateView = function(viewInstance,oldData){
	if(viewInstance){
		var viewProperties = Object.getOwnPropertyNames(oldData);
		_.each(viewProperties,function(propKey){
			var propVal = oldData[propKey];
			if($.isArray(propVal)){
				viewInstance[propKey](JSON.parse(JSON.stringify(propVal)));
			}else{
				viewInstance[propKey](propVal);
			}
		});
	}
};
$("#deleteImage").on('click',function(){
	UserPreferences.setUserPreference(window.plansKey, 'userImage', 'userImage' , null);
	Workspace.getPhoto(null,"",function(){});
})
$("#loadImage").on('click', function (view, event) {
	var uri = "";
	$('#userProfileInput').click();
})

window.saveImage = function(){
	if(window.utils.checkImageSize($("#userProfileInput")[0].files[0])){
		var fileObject = $("#userProfileInput")[0].files[0].path;
		showProfileImage(fileObject);
	}
};

function showProfileImage(uri){
		var xhr = new XMLHttpRequest();
		xhr.responseType = 'blob';
		xhr.onload = function () {
			var fileReader = new FileReader();
			fileReader.onload = function (evt) {
				var result = evt.target.result;
				UserPreferences.setUserPreference(window.plansKey, 'userImage', 'userImage', result);
				Workspace.getPhoto(null,"",function(){});
			};
			fileReader.readAsDataURL(xhr.response);
		}
		xhr.open('GET', uri, true);
		xhr.send();
}

window.getAndCreateView = function(templatePath,templateName,model,viewTypeStr,tabId,tabName,callback,viewOptions){
	var dataManager = DataManager.getDataManager();
	var isCommon = (templatePath.indexOf('appcommon') >= 0);
	var version = "1";
	/*if (model && model.getNestedParent && model.getNestedParent().getVersion)
	{
		version = model.getNestedParent().getVersion();
	}*/

	function createView(viewType,$,kb){
		var options = {
			attribute: "data-bind",        // default "data-sbind"
			globals: window,               // default {}
			bindings: ko.bindingHandlers,  // default ko.bindingHandlers
			noVirtualElements: false       // default true
		};
		//ko.bindingProvider.instance = new ko.secureBindingsProvider(options);
		var createView2 = function(templateSrc,templateName,viewType,model,viewOptions,tabId){
				if(window.isBinding){
					return;
				}
				dataManager.clearContextMenus();
				var contentNode = $("#content");
				window.cleanViewModel(window.oldVdmModelView,contentNode,false);
				window.oldVdmModelView = null;
                /*if (window.vdmDialogViewArray == null){
                    window.vdmDialogViewArray = [];
				}*/
				contentNode.children().remove();
				var appContentNode = $("<div></div>");
				appContentNode.attr("id",tabId);
				contentNode.append(appContentNode);
				window.vdmModelView = viewType.getInstance(model, viewOptions, true);
				window.isBinding = true;
				function bindTemplate(templates){
					appContentNode.append('<div style=\"display:none\">' + templates + '<\/div>');
					var bindNode = $("<div style=\"-webkit-app-region: no-drag;\" data-bind=\"template: { name:\'" + templateName + "\', data: " + tabId +  "Model , afterRender: afterRenderView}\"></div>");
					appContentNode.append(bindNode);
					kb.applyBindings(window.vdmModelView, document.getElementById(tabId));
					window.isBinding = false;
					var autoCompAttr = 'new-password';
					if(window.browser == "chrome"){
						autoCompAttr = 'off';
					}
					$('input[type="text"]').attr('autocomplete',autoCompAttr);
					$('input[type="text"]').bind("paste", function(e){
						var validTxt = window.utils.pasteHandle(e);
						if(validTxt !== null){
							e.stopPropagation();
							e.preventDefault();
							$(e.target).val(window.utils.pasteContent(e,validTxt));
							return false;
						}
					});
					setTimeout(function(){
						if(DataManager.getDataManager().get('readMode') || window.utils.checkIsLinked(null,true)){
							window.utils.hideIconsOnReadMode();
						}
						window.setBreadCrumb(null, viewOptions.project, window.vdmModelView);
					}, 100);
					callback(viewType, window.vdmModelView);
					$(window).resize(function () {
						clearTimeout(this.id);
						this.id = setTimeout(window.adjustBreadcrumb, 300);
					});
				}
				var templates = window.utils.getHtmlPage(templateName);
				if(!templates) {
					console.log("import "+templateName+" from "+templateSrc);
					$.get(templateSrc, function(templates) {
						bindTemplate(templates);
					});
				} else {
					bindTemplate(templates);
				}
		};
		window.oldVdmModelView = window.vdmModelView;
		viewOptions = $.extend({},{includeDetails:true},viewOptions);
		
		createView2((isCommon ? "js/app/" + templatePath.substr(templatePath.indexOf("/")+ 1) : "js/app/version" + version + "/" + templatePath),
					templateName,viewType,model,viewOptions,tabId);

	};
	var viewLib;
	if(isCommon){
		viewLib = dataManager.buildCommonNsPath(viewTypeStr.substr(viewTypeStr.indexOf('/')+ 1).replace(/[\/]/g,'.'));
	}else{
		viewLib = dataManager.buildAppNsPath(viewTypeStr.substr(viewTypeStr.indexOf('/')+ 1).replace(/[\/]/g,'.'),"version" + version);
	}

	if(viewLib && (viewLib.constructor.prototype !== Object.prototype)){
		createView(viewLib,$,kb);
	}else{
		/*app: '../app/version1', these need to added for import to work
		appbo: '../app/version1/bo',
		appviews: '../app/version1/views',
		appcommon: '../app',*/
		viewTypeStr = viewTypeStr.replace("appcommon","../app");
		viewTypeStr=viewTypeStr.replace("appviews","../app/version1/views");
		viewTypeStr = viewTypeStr.replace("appbo","../app/version1/bo");
		//requirejs([viewTypeStr],createView(module));
		var className = viewTypeStr.substring(viewTypeStr.lastIndexOf('/')+1)
		var className = viewTypeStr.substring(viewTypeStr.lastIndexOf('/')+1)
		createView(window.utils.getView(className),$,kb);
		//viewTypeStr.find("appbo").replace("../app");
		//import(viewTypeStr).then(module => {
			//createView(module);
		//});
	}
}

window.adjustBreadcrumb  = function(){
	$('#breadcrumb').empty();
	if (window.breadCrumbItems.length > 1) {
		window.displayBreadCrumb(window.breadCrumbItems.length - 1, true);
	} else {
		window.displayBreadCrumb(0);
	}
}
window.displayBreadCrumb = function (index,showBefore) {
	var breadCrumbNode = $("#breadcrumb");
	var userNode = $('#userImage');
	var starNode = $('#starIcon');
	var menuNode = $('.menuButton');
	var nextNode = starNode.is(":visible") ? starNode : (userNode.is(":visible") ? userNode : menuNode);
	var prevSpace;
	var singleCrumbHeight;
	var prevLiNode;
	if (!showBefore) {
		if (index) {
			breadCrumbNode.append('<li><a id="bcPrevious" nodeIndex="' + index + '" style="cursor:pointer;font-size: x-large;color: grey;href="#"">...</a></li>');
		}
		
		for (var i = index ? index : 0; i < window.breadCrumbItems.length; i++) {
			var liNode = jQuery(window.breadCrumbItems[i]);
			window.utils.applyFontSize(liNode);
			breadCrumbNode.append(liNode);
			singleCrumbHeight = liNode.height() + 5;
			var currentBCEnd = breadCrumbNode[0].getBoundingClientRect().left + breadCrumbNode[0].getBoundingClientRect().width;
			var spaceLeft = nextNode[0].getBoundingClientRect().left - currentBCEnd;
			if (spaceLeft < 10 || (prevSpace && spaceLeft === prevSpace || breadCrumbNode.height() > singleCrumbHeight)) {
				liNode.remove();
				var firstDotsNode = jQuery('<li><a id="bcNext" nodeIndex="' + i + '" style="cursor:pointer;font-size: x-large;color: grey;href="#"">...</a></li>');
				window.utils.applyFontSize(firstDotsNode);
				breadCrumbNode.append(firstDotsNode);
				currentBCEnd = breadCrumbNode[0].getBoundingClientRect().left + breadCrumbNode[0].getBoundingClientRect().width;
				spaceLeft = nextNode[0].getBoundingClientRect().left - currentBCEnd;
				if ((spaceLeft < 10 && prevLiNode) || (prevSpace && spaceLeft === prevSpace || breadCrumbNode.height() > singleCrumbHeight && prevLiNode)) {
					prevLiNode.remove();
				}
				break;
			}
			prevSpace = spaceLeft;
			prevLiNode = liNode;
		}
	} else {
		for (var i = index; i >= 0; i--) {
			var liNode = jQuery(window.breadCrumbItems[i]);
			window.utils.applyFontSize(liNode);
			breadCrumbNode.prepend(liNode);
			singleCrumbHeight = liNode.height() + 5;
			var currentBCEnd = breadCrumbNode[0].getBoundingClientRect().left + breadCrumbNode[0].getBoundingClientRect().width;
			var spaceLeft = nextNode[0].getBoundingClientRect().left - currentBCEnd;
			if (spaceLeft < 10 || (prevSpace && spaceLeft === prevSpace || breadCrumbNode.height() > singleCrumbHeight)) {
				liNode.remove();
				var firstDotsNode = jQuery('<li><a id="bcPrevious" nodeIndex="' + i + '" style="cursor:pointer;font-size: x-large;color: grey;href="#"">...</a></li>');
				window.utils.applyFontSize(firstDotsNode);
				breadCrumbNode.prepend(firstDotsNode);
				currentBCEnd = breadCrumbNode[0].getBoundingClientRect().left + breadCrumbNode[0].getBoundingClientRect().width;
				spaceLeft = nextNode[0].getBoundingClientRect().left - currentBCEnd;
				if ((spaceLeft < 10 && prevLiNode) || (prevSpace && spaceLeft === prevSpace || breadCrumbNode.height() > singleCrumbHeight && prevLiNode)) {
					prevLiNode.remove();
				}
				break;
			}
			prevSpace = spaceLeft;
			prevLiNode = liNode;
		}
		if (index && index < window.breadCrumbItems[i] - 1) {
			var lastDotsNode = jQuery('<li><a id="bcNext" nodeIndex="' + index + '" style="cursor:pointer;font-size: x-large;color: grey;href="#"">...</a></li>');
			window.utils.applyFontSize(lastDotsNode);
			breadCrumbNode.append();
			if (breadCrumbNode.height() > singleCrumbHeight) {
				lastDotsNode.prev().remove();
			}
		}
	}
	$('#bcPrevious').on('click', function (e) {
		var breadCrumbNode = $("#breadcrumb");
		e.preventDefault();
		breadCrumbNode.empty();
		window.displayBreadCrumb(parseInt(e.target.getAttribute('nodeIndex'), true));
	});
	$('#bcNext').on('click', function (e) {
		var breadCrumbNode = $("#breadcrumb");
		e.preventDefault();
		breadCrumbNode.empty();
		window.displayBreadCrumb(parseInt(e.target.getAttribute('nodeIndex'),false));
	});
};
window.setBreadCrumb = function(currentPhase,altId,viewModel){
	var dataManager = DataManager.getDataManager();
	var breadCrumbNode = $("#breadcrumb");
	breadCrumbNode.empty();
	$('#breadcrumb').removeClass('breadcrumb');
	var plan = dataManager.get('currentPlan');
	var planVersion = dataManager.get('currentPlanVersion');
	window.breadCrumbItems = [];
	if(plan) {
		var phases = plan.get('phase');
		if(!currentPhase){
			for(var i=0;i<phases.length;i++){
				var alternatives = phases.at(i).get('phaseAlternative');
				for(var j=0;j<alternatives.length;j++){
					if(alternatives.at(j).id === altId){
						currentPhase = phases.at(i).id;
						break;
					}
				}
				if(currentPhase){
					break
				}
			}
		}
		var planNavUrl = window.utils.getPlanNavigateUrl(plan.id,plan.get('documentVersion'));
		if(dataManager.get('currentWorkspace').get("id") === window.plansKey) {
			if(!currentPhase && !altId && viewModel && viewModel.PlansWorkspaceExplorerViewModel) {
				dataManager.set('viewPhase',null);
				dataManager.set('viewAlternative',null);
				dataManager.set('currentPlan',null);
				dataManager.set('currentCommonScenario',null);
			}
			else {
				dataManager.set('viewPhase',currentPhase);
				dataManager.set('viewAlternative',altId);
				$('#breadcrumb').addClass('breadcrumb');
				window.breadCrumbItems.push('<li><a style="color: grey;" href="#" id="currentRepository">'+dataManager.get('localeManager').get('MyPlans')+'</a></li>');
				window.breadCrumbItems.push('<li><a style="color: grey;" href="#views/'+ planNavUrl + "/" + plan.id + '/transformation_Plan/' + planVersion + '/' + plan.id + '/transformation_Plan/' + DataManager.getDataManager().get('currentWorkspace').get('id') + '">' + plan.get("name") + '</a></li>');
				for(var i=0;i<phases.length;i++){
					if(phases.at(i).id === currentPhase){
						var breadText = phases.at(i).get("name");
						var alternative = phases.at(i).get('phaseAlternative').findWhere({ 'id': altId });
						breadText = breadText.concat(" ("+alternative.get("name")+")");
						window.breadCrumbItems.push('<li><a style="color: grey;" href="#' + planNavUrl + "/" + alternative.id + '">' + breadText + '</a></li>');
						break;
					}
				}
			}
		}
		else {
			$('#breadcrumb').addClass('breadcrumb');
			window.breadCrumbItems.push('<li><a style="color: grey;" href="#" id="currentRepository">' + dataManager.get('localeManager').get('MyPlans') + '</a></li>');
			window.breadCrumbItems.push('<li><a style="color: grey;" href="#views/'+ planNavUrl + "/" + plan.id + '/transformation_Plan/' + planVersion + '/' + plan.id + '/transformation_Plan/' + dataManager.get('currentWorkspace').get('id') + '">' + plan.get("name") + '</a></li>');
			if(!viewModel){
				for(var i=0;i<phases.length;i++){
					if(phases.at(i).id === currentPhase && phases.at(i).get('phaseAlternative').length > 1){
						var alternative = phases.at(i).get('phaseAlternative').findWhere({ 'id': altId });
						window.breadCrumbItems.push('<li><a style="color: grey;" href="#' + planNavUrl + "/" + alternative.id + '">' + alternative.get('name') + '</a></li>');
						break;
					}
				}
			}
			if(!dataManager.get('viewAlternative')) {
				dataManager.set('viewAlternative',dataManager.get('currentWorkspace').get("id"));
			} else {
				if(!dataManager.get('viewPhase')) {
					for(var i=0;i<plan.get("phase").length;i++){
						var alternatives = plan.get("phase").at(i).get("phaseAlternative");
						var alt = alternatives.findWhere({id:altId});
						if(alt) {
							dataManager.set('viewPhase',alt.get("phaseAlternativeOwner").get("id"));
							break;
						}
					}
				}
			}
			if(dataManager.get('viewPhase')) {
				for(var i=0;i<plan.get("phase").length;i++){
					var alternatives = plan.get("phase").at(i).get("phaseAlternative");
					var alt = alternatives.findWhere({id:dataManager.get('viewAlternative')});
					if(alt) {
						dataManager.set('viewPhase',alt.get("phaseAlternativeOwner").get("id"));
						break;
					}
				}
				var phase = plan.get("phase").findWhere({id:dataManager.get('viewPhase')});
				var breadText = phase.get("name");
				if(dataManager.get('viewAlternative')) {
					//if(phase.get("phaseAlternative").length > 1) {
						var alternative = phase.get("phaseAlternative").findWhere({id:dataManager.get('viewAlternative')});
						if(alternative){
							breadText = breadText.concat(" ("+alternative.get("name")+")");
						}
					//}
				}
				if(viewModel && !viewModel.PlanViewModel) {
					window.breadCrumbItems.push('<li><a style="color: grey;" href="#' + planNavUrl + "/" + dataManager.get('viewAlternative') + '">' + breadText + '</a></li>');
				}
				if(dataManager.get('viewAlternative')) {
					if(viewModel && viewModel.BusinessModelViewModel && viewModel.model.get('type') == 'vdml_BusinessModel'){
						var cubeUrl = window.location.href.substr(window.location.href.indexOf('#'));
						if (!cubeUrl.endsWith("Cube")) {
							cubeUrl = cubeUrl + "/Cube";
						}
						window.breadCrumbItems.push('<li><a style="color: grey;" href="' + cubeUrl + '">' + viewModel.model.get("name") + " cube " + '</a></li>');
					}
					if(viewModel && !(viewModel.getName === plan.get('name') || viewModel.model.get("name") === plan.get('name'))){
						window.breadCrumbItems.push('<li><a style="color: grey;" href="' + window.location.href + '">' + (viewModel.getName ? viewModel.getName() : viewModel.model.get("name")) + '</a></li>');
					}
				}
			}
		}
	} else if (viewModel && viewModel.isAdminView && viewModel.isAdminView()) {
		viewModel.setBreadCrumb(window.breadCrumbItems);
		window.showSettings();
	}
	if (window.breadCrumbItems.length > 1) {
		window.displayBreadCrumb(window.breadCrumbItems.length - 1, true);
	} else {
		window.displayBreadCrumb(0);
	}
};

window.checkContextForRevision = function(){
	if(DataManager.getDataManager().get('viewAlternative') !== DataManager.getDataManager().get('currentWorkspace').get('id')){
		return true;
	}
	else {
		return false;
	}
};
window.uuidGenerator = function(){
    var uuid = uuidv4();
    return uuid;
};
window.guidGenerator = function(currentWorkspace) {
	var projectID;
	var guid = '-xxxx-4xxx-yxxx-xxxxxxxx'.replace(/[xy]/g, function(c) {
		var r = Math.random() * 16 | 0,
		v = c === 'x' ? r : (r & 0x3 | 0x8);
		return v.toString(16);
	});
	if(!currentWorkspace){
		currentWorkspace = DataManager.getDataManager().get('currentWorkspace');
	}
	if(currentWorkspace){
		projectID = currentWorkspace.get('id');
	}else{
		projectID = DataManager.getDataManager().get('currentWorkSpaceKey');
	}
	return projectID + guid;
};
window.planGuidGenerator = function() {
	var projectID;
	var guid = 'xxxxyx-yxxx-xxxx'.replace(/[xy]/g, function(c) {
		var r = Math.random() * 16 | 0,
		v = c === 'x' ? r : (r & 0x3 | 0x8);
		return v.toString(16);
	});
	return window.plansKey + guid;
};

window.revisionGuidGenerator = function() {
	var projectID;
	var guid = 'xxxxyx'.replace(/[xy]/g, function(c) {
		var r = Math.random() * 16 | 0,
		v = c === 'x' ? r : (r & 0x3 | 0x8);
		return v.toString(16);
	});
	return guid;
};

window.projectGuidGenerator = function() {
	var guid = '@xxxxyx-yxxx-xxxx@'.replace(/[xy]/g, function(c) {
		var r = Math.random() * 16 | 0,
		v = c === 'x' ? r : (r & 0x3 | 0x8);
		return v.toString(16);
	});
	return guid;
};
window.isUniqueName=function(name,models,id,existingModels,activityId){
	var specialChar;
	const regex = /[\x00-\x1F\x7F-\x9F\u2000-\u200F\u2028-\u202F\u2060-\u2064\u206A-\u206F]/g;
	var newStr = name.replace(regex, "");
	if((newStr && newStr.length > 0) && newStr !== name){
		specialChar = name.match(regex)?.[0];
		bootbox.alert("Special Character "+ specialChar +" is not allowed");
		return false;
	}
	/*for(var i=0;i<name.length;i++){
		if(name.charCodeAt(i) === 160){
			specialChar = true;
		}
	}*/
	if(models){
		for(var i=0;i<models.length;i++){
			if(models[i].name===name.trim() && models[i].modalId !== id){
				if(activityId){
					var resultId = null;
					if(models[i].activityId){
						resultId = models[i].activityId ? window.utils.htmlEscape(models[i].activityId):null;
					}
					else{
						resultId = models[i].activityModalId ?models[i].activityModalId:null;
					}
					if(activityId && resultId === activityId){
						return false;
					}
				}
				else{
					return false;
				}
			}
		}
	}
	if(existingModels){
		var result = existingModels.findWhere({name: name.trim()});
		var resultId = result ? window.utils.htmlEscape(result.id):null;
		if(result && resultId !== id){
			return false;
		}
	}
	return true;
}

window.addEventListener('appinstalled', (event) => {
	console.log('👍', 'appinstalled', event);
	// Clear the deferredPrompt so it can be garbage collected
	window.deferredPrompt = null;
});
//$("#installPWA").hide();
window.addEventListener('beforeinstallprompt', (event) => {
	console.log('👍', 'beforeinstallprompt', event);
	// Stash the event so it can be triggered later.
	window.deferredPrompt = event;
	// Remove the 'hidden' class from the install button container
	//divInstall.classList.toggle('hidden', false);
	//$("#installPWA").show();
});
/*window.addEventListener('beforeunload', function (e) {
		e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
		// Chrome requires returnValue to be set
		e.returnValue = '';
		window.saveData();
});*/
window.isDirty = function() {
	var dataManager = DataManager.getDataManager();
	if(dataManager.get(dataManager.CHANGED_OBJECTS).length> 0){
		$("#saveStatus").text("Saving Data:" + dataManager.get(dataManager.CHANGED_OBJECTS).size() + "objs");
		console.log("is dirty:" + dataManager.get(dataManager.CHANGED_OBJECTS).length);
		//window.saveData();		
		dataManager.callSaveData();
		return true;
	}
	console.log("is not dirty:" + dataManager.get(dataManager.CHANGED_OBJECTS).length);
	return false;
}


$(document).ready(function() {
	wireUpEvents();
});
function wireUpEvents()
{
	window.onbeforeunload = function (e) {
		var dataManager = DataManager.getDataManager();
		if (!window.isDirty()) {
			return undefined;
		}
		var confirmationMessage = 'It looks like you have been editing something. '
								+ 'If you leave before saving, your changes will be lost.';
		if(!e) e = window.event;
		//e.cancelBubble is supported by IE - this will kill the bubbling process.
		e.cancelBubble = true;
		e.returnValue = confirmationMessage;
		//e.stopPropagation works in Firefox.
		if (e.stopPropagation) {
			e.stopPropagation();
			e.preventDefault();
		}
		//return works for Chrome and Safari
		return confirmationMessage;
		(e || window.event).returnValue = confirmationMessage; 
		return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
	};
}

var idleFunction = function (payload) {
	if (payload.idle && jQuery('#SpinnerDiv').attr('spinnerProgress') == "false") {
		console.log("system is idle");
		window.idle = true;
	} else {
		DataManager.getDataManager().set("lastSavedTime",new Date().getTime());
		console.log("system is not idle");
		window.idle = false;
	}
}
var idleTracker = new IdleTracker({
	timeout: 120000,
	onIdleCallback: idleFunction,
	throttle: 1000
});

idleTracker.start(); // start tracker

//idleTracker.end(); // stop tracker and release memory

function fnBrowserDetect(){
                 
	let userAgent = navigator.userAgent;
	let browserName;

	let chromeCheck = /Chrome/.test(userAgent) && /Google Inc/.test(navigator.vendor);
	let firefoxCheck = /Firefox/.test(userAgent);
	let ieCheck = /Edg/.test(userAgent);
	if (chromeCheck && !ieCheck) {
		browserName = "chrome";
	} else if (firefoxCheck) {
		browserName = "firefox";
	} else if (ieCheck) {
		browserName = "edge";
	} else if(userAgent.match(/opr\//i)){
		browserName = "opera";
	}  else if(userAgent.match(/safari/i)){
		browserName = "safari";
	} else {
		browserName = "unknown";
	}
	return browserName;  
}
window.browser = fnBrowserDetect();

window.saveData = function(){
	function checkWorker() {
		console.log("closing vmp");
		window.utils.stopSpinner('saveData');
	}
	window.utils.startSpinner('saveData', "Saving ... ");
	window.setTimeout(function () {
		var dataManager = DataManager.getDataManager();
		dataManager.set('closingApp', true);
		dataManager.set('isActive', false);
		var currentWorkspaceName = dataManager.get("currentWorkspaceName");
		dataManager.saveAndSwitchWorkspace(null, function(){
			dataManager.set("currentWorkspaceName", currentWorkspaceName);
			//dataManager.pullServerChanges(checkWorker);
		}, dataManager.get("currentWorkspaceId"));
		/*dataManager.saveData({
			success: checkWorker,
			error: checkWorker,
			persist: true
		});*/
	}, 100);
			
}
